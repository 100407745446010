import React from "react";
import "./ImageOutputPreview.css";
import { AiOutlineClose } from "react-icons/ai";
function ImageOutputPreview({ image, setOpenImageOutputPreview }) {
  return (
    <div className='image-preview'>
      <div className='preview-head'>
        <p>Image Preview </p>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setOpenImageOutputPreview();
          }}>
          <AiOutlineClose />
        </div>
      </div>
      <div className='image-preview-img-div'>
        <img src={image} alt='preview' />
      </div>
    </div>
  );
}

export default ImageOutputPreview;
