import { COOKIE_POLICY } from "../../Actions/CookiePolicy/CookiePolicy"

const init = {
    cookiesPolicy : undefined
}

export const cookiePolicyReducer = (state = init, { type, payload }) => {
    switch (type) {
        case COOKIE_POLICY:
            return {...state,cookiesPolicy:payload}
        default :
            return state
    }
}