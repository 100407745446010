import { DRAFT_SUBMIT } from "../../Actions/DraftSubmitModel/DraftSubmit"

const init = {
    draftSubmit:null
}

export const darftSubmitReducer = (state = init, { type, payload }) => {
    switch (type) {
        case DRAFT_SUBMIT:
            return {...state,draftSubmit:payload};
        default:
            return state
    }
}