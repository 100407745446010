export const ALLORDERS = "ALLORDERS";
export const ADDORDER="ADDORDER";
export const allOrderAction = (data) => {
    return {
        type: ALLORDERS,
        payload: data
    }
}

export const addOrderAction = (data) => {
    return { type: ADDORDER, payload: data }
}