import React from "react";
import "./404NotFoundPage.css";
import { Link } from "react-router-dom";
function NotFoundPage() {
  return (
    <div className='not-found-page'>
      <div className='not-found-div'>
        <div className='number-text'>404</div>
        <div className='not-found-text'>Oops! Page Not Found</div>
        <div className='not-found-description'>
          Bring your models to the SkyServe platform for deployment to
          satellites and constellations.
        </div>
        <div className='back-to-home-btn'>
          <Link to='/'>
            <button>Back to home</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
