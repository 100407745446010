import React from "react";
import "./ImageMetadata.css";
import { ObjectView } from "react-object-view";
import { AiOutlineClose } from "react-icons/ai";
function ImageMetadata({ metadata, setOpenImageMetadata, imageUrl, imageId }) {
  console.log(imageId);
  const options = {
    hideDataTypes: false,
    hideObjectSize: false,
    hidePreviews: false,
    expandLevel: 3,
  };
  const palette = {};
  const styles = {
    fontSize: 11,
  };
  console.log(JSON.parse(metadata));
  return (
    <div className='image-metadata-div'>
      <div className='image-metadata-box'>
        <div
          className='close-btn-image-metadata pointer-hand'
          onClick={() => {
            setOpenImageMetadata();
          }}>
          <AiOutlineClose />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: "90%",
            justifyContent: "space-between",
            gap: "1vw",
            marginTop: "1%",
          }}>
          <div className='metadata-img-div'>
            <div className='metadata-heading'>Image Metadata</div>
            <div className='metadata-id'>
              Image Id : <span className='metadata-id-text'>{imageId}</span>
            </div>
            <img src={imageUrl} alt='' />
          </div>
          <div className='metadata-text-div'>
            <ObjectView
              data={JSON.parse(metadata)}
              options={options}
              styles={styles}
              palette={palette}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageMetadata;
