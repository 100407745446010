import React, { useEffect, useState } from "react";
import "./DevHomeSidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { getNotification } from "../../Redux/Actions/Notifications/NotificationsAction";
import NotAccessText from "../../Components/NotAccessText/NotAccessText";
import { Link, useLocation } from "react-router-dom";
import { getResubmitModel } from "../../Redux/Actions/ResubmitModel/ResubmitModel";
import { getDraftSubmit } from "../../Redux/Actions/DraftSubmitModel/DraftSubmit";
import { IoNotificationsSharp } from "react-icons/io5";
import { getAllModels } from "../../Redux/Actions/AllModels/AllModelsAction";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
const DevHomeSidebar = ({ firstStepDone, allModels, fcmNotification }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector((store) => store.notification.notification);
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const devAccess = useSelector((store) => store.access.access);
  const [unreadMsg, setUnreadMsg] = useState(0);
  const emailVerified = useSelector(
    (store) => store.emailVerified.emailVerified
  );
  const [notAccessTextFor, setNotAccessTextFor] = useState("");
  useEffect(() => {
    if (notifications) {
      let count = 0;
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].read == 0 && notifications[i].clear == 0) {
          count++;
        }
      }
      setUnreadMsg(count);
    } else {
      setUnreadMsg(0);
    }
  }, [notifications]);

  useEffect(() => {
    if (fcmNotification) {
      setTimeout(() => {
        getAllNotifications();
      }, 500);
    }
  }, [fcmNotification]);

  useEffect(() => {
    if (Session_Token && devAccess == true) {
      getAllNotifications();
    }
  }, [devAccess]);
  async function getAllNotifications() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/getAllNotificationsForUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        getAllModelsForApp();
        if (result == null) {
          // setAllNotifications();
          dispatch(getNotification());
        }
        if (result && result.Status == 401) {
          // setAllNotifications();
          dispatch(getNotification());
        } else {
          // setAllNotifications(result);
          dispatch(getNotification(result));
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }
  function getAllModelsForApp() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        "/v1/model/listModelsWithVersionByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result == null) {
          dispatch(getAllModels("No data"));
        } else {
          dispatch(getAllModels(result.reverse()));
        }
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <div className='dev-sidebar'>
      {/* <div className='searchbar'>
        <input type='text' name='' id='' placeholder='Search' />
      </div> */}
      {/* {pageSides.map((el) => (
        <div
          className='developer-page-options'
          id={`${pageShow == el.pagename && "activated"}`}
          onClick={() => {
            getPage(el.pagename);
          }}>
          <div>
            <div>{el.name}</div>
            <div>
              <BsChevronDown />
            </div>
          </div>
        </div>
      ))} */}
      <Link to='/developer/overview'>
        <div
          id={`${location.pathname == "/developer/overview" && "activepage"}`}
          onClick={() => {
            // dispatch(getDevPage("overview"));
            // dispatch(getNotification(null));
            dispatch(getResubmitModel());
            dispatch(getDraftSubmit());
          }}
          className='developer-page-options overview-sidebar'>
          <div>
            <div>Overview</div>
            {/* <div><BsChevronDown /></div> */}
          </div>
        </div>
      </Link>
      {emailVerified == true ? (
        <Link to='/developer/submit-model'>
          <div
            id={`${
              location.pathname == "/developer/submit-model" && "activepage"
            }`}
            onClick={() => {
              if (
                emailVerified == true &&
                location.pathname !== "/developer/submit-model"
              ) {
                // dispatch(getDevPage("deployment"));
                // dispatch(getNotification(null));
                dispatch(getResubmitModel());
                dispatch(getDraftSubmit());
              }
            }}
            className='developer-page-options deployment-sidebar'>
            <div>
              <div>Submit Model</div>
              <div>{/* <BsChevronDown /> */}</div>
            </div>
          </div>
        </Link>
      ) : (
        <div
          id={`${
            location.pathname == "/developer/submit-model" && "activepage"
          }`}
          onMouseEnter={() => {
            if (emailVerified == false) {
              setNotAccessTextFor("deployment");
            }
          }}
          onMouseLeave={() => {
            if (emailVerified == false) {
              setNotAccessTextFor("");
            }
          }}
          className='developer-page-options deployment-sidebar'>
          <div>
            <div>Submit Model</div>
            <div>{/* <BsChevronDown /> */}</div>
          </div>

          {notAccessTextFor == "deployment" && (
            <div className='cant-access-text'>
              <NotAccessText />
            </div>
          )}
        </div>
      )}

      {emailVerified == true ? (
        <Link to='/developer/monitor'>
          <div
            id={`${location.pathname == "/developer/monitor" && "activepage"}`}
            onClick={() => {
              if (emailVerified == true) {
                // dispatch(getDevPage("monitor"));
                // dispatch(getNotification(null));
                if (firstStepDone) {
                  firstStepDone();
                }
                dispatch(getResubmitModel());
                dispatch(getDraftSubmit());
              }
            }}
            className='developer-page-options monitor-sidebar'>
            <div>
              <div>My Models</div>
              <div>{/* <BsChevronDown /> */}</div>
            </div>
          </div>
        </Link>
      ) : (
        <div
          id={`${location.pathname == "/developer/monitor" && "activepage"}`}
          onMouseEnter={() => {
            if (emailVerified == false) {
              setNotAccessTextFor("monitor");
            }
          }}
          onMouseLeave={() => {
            if (emailVerified == false) {
              setNotAccessTextFor("");
            }
          }}
          className='developer-page-options monitor-sidebar'>
          <div>
            <div>My Models</div>
            <div>{/* <BsChevronDown /> */}</div>
          </div>
          {notAccessTextFor == "monitor" && (
            <div className='cant-access-text'>
              <NotAccessText />
            </div>
          )}
        </div>
      )}

      {allModels && allModels != "No data" && emailVerified == true ? (
        <Link to='/developer/tracker'>
          <div
            id={`${location.pathname == "/developer/tracker" && "activepage"}`}
            onClick={() => {
              if (emailVerified == true) {
                // dispatch(getNotification(null));
                dispatch(getResubmitModel());
                dispatch(getDraftSubmit());
              }
            }}
            className='developer-page-options tracker-sidebar'>
            <div>
              <div>Latest Submission</div>
              <div>{/* <BsChevronDown /> */}</div>
            </div>
          </div>
        </Link>
      ) : (
        allModels &&
        allModels != "No data" &&
        emailVerified == false && (
          <div
            id={`${location.pathname == "/developer/tracker" && "activepage"}`}
            onMouseEnter={() => {
              if (emailVerified == false) {
                setNotAccessTextFor("tracker");
              }
            }}
            onMouseLeave={() => {
              if (emailVerified == false) {
                setNotAccessTextFor("");
              }
            }}
            className='developer-page-options tracker-sidebar'>
            <div>
              <div>Latest Submission</div>
              <div>{/* <BsChevronDown /> */}</div>
            </div>
            {notAccessTextFor == "tracker" && (
              <div className='cant-access-text'>
                <NotAccessText />
              </div>
            )}
          </div>
        )
      )}
      {(allModels == null || allModels == "No data") && (
        <div
          id='grayed-out'
          onClick={() => {
            if (emailVerified == true) {
              // dispatch(getNotification(null));
              dispatch(getResubmitModel());
              dispatch(getDraftSubmit());
            }
          }}
          className='tracker-sidebar'>
          <div>
            <div>Latest Submission</div>
            <div>{/* <BsChevronDown /> */}</div>
          </div>
        </div>
      )}
      <Link to='/developer/notifications'>
        <div
          id={`${
            location.pathname == "/developer/notifications" && "activepage"
          }`}
          onClick={() => {
            // dispatch(getDevPage("notifications"));
            // dispatch(getNotification(null));
            dispatch(getResubmitModel());
            dispatch(getDraftSubmit());
          }}
          className='developer-page-options notification-sidebar'>
          <div>
            <div>
              Notifications
              {unreadMsg > 0 && (
                <span className='notification-position'>
                  <IoNotificationsSharp />
                  <span className='notification-number'>{unreadMsg}</span>
                </span>
              )}
            </div>
            <div>{/* <BsChevronDown /> */}</div>
          </div>
        </div>
      </Link>
      <a href='https://skyserve.gitbook.io/' target='_blank'>
        <div className='developer-page-options notification-sidebar'>
          <div>
            <div>Documentation</div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default DevHomeSidebar;
