import { MODEL } from "../../Actions/Models/ModelAction"

const init = {
    model:null
}
export const modelReducer = (state = init, { type, payload }) => {
    switch (type) {
        case MODEL:
            return {...state,model: payload}
        default:
            return state
    }
}