import { PRODUCT_SECTION } from "../Actions/ProductSectionAction"

const init = {
    section:""
}
export const sectionReducer = (state = init, { type, payload }) => {
    switch (type) {
        case PRODUCT_SECTION:
            return {...state,section:payload}
        default:
            return state
    }
}