import React from "react";
import "./ModelLog.css";
import { AiOutlineClose } from "react-icons/ai";
import { ObjectView } from "react-object-view";
function ModelLog({ logs, closelog, model_id, modelLogTitle }) {
  const log = [];
  if (logs) {
    for (let i = 0; i < logs.length; i++) {
      log.push(logs[i]);
    }
  }
  //---------------------------//

  const modelLogs = {
    Message: logs,
  };
  const options = {
    hideDataTypes: false,
    hideObjectSize: false,
    hidePreviews: false,
    expandLevel: 3,
  };
  const palette = {};
  const styles = {
    fontSize: 13,
  };
  return (
    <div className='model-log'>
      <div className='close-icon-log'>
        <div className='model-log-text-head'>{modelLogTitle}</div>
        <AiOutlineClose
          className='closing-icons-font'
          onClick={() => closelog(false)}
        />
      </div>
      {model_id && (
        <div className='model_id_log'>
          <div className='i-red a-circle'></div>
          <div className='i-yellow a-circle'></div>
          <div className='i-green a-circle'></div>
          Model Id : {model_id}
        </div>
      )}
      {logs ? (
        <div className='log-table'>
          <div>
            <ObjectView
              data={modelLogs}
              options={options}
              styles={styles}
              palette={palette}
            />
          </div>
          <div
            className='close-btn-log'
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              margin: "10px",
            }}>
            {/* <button onClick={() => closelog(false)}>Close</button> */}
          </div>
        </div>
      ) : (
        <div className='log-table'>
          <p style={{ fontSize: "1vw" }}>No logs Available for this Model !</p>
        </div>
      )}
    </div>
  );
}

export default ModelLog;
