import React, { useEffect, useRef, useState } from "react";
import "./ConfigParameter.css";
import { BsPlusLg } from "react-icons/bs";
import { BsCaretDownFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
function ConfigParameter({
  setSec3Details,
  sec3Details,
  setOpenInfoAdditional,
  proMode,
}) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [openConfigOption, setOpenConfigOption] = useState(false);
  const [openConfigType, setOpenConfigType] = useState(false);
  const [errorConfig, setErrorConfig] = useState("");
  const [errorDataType, setErrorDataType] = useState("");
  const [errorMin, setErrorMin] = useState("");
  const [errorMax, setErrorMax] = useState("");
  const [errorDefault, setErrorDefault] = useState("");
  const [startEditConfigIndex, setStartEditConfigIndex] = useState(null);
  const [error, setError] = useState("");
  const [configDetails, setConfigureDetails] = useState({
    config_name: "",
    config_data_type: "",
    min: "",
    max: "",
    default: "",
  });
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);
  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_config_structure") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);

  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenConfigOption(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Validate and add the configuration details to the form
  function addingConfigs() {
    const { config_name, config_data_type, min, max } = configDetails;
    if (config_name == "") {
      setErrorConfig("Missing configuration name");
    }
    if (config_name.length < 4) {
      setErrorConfig("Config name must be at least 4 characters");
    }
    if (config_data_type == "") {
      setErrorDataType("please provide a config type");
    }
    if (typeof min !== "number") {
      setErrorMin("Provide a min value");
    }
    if (typeof max !== "number") {
      setErrorMax("Provide a max value");
    }
    if (typeof configDetails.default !== "number") {
      setErrorDefault("Provide a default value");
    }
    if (min > configDetails.default || max < configDetails.default) {
      setErrorDefault("Default should be bewtween min and max values");
    }
    if (
      config_name != "" &&
      config_name.length >= 4 &&
      config_data_type != "" &&
      // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
      typeof min == "number" &&
      // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
      typeof max == "number" &&
      // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
      typeof configDetails.default == "number" &&
      min <= max &&
      min <= configDetails.default &&
      max >= configDetails.default
    ) {
      let arr = [...sec3Details.model_config_structure];
      let present = false;
      // If the configuration name is already present
      for (let i = 0; i < arr.length && startEditConfigIndex == null; i++) {
        // If same name present in the config array then throw error
        if (config_name == arr[i].config_name) {
          arr[i] = configDetails;
          present = true;
          setErrorConfig("Config name should not same");
          break;
        }
      }
      // If the configuration name is not present set all the error state to empty string and add
      // the configuration to the form and when we are not updating the config(1st time)
      if (present == false && startEditConfigIndex == null) {
        arr.push(configDetails);
        setSec3Details({
          ...sec3Details,
          model_config_structure: arr,
        });
        setErrorConfig("");
        setErrorDataType("");
        setErrorMax("");
        setErrorMin("");
        setErrorDefault("");
        setOpenConfigOption(false);
      }
      // When you start updating the config then after clicking on any config from the list then
      // startEditConfigIndex will be set to that index and then we will update the config at that index
      // startEditConfigIndex comparing with the type because it can be a 0(zero) aw well and if we compare with 0 then it will false
      else if (typeof startEditConfigIndex == "number") {
        arr[startEditConfigIndex] = configDetails;
        setSec3Details({
          ...sec3Details,
          model_config_structure: arr,
        });
        setErrorConfig("");
        setErrorDataType("");
        setErrorMax("");
        setErrorMin("");
        setErrorDefault("");
        setOpenConfigOption(false);
        setStartEditConfigIndex(null);
      }
    }
  }
  function validatingIfItIsANumberOrNot(e, type) {
    const inputValue = e.target.value;

    // Validate input as integer
    if (type == "int" && inputValue.includes(".") == true) {
      return undefined;
    }
    if (isValidNumber(inputValue, type)) {
      return inputValue;
    }
  }

  const isValidNumber = (inputValue, type) => {
    // Ensure input is not empty
    if (inputValue.trim() === "") {
      return true; // or false, depending on your requirement
    }

    // Check if the input matches the pattern of a valid integer or decimal number
    if (type == "float") {
      if (inputValue === "" || /^-?\d*\.?\d*$/.test(inputValue)) {
        return true;
      }
      return false;
    } else {
      if (inputValue === "" || /^-?\d*\.?\d*$/.test(inputValue)) {
        return true;
      }
      return false;
    }
  };
  // Checking for missing fields in the configuration
  function isDisabled() {
    const { config_name, config_data_type, min, max } = configDetails;
    if (
      config_name != "" &&
      config_data_type != "" &&
      min &&
      max &&
      configDetails.default
    ) {
      return false;
    }
    return true;
  }
  return (
    <div className='config-div'>
      <div ref={divRef} className='added-configs input-label'>
        Configs
        <div
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("config");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          onClick={() => {
            if (sec3Details.model_config_structure.length == 0) {
              setOpenConfigOption(!openConfigOption);
            }
          }}
          className={`${
            sec3Details.model_config_structure.length > 0
              ? "configs-list pointer-hand"
              : "to-be-added pointer-hand"
          }`}>
          {/* {error != "" && (
            <div className='error-div'>
              <div style={{ position: "relative" }}>
                <div className='pointer-error'></div>
                <div>{error}</div>
              </div>
            </div>
          )} */}
          <div className='selected-config'>
            {sec3Details.model_config_structure.length == 0
              ? "Add Configs"
              : sec3Details.model_config_structure.map((el, id) => (
                  <div className='single-selected-config'>
                    <div
                      className='pointer-hand'
                      onClick={() => {
                        setConfigureDetails(el);
                        setOpenConfigOption(true);
                        setStartEditConfigIndex(id);
                      }}>
                      {el.config_name.length > 30
                        ? el.config_name.substring(0, 30) + "..."
                        : el.config_name}
                    </div>
                    <MdClose
                      className='pointer-hand'
                      onClick={() => {
                        let arr = [...sec3Details.model_config_structure];
                        arr.splice(id, 1);
                        setSec3Details({
                          ...sec3Details,
                          model_config_structure: arr,
                        });
                        setConfigureDetails({
                          config_name: "",
                          config_data_type: "",
                          min: "",
                          max: "",
                          default: "",
                        });
                      }}
                    />
                  </div>
                ))}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                if (sec3Details.model_config_structure.length != 0) {
                  setConfigureDetails({
                    config_name: "",
                    config_data_type: "",
                    min: "",
                    max: "",
                    default: "",
                  });
                  setOpenConfigOption(true);
                  setErrorConfig("");
                  setErrorDataType("");
                  setErrorMax("");
                  setErrorMin("");
                  setErrorDefault("");
                  setStartEditConfigIndex(null);
                }
              }}>
              <BsPlusLg />
            </div>
          </div>
          {openTooltip == "config" &&
            openConfigOption == false &&
            proMode == true &&
            error == "" && (
              <InformativeTooltip
                info='Add Configurations for your model.'
                doc=''
                icon={true}
                link='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-configs'
              />
            )}
        </div>
        <div
          className={`${
            openConfigOption == true ? "open-config" : "close-config"
          }`}>
          <div className='config-options'>
            <div
              onClick={() => {
                setOpenInfoAdditional(1.1);
              }}
              className='config-name'>
              <div>Configure Name : </div>
              <input
                onChange={(e) => {
                  // if (e.target.value != "") {
                  const pattern = /^[a-zA-Z0-9-]*$/;
                  let value = e.target.value;
                  // Check if the value matches the pattern and does not contain spaces
                  if (pattern.test(value) && !value.includes(" ")) {
                    setConfigureDetails({
                      ...configDetails,
                      config_name: e.target.value.trim(),
                    });
                    setErrorConfig("");
                  }
                  // }
                }}
                onBlur={(e) => {
                  if (e.target.value == "") {
                    setErrorConfig("Please add a config name");
                  }
                }}
                type='text'
                name=''
                id=''
                value={configDetails.config_name}
              />
              {errorConfig != "" && (
                <div className='error-div'>
                  <div style={{ position: "relative" }}>
                    <div className='pointer-error'></div>
                    <div>{errorConfig}</div>
                  </div>
                </div>
              )}
            </div>
            <div
              className='config-data-type-div'
              onClick={() => {
                setOpenInfoAdditional(1.2);
              }}>
              <div>Config Data Type : </div>
              <div className='config-data-type'>
                <div
                  onClick={() => {
                    setOpenConfigType(!openConfigType);
                  }}
                  className='selected-data-type pointer-hand'>
                  <div>
                    {configDetails.config_data_type != ""
                      ? configDetails.config_data_type == "int"
                        ? "Integer"
                        : configDetails.config_data_type == "float" && "Float"
                      : "Add Config Data Type"}
                  </div>
                  <div
                    className={`${openConfigType == true ? "goUp" : "goDown"}`}>
                    <BsCaretDownFill />
                  </div>
                </div>
                <div
                  className={`${
                    openConfigType == true
                      ? "model-type-lists-open"
                      : "model-type-lists-close"
                  }`}>
                  <div
                    className='single-type pointer-hand'
                    onClick={() => {
                      setConfigureDetails({
                        ...configDetails,
                        config_data_type: "int",
                        min: "",
                        max: "",
                        default: "",
                      });
                      setErrorDataType("");
                      setOpenConfigType(false);
                    }}>
                    Integer
                  </div>
                  <div
                    className='single-type pointer-hand'
                    onClick={() => {
                      setConfigureDetails({
                        ...configDetails,
                        config_data_type: "float",
                        min: "",
                        max: "",
                        default: "",
                      });
                      setErrorDataType("");
                      setOpenConfigType(false);
                    }}>
                    Float
                  </div>
                </div>
                {errorDataType != "" && (
                  <div className='error-div'>
                    <div style={{ position: "relative" }}>
                      <div className='pointer-error'></div>
                      <div>{errorDataType}</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='config-min-max-value'>
            <div
              onClick={() => {
                setOpenInfoAdditional(1.3);
              }}
              className='config-min'>
              <div>Add Min Value</div>
              <input
                onChange={(e) => {
                  if (e.target.value != "") {
                    if (configDetails.config_data_type == "int") {
                      let isNumber = validatingIfItIsANumberOrNot(e, "int");
                      if (isNumber) {
                        // const value = validateNumber(isNumber);
                        setConfigureDetails({
                          ...configDetails,
                          min: isNumber,
                        });
                      }
                    } else {
                      let isNumber = validatingIfItIsANumberOrNot(e, "float");
                      if (isNumber) {
                        setConfigureDetails({
                          ...configDetails,
                          min: isNumber,
                        });
                      }
                    }
                  } else if (e.target.value == "") {
                    setConfigureDetails({
                      ...configDetails,
                      min: "",
                    });
                  }
                  setErrorMin("");
                  setErrorMax("");
                }}
                onBlur={(e) => {
                  if (e.target.value == "") {
                    setErrorMin("Please Add Min");
                  }
                  // else if (e.target.value < 0) {
                  //   setErrorMin("Min must be greater than zero");
                  // }
                  else if (
                    e.target.value != "" &&
                    configDetails.max != "" &&
                    e.target.value > configDetails.max
                  ) {
                    setErrorMin("Max must be greater than Min");
                  }
                  if (+configDetails.min != NaN && configDetails.min != "") {
                    setConfigureDetails({
                      ...configDetails,
                      min: +configDetails.min,
                    });
                  }
                }}
                type='text'
                name=''
                id=''
                value={configDetails.min}
              />
              {errorMin != "" && (
                <div className='error-div'>
                  <div style={{ position: "relative" }}>
                    <div className='pointer-error'></div>
                    <div>{errorMin}</div>
                  </div>
                </div>
              )}
            </div>
            <div
              onClick={() => {
                setOpenInfoAdditional(1.4);
              }}
              className='config-max'>
              <div>Add Max Value</div>
              <input
                onChange={(e) => {
                  if (e.target.value != "") {
                    if (configDetails.config_data_type == "int") {
                      let isNumber = validatingIfItIsANumberOrNot(e, "int");
                      if (isNumber) {
                        // const value = validateNumber(isNumber);
                        setConfigureDetails({
                          ...configDetails,
                          max: isNumber,
                        });
                      }
                    } else {
                      let isNumber = validatingIfItIsANumberOrNot(e, "float");
                      if (isNumber) {
                        setConfigureDetails({
                          ...configDetails,
                          max: isNumber,
                        });
                      }
                    }
                  } else if (e.target.value == "") {
                    setConfigureDetails({
                      ...configDetails,
                      max: "",
                    });
                  }
                  setErrorMin("");
                  setErrorMax("");
                }}
                onBlur={(e) => {
                  if (e.target.value == "") {
                    setErrorMax("Please Add Max Value");
                  } else if (
                    e.target.value != "" &&
                    configDetails.min != "" &&
                    e.target.value < configDetails.min
                  ) {
                    setErrorMax("Max must be greater than Min ");
                  }
                  if (+configDetails.max != NaN && configDetails.max != "") {
                    setConfigureDetails({
                      ...configDetails,
                      max: +configDetails.max,
                    });
                  }
                }}
                type='text'
                name=''
                id=''
                value={configDetails.max}
              />
              {errorMax != "" && (
                <div className='error-div'>
                  <div style={{ position: "relative" }}>
                    <div className='pointer-error'></div>
                    <div>{errorMax}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            onClick={() => {
              setOpenInfoAdditional(1.5);
            }}
            className='config-default'>
            <div>Add Default Value</div>
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  if (configDetails.config_data_type == "int") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "int");
                    if (isNumber) {
                      // const value = validateNumber(isNumber);
                      setConfigureDetails({
                        ...configDetails,
                        default: isNumber,
                      });
                    }
                  } else {
                    let isNumber = validatingIfItIsANumberOrNot(e, "float");
                    if (isNumber) {
                      setConfigureDetails({
                        ...configDetails,
                        default: isNumber,
                      });
                    }
                  }
                } else if (e.target.value == "") {
                  setConfigureDetails({
                    ...configDetails,
                    default: "",
                  });
                }
                setErrorDefault("");
              }}
              onBlur={(e) => {
                if (e.target.value == "") {
                  setErrorDefault("Add The default value");
                } else if (
                  e.target.value != "" &&
                  configDetails.min != "" &&
                  configDetails.max != "" &&
                  (e.target.value < configDetails.min ||
                    e.target.value > configDetails.max)
                ) {
                  setErrorDefault("Default must be between Min and Max");
                }
                if (
                  +configDetails.default != NaN &&
                  configDetails.default != ""
                ) {
                  setConfigureDetails({
                    ...configDetails,
                    default: +configDetails.default,
                  });
                }
              }}
              type='text'
              name=''
              id=''
              value={configDetails.default}
            />
            {errorDefault != "" && (
              <div className='error-div'>
                <div style={{ position: "relative" }}>
                  <div className='pointer-error'></div>
                  <div>{errorDefault}</div>
                </div>
              </div>
            )}
          </div>
          <div className='add-config-btn'>
            <button
              onClick={() => {
                setOpenConfigOption(false);
                setErrorConfig("");
                setErrorDataType("");
                setErrorMax("");
                setErrorMin("");
                setErrorDefault("");
              }}>
              Cancel
            </button>
            <button
              style={{
                backgroundColor: isDisabled() ? "grey" : "#1AA053",
                border: isDisabled() ? "1px solid grey" : "1px solid #1AA053",
                cursor: isDisabled() ? "not-allowed" : "pointer",
              }}
              disabled={isDisabled()}
              onClick={() => {
                addingConfigs();
              }}>
              {startEditConfigIndex != null ? "Update Config" : "Add Config"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfigParameter;
