import { SUBMISSION_ERROR } from "../../Actions/SubmissionError/SubmissionErrorAction"

const init = {
    subError : null
}

export const submissionErrorReducer = (state = init, { type, payload }) => {
    switch (type) {
        case SUBMISSION_ERROR:
            return { ...state, subError: payload };
        default:
            return state
    }
}