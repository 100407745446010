export async function checkUserAccessForMission(token,email) {
    const myHeaders = new Headers();
    myHeaders.append("Session-Token", token);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    try {
        const response = await fetch(process.env.REACT_APP_BASE_URL+`/v1/user/getUserMissionSensorDetails/${email}`, requestOptions);
        const result = await response.json();
        console.log(result);
        return result;
    } catch (error) {
        console.error(error);
        throw error;
    }
}