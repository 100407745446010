import React, { useEffect, useState } from "react";
import "./OutputResult.css";
import { AiOutlineClose } from "react-icons/ai";
import { ObjectView } from "react-object-view";
import Skeleton from "@mui/material/Skeleton";
import { FiExternalLink } from "react-icons/fi";
import ImageOutputPreview from "./ImageOutputPreview/ImageOutputPreview";
import VisualizeResult from "./VisualizeResult/VisualizeResult";
function OutputResult({ setOpenOutputResult, outputResult, modelId }) {
  const [idWiseOutput, setIdWiseOutput] = useState();
  const [openImageOutputPreview, setOpenImageOutputPreview] = useState();
  const [openVisualizeResult, setOpenVisualizeResult] = useState();
  const options = {
    hideDataTypes: false,
    hideObjectSize: false,
    hidePreviews: false,
    expandLevel: 3,
  };
  const palette = {};
  const styles = {
    fontSize: 11,
  };
  useEffect(() => {
    let obj = {};

    // If the outputResult is not empty then it will store the outputResult in the object with the key as input_img_id
    // So that we can show the outputResult in the table with the input_img_id
    if (outputResult) {
      for (let i = 0; i < outputResult.length; i++) {
        // If the key is not present in the object then it will create a new key with the value of the outputResult[i]
        if (obj[outputResult[i].input_img_id] === undefined) {
          obj[outputResult[i].input_img_id] = [outputResult[i]];
        }
        // If the key is already present in the object then it will push the value of the outputResult[i] in the array of the key
        else {
          obj[outputResult[i].input_img_id] = [
            ...obj[outputResult[i].input_img_id],
            outputResult[i],
          ];
        }
      }
    }
    setIdWiseOutput(obj);
  }, [outputResult]);

  // When the output response is JSON then this function will be called to fetch data and show the JSON data in the object view
  function ObjectShowing({ outputUrl }) {
    // const data = await fetch(
    //   "https://skyserve-model-test-results.s3.us-east-1.amazonaws.com/phh0ckq4pb00jzd0na/testing/drqbl8y4nn0zu65fuc-band-extractor-out.json?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWGOL5GQZ4OUSGV4K%2F20230706%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230706T064958Z&X-Amz-Expires=518400&X-Amz-SignedHeaders=host&x-id=GetObject&X-Amz-Signature=b280020a1bef0126c1924afbda0db77f471efc07ce48e6db94e04d47ff7dbf4a"
    // );
    // const res = await data.json();
    // console.log(res);
    const [objData, setObjData] = useState();
    useEffect(() => {
      fetch(outputUrl)
        .then((response) => response.json())
        .then((data) => setObjData(data));
    }, []);
    return (
      <ObjectView
        data={objData}
        options={options}
        styles={styles}
        palette={palette}
      />
    );
  }
  return (
    <div
      style={{
        position: "relative",
      }}
      className='output-box'>
      <div className='output-head'>
        <div style={{ fontSize: "0.9vw" }}>
          Model Id : <span style={{ color: "skyblue" }}>{modelId}</span>
        </div>
        <div className='closing-cross-icon'>
          <AiOutlineClose onClick={() => setOpenOutputResult(false)} />
        </div>
      </div>
      <div className='output-table table-head'>
        <div>Sl no.</div>
        <div>Input ID</div>
        <div>Input Image Preview</div>
        <div>Analytical file</div>
        <div>Output Preview</div>
        <div>Output Type</div>
      </div>
      <div className='output-content'>
        {idWiseOutput &&
          Object.entries(idWiseOutput).map(([key, el], id) => {
            console.log(el);
            return (
              <div
                style={{
                  borderBottom: "1px solid #36373e",
                }}>
                <div className='content-height'>
                  <div>{id + 1}</div>
                  <div className='input-id-masking'>
                    <div className='input-id'>
                      <div className='pointer-hand'>
                        <a
                          style={{
                            color: "white",
                            textDecoration: "none",
                          }}
                          href={el[0].input_url}>
                          {key}...
                          <FiExternalLink style={{ fontSize: "1.1vw" }} />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className='pointer-hand'
                      onClick={() => {
                        setOpenImageOutputPreview(el[0].input_preview_url);
                      }}>
                      <img
                        style={{ height: "70px", width: "70px" }}
                        src={el[0].input_preview_url}
                        alt='preview'
                      />
                    </div>
                    <div className='cloud-links'>
                      <a
                        className='mask-download-buttons'
                        href={el[0].cloud_mask_url}>
                        <div>Cloud Mask</div>
                      </a>

                      <a
                        className='mask-download-buttons'
                        href={el[0].cloud_shadow_mask_url}>
                        <div>Cloud Shadow Mask</div>
                      </a>
                      <a
                        className='mask-download-buttons'
                        href={el[0].no_data_mask_url}>
                        <div>NODATA Mask</div>
                      </a>
                    </div>
                  </div>
                  <div>
                    {el &&
                      el.map((output, x) => (
                        <div>
                          <div>
                            <span>
                              {x + 1}
                              {" ) "}
                            </span>
                            <a
                              style={{
                                color: "white",
                                textDecoration: "none",
                              }}
                              href={output.output_url}>
                              {output.output_url.substring(0, 25) + "..."}
                              <FiExternalLink style={{ fontSize: "1.1vw" }} />
                            </a>
                          </div>
                          <div>
                            {output.output_preview_url === "" ? (
                              <ObjectShowing outputUrl={output.output_url} />
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "20px",
                                }}>
                                <div
                                  className='pointer-hand'
                                  onClick={() => {
                                    setOpenImageOutputPreview(
                                      output.output_preview_url
                                    );
                                  }}>
                                  <img
                                    style={{ height: "70px", width: "70px" }}
                                    src={output.output_preview_url}
                                    alt='Preview'
                                  />
                                </div>
                                <div className='output-links'>
                                  <a
                                    className='output-download-buttons'
                                    href={output.output_preview_url}>
                                    <div>Download Result Preview</div>
                                  </a>
                                  { el[0].jetson_jtop_logs_url && 
                                  <a
                                    className='output-download-buttons'
                                    href={el[0].jetson_jtop_logs_url}>
                                    <div>Download H/W Logs</div>
                                  </a>}
                                  <div 
                                    className='visualize-result-button'
                                    onClick={() => {
                                      setOpenVisualizeResult([
                                        el[0].input_preview_url,
                                        output.output_preview_url
                                      ]
                                      );
                                    }}>
                                    Visualize Results
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div>
                            {output.output_preview_url === ""
                              ? "JSON"
                              : "Image"}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {el[0].model_hw_execution_stats && (
                  <div className='execution-details'>
                    <div className='output-table-heading'>
                      <div>Parameter</div>
                      <div>Threshold Values</div>
                      <div>Current Values</div>
                    </div>
                    <div className='output-table-content'>
                      <div>
                        <div>Total Execution Time {`(in Seconds)`}</div>
                        {el[0]?.model_hw_execution_stats[0].hw_model_size !=
                          -1 && <div>Model Size {`(in MB)`}</div>}
                        <div>Output Size {`(in MB)`}</div>
                        <div>
                          Output-Input Size Ratio <br />
                          {`(in Percentage)`}
                        </div>
                      </div>
                      <div>
                        <div>500</div>
                        {el[0]?.model_hw_execution_stats[0].hw_model_size !=
                          -1 && <div>200</div>}
                        <div>20</div>
                        <div>{"66.66%"}</div>
                      </div>
                      <div>
                        <div>
                          {
                            el[0]?.model_hw_execution_stats[0].hw_stats
                              .total_execution_time
                          }
                        </div>
                        {el[0]?.model_hw_execution_stats[0].hw_model_size !=
                          -1 && (
                          <div>
                            {(
                              el[0]?.model_hw_execution_stats[0].hw_model_size /
                              1024 ** 2
                            ).toFixed(2)}
                          </div>
                        )}
                        <div>
                          {(
                            el[0]?.model_hw_execution_stats[0]
                              .hw_insights_size /
                            1024 ** 2
                          ).toFixed(2)}
                        </div>
                        <div>
                          {el[0]?.model_hw_execution_stats[0].hw_out_inp}%
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        {!outputResult && (
          <div style={{ marginTop: "2%" }}>
            {Array(4)
              .fill(0)
              .map((el) => (
                <Skeleton variant='text' width='100%' height={40} />
              ))}
          </div>
        )}
      </div>
      {openImageOutputPreview && <div className='overlay-background'></div>}
      {openImageOutputPreview && (
        <div className='output-img-preview'>
          <ImageOutputPreview
            image={openImageOutputPreview}
            setOpenImageOutputPreview={setOpenImageOutputPreview}
          />
        </div>
      )}
      {openVisualizeResult && <div className='overlay-background'></div>}
      {openVisualizeResult && (
        <div className='output-img-preview'>
          <VisualizeResult
            inputImageUrl={openVisualizeResult[0]}
            outputImageUrl={openVisualizeResult[1]}
            setOpenVisualizeResult={setOpenVisualizeResult}
          />
        </div>
      )}
    </div>
  );
}

export default OutputResult;
