import React, { useEffect, useState } from "react";
import "./ModelPath.css";
import { useSelector } from "react-redux";
import { BsAsterisk } from "react-icons/bs";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
function ModelPath({ setSec2Details, sec2Details, proMode, resubmitModel }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [error, setError] = useState("");
  const fieldErr = useSelector((store) => store.subError.subError);
  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_path") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);
  return (
    <div className='model-path'>
      <div className='input-label'>
        Model Path <BsAsterisk color='red' size={"0.6vw"} />{" "}
      </div>
      <div
        onMouseEnter={(e) => {
          e.currentTarget.style.position = "relative";
          setOpenTooltip("model-path");
        }}
        onMouseLeave={(e) => {
          // e.currentTarget.style.position = "static";
          setOpenTooltip("");
        }}>
        <input
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          className={`${resubmitModel && "color-white"}`}
          onChange={(e) => {
            // Setting the model path for sec 2
            setSec2Details({
              ...sec2Details,
              model_location: {
                ...sec2Details.model_location,
                model_path: e.target.value.trim(),
              },
            });
            setError("");
          }}
          onBlur={(e) => {
            if (e.target.value == "") {
              setError("not blank");
            }
          }}
          type='text'
          name=''
          id=''
          value={sec2Details && sec2Details.model_location.model_path}
          disabled={resubmitModel ? true : false}
        />
        {openTooltip == "model-path" &&
          proMode == true &&
          (error == "" || error == "not blank") && (
            <InformativeTooltip
              info='Exact path of ONXX file.'
              doc=''
              icon={true}
              link='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-path'
            />
          )}
      </div>
      {/* {error != "" && (
        <div className='error-div'>
          <div style={{ position: "relative" }}>
            <div className='pointer-error'></div>
            <div>{error}</div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default ModelPath;
