import React, { useState } from "react";
import "./ChangePassword.css";
import { useDispatch } from "react-redux";
import { spinnerAction } from "../../../Redux/Actions/Spinner/spinnerAction";
import SuccessfullPop from "../../RegistartionPopups/SuccessfullPop";
import { getopenSessionToken } from "../../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function ChangePassword({ setOpenChangePasswordPop }) {
  const dispatch = useDispatch();
  const [newPass, setNewPass] = useState();
  const [retype, setRetype] = useState();
  const [error, setError] = useState(false);
  const [openSuccessPop, setOpenSuccessPop] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const session_token = localStorage.getItem("session-token-skyserve");
  function changingPassword() {
    // setOpenChangePasswordPop(false);
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      password: newPass,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/auth_one/Change_password_direct",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(spinnerAction(false));
        setOpenSuccessPop(true);
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  return (
    <div className='change-password-div'>
      <div className='change-heading'>Want to Change Password ?</div>
      <div className='password-div'>
        <div>
          <div className='label-input'>New Password</div>
          <div>
            <input
              className='name-change-input'
              onChange={(e) => setNewPass(e.target.value)}
              type='password'
              value={newPass != null ? newPass : ""}
            />
          </div>
        </div>
        <div style={{ position: "relative" }}>
          <div className='label-input'>Re-type Password</div>
          <div>
            <input
              className='name-change-input'
              onChange={(e) => {
                setRetype(e.target.value);
                setError(false);
              }}
              type='text'
              value={retype != null ? retype : ""}
            />
          </div>
          {error && <div className='error-text'>Password is not same</div>}
        </div>
      </div>
      <div className='edit-store-btns'>
        <button
          onClick={() => {
            setNewPass();
            setRetype();
          }}>
          Clear
        </button>
        <button
          className={`${userDetails.email_verified == undefined && "disabled"}`}
          disabled={(newPass == null || retype == null) && true}
          onClick={() => {
            if (userDetails.email_verified == true) {
              if (newPass == retype && newPass != "" && retype != "") {
                changingPassword();
              } else {
                setError(true);
              }
            }
          }}>
          Submit
        </button>
      </div>
      {openSuccessPop && (
        <div className='password-change-success-overlay'></div>
      )}
      {openSuccessPop && (
        <div className='register-success-pop'>
          <SuccessfullPop
            heading='Awesome !'
            setOpenSuccessPop={setOpenSuccessPop}
            msg='You password has been changed successfully.'
            fromPage='changePassword'
          />
        </div>
      )}
    </div>
  );
}

export default ChangePassword;
