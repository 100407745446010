import React, { useEffect, useState } from "react";
import "./ObjectClassification.css";
import { BsCaretDownFill } from "react-icons/bs";
function ObjectClassification({
  giveOutPutData,
  singleOutputObj,
  outputData,
  addNew,
  setOutputData,
  setOpenOutputOptions,
}) {
  const [openDtype, setOpenDtype] = useState(false);
  const [dtypeError, setDtypeError] = useState();
  const [numberError, setNumberError] = useState();
  const [allClassGiven, setAllClassGiven] = useState([]);
  const [classificationOutput, setClassificationType] = useState({
    output_type: "",
    dtype: "",
    max_classes: "",
    classes: [],
  });

  // When try to change the values of the singleOutputObj then it will update the classificationOutput
  // It stores values of the singleOutputObj in classificationOutput
  useEffect(() => {
    if (singleOutputObj) {
      // Get the local values of the classificationOutput and then create a new obj.
      let obj = { ...classificationOutput };
      for (let x in obj) {
        obj[x] = singleOutputObj[x];
      }
      // store the updated object to the classificationOutput
      setClassificationType(obj);
      let arr = [];
      // It creates an array of the classes given in the singleOutputObj to make the table for the classes
      for (let i = 0; i < obj.classes.length; i++) {
        arr.push(i);
      }
      setAllClassGiven(arr);
    }
  }, [singleOutputObj]);
  function validatingInputFiledForNumber(e) {
    const inputValue = e.target.value;

    // Validate input as integer
    if (isValidInteger(inputValue)) {
      return inputValue;
    }
  }

  const isValidInteger = (inputValue) => {
    // Ensure input is not empty
    if (inputValue.trim() === "") {
      return true; // or false, depending on your requirement
    }

    // Check if the input is a valid integer
    return /^[1-9]\d*$/.test(inputValue);
  };
  const classesNo = [];
  // It creates the number of input fields according to the number of classes given
  if (classificationOutput.max_classes != "") {
    for (let i = 0; i < classificationOutput.max_classes; i++) {
      classesNo.push(
        <div className='single-class-div'>
          <div>{i}</div>
          <div>
            <input
              style={{ fontSize: "0.9vw" }}
              onChange={(e) => {
                let arr = [...classificationOutput.classes];
                arr[i] = { value: i, name: e.target.value };
                setClassificationType({
                  ...classificationOutput,
                  classes: arr,
                });
                if (e.target.value != "") {
                  let arr = [...allClassGiven];
                  if (arr.includes(i) == false) {
                    arr.push(i);
                  }
                  setAllClassGiven(arr);
                } else if (e.target.value == "") {
                  let arr = [...allClassGiven];
                  arr = arr.filter((x) => x != i);
                  setAllClassGiven(arr);
                }
              }}
              type='text'
              name=''
              id=''
              value={
                classificationOutput.classes.length >= i + 1
                  ? classificationOutput.classes[i]?.name
                  : ""
              }
              // autoFocus={i == 0 && true}
            />
          </div>
        </div>
      );
    }
  }
  // Storing data to the submit model form
  const savingData = () => {
    giveOutPutData(classificationOutput);
    setOpenOutputOptions(false);
  };

  // It will reset the values of the classificationOutput when addNew is true so that we can add new values
  useEffect(() => {
    if (addNew == true) {
      setClassificationType({
        output_type: "",
        dtype: "",
        max_classes: "",
        classes: [],
      });
    }
  }, [addNew]);
  return (
    <div>
      <div className='select-dtype'>
        <div className='label-head'>Object Detection Geometry Type :</div>
        <div
          onClick={() => {
            setOpenDtype(!openDtype);
            setDtypeError();
          }}
          className='selected-dtype pointer-hand'>
          <div>
            {classificationOutput.dtype == ""
              ? "Select ..."
              : classificationOutput.dtype == "point"
              ? "Point"
              : "Boundingbox"}
          </div>
          <div className={`${openDtype == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
        </div>
        <div className={`${openDtype == true ? "open-dtype" : "close-dtype"}`}>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setClassificationType({
                ...classificationOutput,
                output_type: "obj_classification_point",
                dtype: "point",
              });
              setOpenDtype(false);
            }}>
            Point
          </div>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setClassificationType({
                ...classificationOutput,
                output_type: "obj_classification_bbox",
                dtype: "bbox",
              });
              setOpenDtype(false);
            }}>
            Boundingbox
          </div>
        </div>
        {dtypeError && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{dtypeError}</div>
            </div>
          </div>
        )}
      </div>
      <div className='class-number-selection'>
        <div>Select number of classess : </div>
        <div>
          <input
            style={{
              fontSize: "0.9vw",
            }}
            onChange={(e) => {
              const isNumber = validatingInputFiledForNumber(e);
              if (classificationOutput.dtype == "") {
                setDtypeError("Select Data Type");
              } else {
                if (e.target.value != "") {
                  if (e.target.value < 0) {
                    setNumberError("Number should not be negative");
                  } else {
                    setNumberError();
                    setClassificationType({
                      ...classificationOutput,
                      max_classes: isNumber && +isNumber,
                      classes: [],
                    });
                    setAllClassGiven([]);
                  }
                } else {
                  setClassificationType({
                    ...classificationOutput,
                    max_classes: e.target.value,
                  });
                }
              }
            }}
            type='text'
            name=''
            id=''
            value={classificationOutput.max_classes}
          />
        </div>
        {numberError && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{numberError}</div>
            </div>
          </div>
        )}
      </div>
      {classificationOutput.max_classes != "" &&
        classificationOutput.max_classes && (
          <div className='class-selection'>
            <div className='class-selection-heading'>
              <div>Class Id.</div>
              <div>Class Label</div>
            </div>
            <div className='class-selection-content'>{classesNo}</div>
          </div>
        )}

      <div className='add-config-btn'>
        <button
          onClick={() => {
            setClassificationType({
              output_type: "",
              dtype: "",
              max_classes: "",
              classes: [],
            });
            setOutputData({ name: "" });
          }}>
          Reset
        </button>
        <button
          style={{
            background:
              (classificationOutput.classes.length == 0 ||
                classificationOutput.dtype == "" ||
                classificationOutput.max_classes == "" ||
                classificationOutput.output_type == "" ||
                allClassGiven.length != classificationOutput.max_classes ||
                outputData?.name == "") &&
              "gray",
            border:
              (classificationOutput.classes.length == 0 ||
                classificationOutput.dtype == "" ||
                classificationOutput.max_classes == "" ||
                classificationOutput.output_type == "" ||
                allClassGiven.length != classificationOutput.max_classes ||
                outputData?.name == "") &&
              "1px solid gray",
            cursor:
              (classificationOutput.classes.length == 0 ||
                classificationOutput.dtype == "" ||
                classificationOutput.max_classes == "" ||
                classificationOutput.output_type == "" ||
                allClassGiven.length != classificationOutput.max_classes ||
                outputData?.name == "") &&
              "not-allowed",
          }}
          disabled={
            (classificationOutput.classes.length == 0 ||
              classificationOutput.dtype == "" ||
              classificationOutput.max_classes == "" ||
              classificationOutput.output_type == "" ||
              allClassGiven.length != classificationOutput.max_classes ||
              outputData?.name == "") &&
            true
          }
          onClick={() => {
            savingData();
          }}>
          {singleOutputObj ? "Update" : "Save"}
        </button>
      </div>
    </div>
  );
}

export default ObjectClassification;
