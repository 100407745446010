import React, { useEffect, useState } from "react";
import "./Segmentation.css";
import { BsCaretDownFill } from "react-icons/bs";
function Segmentation({
  giveOutPutData,
  singleOutputObj,
  outputData,
  addNew,
  setOutputData,
  setOpenOutputOptions,
}) {
  const [openSegType, setOpenSegType] = useState();
  const [numberError, setNumberError] = useState();
  const [dtypeError, setDtypeError] = useState();
  const [allClassGiven, setAllClassGiven] = useState([]);
  const [selectSegmentation, setSelectSegmentation] = useState({
    output_type: "",
    dtype: "",
    max_classes: "",
    classes: [],
  });
  // When try to change the values of the singleOutputObj then it will update the segmentation
  // It stores values of the singleOutputObj in segmentation
  useEffect(() => {
    if (singleOutputObj) {
      let obj = { ...selectSegmentation };
      for (let x in obj) {
        obj[x] = singleOutputObj[x];
      }
      setSelectSegmentation(obj);
      let arr = [];
      for (let i = 0; i < obj.classes.length; i++) {
        arr.push(i);
      }
      setAllClassGiven(arr);
    }
  }, [singleOutputObj]);

  function validatingInputFiledForNumber(e) {
    const inputValue = e.target.value;

    // Validate input as integer
    if (isValidInteger(inputValue)) {
      return inputValue;
    }
  }

  const isValidInteger = (inputValue) => {
    // Ensure input is not empty
    if (inputValue.trim() === "") {
      return true; // or false, depending on your requirement
    }

    // Check if the input is a valid integer
    return /^[1-9]\d*$/.test(inputValue);
  };
  const classNumberList = [];
  if (selectSegmentation.max_classes != "") {
    for (let i = 0; i < selectSegmentation.max_classes; i++) {
      classNumberList.push(
        <div className='single-class-div'>
          <div>{i}</div>
          <div>
            <input
              onChange={(e) => {
                let arr = [...selectSegmentation.classes];
                arr[i] = { value: i, name: e.target.value };
                setSelectSegmentation({
                  ...selectSegmentation,
                  classes: arr,
                });
                if (e.target.value != "") {
                  let arr = [...allClassGiven];
                  if (arr.includes(i) == false) {
                    arr.push(i);
                  }
                  setAllClassGiven(arr);
                } else if (e.target.value == "") {
                  let arr = [...allClassGiven];
                  arr = arr.filter((x) => x != i);
                  setAllClassGiven(arr);
                }
              }}
              style={{ fontSize: "0.9vw" }}
              type='text'
              name=''
              id=''
              value={
                selectSegmentation.classes.length >= i + 1
                  ? selectSegmentation.classes[i]?.name
                  : ""
              }
            />
          </div>
        </div>
      );
    }
  }
  useEffect(() => {
    if (addNew == true) {
      setSelectSegmentation({
        output_type: "",
        dtype: "",
        max_classes: "",
        classes: [],
      });
    }
  }, [addNew]);
  console.log(selectSegmentation);
  return (
    <div>
      <div style={{ position: "relative", marginTop: "10%" }}>
        <div>Segmentation Type : </div>
        <div>
          <div
            onClick={() => {
              setOpenSegType(!openSegType);
            }}
            className='selected-seg-data-type pointer-hand'>
            <div>
              {selectSegmentation.dtype != ""
                ? selectSegmentation.dtype == "binary"
                  ? "Binary Mask"
                  : "Multiclass"
                : "Select ..."}
            </div>
            <div className={`${openSegType == true ? "goUp" : "goDown"}`}>
              <BsCaretDownFill />
            </div>
          </div>
          <div
            className={`${
              openSegType
                ? "open-list-seg-data-type"
                : "close-list-seg-data-type"
            }`}>
            <div
              className='single-type pointer-hand'
              onClick={() => {
                setSelectSegmentation({
                  ...selectSegmentation,
                  dtype: "binary",
                  output_type: "segmentation_binary",
                  max_classes: 2,
                });
                setOpenSegType(false);
                setDtypeError();
              }}>
              Binary Mask
            </div>
            <div
              className='single-type pointer-hand'
              onClick={() => {
                setSelectSegmentation({
                  ...selectSegmentation,
                  dtype: "multiclass",
                  output_type: "segmentation_multiclass",
                  max_classes: 3,
                });
                setOpenSegType(false);
                setDtypeError();
              }}>
              Multiclass
            </div>
          </div>
        </div>
        {dtypeError && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{dtypeError}</div>
            </div>
          </div>
        )}
      </div>
      <div className='class-number-selection' style={{ position: "relative" }}>
        <div>Select number of classess : </div>
        <div>
          <input
            onChange={(e) => {
              if (selectSegmentation.dtype != "binary") {
                const isNumber = validatingInputFiledForNumber(e);
                if (selectSegmentation.dtype == "") {
                  setDtypeError("Please Select Data Type First");
                } else {
                  if (e.target.value != "") {
                    if (e.target.value < 0) {
                      setNumberError("Number should not be negative");
                    } else {
                      if (+isNumber >= 3) {
                        setSelectSegmentation({
                          ...selectSegmentation,
                          max_classes: isNumber && +isNumber,
                          classes: [],
                        });
                        setAllClassGiven([]);
                        setNumberError();
                      } else if (+isNumber < 3) {
                        setNumberError(
                          "Number should be greater than or equal to 3"
                        );
                        setSelectSegmentation({
                          ...selectSegmentation,
                          max_classes: isNumber && +isNumber,
                          classes: [],
                        });
                      }
                    }
                  } else {
                    setSelectSegmentation({
                      ...selectSegmentation,
                      max_classes: e.target.value,
                    });
                  }
                }
              }
            }}
            type='text'
            name=''
            id=''
            value={
              selectSegmentation.dtype == "binary"
                ? 2
                : selectSegmentation.max_classes
            }
          />
        </div>
        {numberError && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{numberError}</div>
            </div>
          </div>
        )}
      </div>
      {selectSegmentation.max_classes != "" &&
        selectSegmentation.max_classes && (
          <div className='class-selection'>
            <div className='class-selection-heading'>
              <div>Class Id.</div>
              <div>Class Label</div>
            </div>
            <div className='class-selection-content'>{classNumberList}</div>
          </div>
        )}
      <div className='add-config-btn'>
        <button
          onClick={() => {
            setSelectSegmentation({
              output_type: "",
              dtype: "",
              max_classes: "",
              classes: [],
            });
            setOutputData({ name: "" });
          }}>
          Reset
        </button>
        <button
          style={{
            background:
              (selectSegmentation.classes.length == 0 ||
                allClassGiven.length != selectSegmentation.max_classes ||
                selectSegmentation.dtype == "" ||
                selectSegmentation.max_classes == "" ||
                selectSegmentation.output_type == "" ||
                numberError ||
                outputData?.name == "") &&
              "gray",
            cursor:
              (selectSegmentation.classes.length == 0 ||
                allClassGiven.length != selectSegmentation.max_classes ||
                selectSegmentation.dtype == "" ||
                selectSegmentation.max_classes == "" ||
                selectSegmentation.output_type == "" ||
                numberError ||
                outputData?.name == "") &&
              "not-allowed",
            border:
              (selectSegmentation.classes.length == 0 ||
                allClassGiven.length != selectSegmentation.max_classes ||
                selectSegmentation.dtype == "" ||
                selectSegmentation.max_classes == "" ||
                selectSegmentation.output_type == "" ||
                numberError ||
                outputData?.name == "") &&
              "1px solid gray",
          }}
          disabled={
            (selectSegmentation.classes.length == 0 ||
              allClassGiven.length != selectSegmentation.max_classes ||
              selectSegmentation.dtype == "" ||
              selectSegmentation.max_classes == "" ||
              selectSegmentation.output_type == "" ||
              numberError ||
              outputData?.name == "") &&
            true
          }
          onClick={() => {
            giveOutPutData(selectSegmentation);
            setOpenOutputOptions(false);
          }}>
          {singleOutputObj ? "Update" : "Save"}
        </button>
      </div>
    </div>
  );
}

export default Segmentation;
