import React from "react";
import "./SectionProgress.css";
import { MdDone } from "react-icons/md";
function SectionProgress({ sectionNo, setSectionNo }) {
  return (
    <div>
      <div className='section-progress-div'>
        <div className='incomplete-section-progress-bar'>
          <div className='completion-balls-div'>
            <div
              onClick={() => {
                if (sectionNo > 1) {
                  setSectionNo(1);
                }
              }}
              className={`${
                sectionNo > 1
                  ? "completion-ball pointer-hand"
                  : "completion-ball"
              }`}
              id={`${sectionNo >= 1 && "present-section"}`}>
              {sectionNo > 1 ? <MdDone className='done-section' /> : 1}
              <div style={{ marginLeft: "0.8vw" }} className='sec-name'>
                Model information
              </div>
            </div>
            <div
              onClick={() => {
                if (sectionNo > 2) {
                  setSectionNo(2);
                }
              }}
              className={`${
                sectionNo > 2
                  ? "completion-ball pointer-hand"
                  : "completion-ball"
              }`}
              id={`${sectionNo >= 2 && "present-section"}`}>
              {sectionNo > 2 ? <MdDone className='done-section' /> : 2}
              <div className='sec-name'>Model access</div>
            </div>
            <div
              onClick={() => {
                if (sectionNo > 3) {
                  setSectionNo(3);
                }
              }}
              className={`${
                sectionNo > 3
                  ? "completion-ball pointer-hand"
                  : "completion-ball"
              }`}
              id={`${sectionNo >= 3 && "present-section"}`}>
              {sectionNo > 3 ? <MdDone className='done-section' /> : 3}
              <div className='sec-name'>Model Input</div>
            </div>
            <div
              className='completion-ball'
              id={`${sectionNo == 4 && "present-section"}`}>
              4<div className='sec-name'>Model Output</div>
            </div>
          </div>
          <div
            className={`${
              sectionNo == 1
                ? "completion-bar-div-1"
                : sectionNo == 2
                ? "completion-bar-div-2"
                : sectionNo == 3
                ? "completion-bar-div-3"
                : "completion-bar-div-4"
            }`}></div>
        </div>
      </div>
    </div>
  );
}

export default SectionProgress;
