import { LOGIN } from "../Actions/LoginAction"

const init = {
    userName:""
}
export const loggedinUserReducer = (state = init, { type, payload }) => {
    switch (type) {
        case LOGIN: {
            return {
                ...state,
                userName: payload
            }
        }
        default:
            return state
    }
}