import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./CSS/deploment.css";
import { useNavigate } from "react-router";
import { getSubmissionError } from "../Redux/Actions/SubmissionError/SubmissionErrorAction";
import Model_submission from "../DeveloperPortalComponents/ModelSubmissionSections/Model_submission";
function DevHomeDeployment({ proMode, setProMode }) {
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Getting email verification status from Redux store
  const resubmitModel = useSelector(
    (store) => store.resubmitModel.resubmitModel
  );
  // Getting draft model status from Redux store
  const draftModel = useSelector((store) => store.draftSubmit.draftSubmit);
  // If user email is not verified, navigate back
  if (userDetails.email_verified == undefined) {
    navigate(-1);
  }

  // On component mount, dispatch submission error action as null
  // It is required when user switches mode bewtween beginner and export mode in between submission
  useEffect(() => {
    dispatch(getSubmissionError(null));
  }, []);
  return (
    <div className='deployment'>
      <Model_submission
        proMode={proMode}
        setProMode={setProMode}
        resubmitModel={resubmitModel}
        draftModel={draftModel}
      />
    </div>
  );
}

export default DevHomeDeployment;
