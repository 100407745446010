import React from "react";
import "./VisualizeResult.css";
import { AiOutlineClose } from "react-icons/ai";
function VisualizeResult({ inputImageUrl, outputImageUrl, setOpenVisualizeResult }) {
  return (
    <div className='visualize-result'>
        <div className='visualize-result-head'>
            <p className="visualize-result-head-txt">Visualize Result</p>
            <div
                style={{ cursor: "pointer" }}
                onClick={() => setOpenVisualizeResult()}>
                <AiOutlineClose />
            </div>
            </div>
            <div className='visualize-result-img-div'>
            <div className='image-container'>
                <p className='image-label'>Input Image</p>
                <img src={inputImageUrl} alt='input_preview' />
            </div>
            <div className='arrow-container'>
                <div className='arrow'>
                <p className='arrow-text'>Model Inference</p>
                <span className='arrow-symbol'>→</span>
                </div>
            </div>
            <div className='image-container'>
                <p className='image-label'>Output Result</p>
                <img src={outputImageUrl} alt='output_preview' />
            </div>
        </div>
    </div>
  );
}

export default VisualizeResult;
