import { DEV_PAGE } from "../../Actions/DeveloperPortalPage/DeveloperPortalPage"

const init = {
    page:"overview"
}

export const developerPortalPageReducer = (state = init, { type, payload }) => {
    switch (type) {
        case DEV_PAGE:
            return {...state,page:payload};
        default:
            return state;
    }
}