import React, { useEffect } from "react";
import "./SessionTokenExpired.css";
import { useDispatch } from "react-redux";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import { userAction } from "../../Redux/Actions/users/UserAction";
import { useNavigate } from "react-router";
function SessionTokenExpired(message) {
  console.log(message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const session_token = localStorage.getItem("session-token-skyserve");
  useEffect(() => {
    logoutFn();
  }, []);

  // Calling the logout API
  function logoutFn() {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      session_token: session_token,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "/v1/auth/logout", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log("logout calling");
        //   handleClose();
        dispatch(spinnerAction(false));
        dispatch(userAction());
        navigate("/");
        localStorage.removeItem("session-token-skyserve");
        localStorage.removeItem("user-details");
        sessionStorage.removeItem("accessable");
        localStorage.removeItem("missionAccess");
        sessionStorage.removeItem("opened-model");
        window.location.reload();
      })
      .catch((error) => {
        console.log("catching error: ");
        dispatch(spinnerAction(false));
        dispatch(userAction());
        navigate("/");
        localStorage.removeItem("session-token-skyserve");
        localStorage.removeItem("user-details");
        sessionStorage.removeItem("accessable");
        localStorage.removeItem("missionAccess");
        sessionStorage.removeItem("opened-model");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  }
  return (
    <div className='session-token'>
      <div className='session-token-div'>
        <div>{message.message}</div>
      </div>
    </div>
  );
}

export default SessionTokenExpired;
