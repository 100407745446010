import { ADD_EMAIL } from "../Actions/AddEmailAction"

const init = {
    email:"",
}
export const AddEmailReducer = (state = init, { type, payload }) => {
    switch (type) {
        case ADD_EMAIL:
            return {...state,email:payload}
        default:
            return state
    }
}