import { LATEST_MODEL } from "../../Actions/LatestSubmission/LatestSubmissionAction"

const init = {
    latestModel : null
}

export const latestModelReducer = (state = init, { type, payload }) => {
    switch (type) {
        case LATEST_MODEL:
            return {...state,latestModel: payload};
        default:
            return state
    }
}