import React, { useEffect, useState } from "react";
import "./ModelName.css";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
import { useSelector } from "react-redux";
import { BsAsterisk } from "react-icons/bs";
function ModelName({ sec1Details, setSec1Details, proMode, resubmitModel }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [error, setError] = useState("");
  const fieldErr = useSelector((store) => store.subError.subError);

  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_name") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);
  return (
    <div className='model-name'>
      <div className='input-label'>
        Model Name <BsAsterisk color='red' size={"0.6vw"} />
      </div>
      <div
        style={{
          width: "100%",
        }}
        onMouseEnter={(e) => {
          e.currentTarget.style.position = "relative";
          setOpenTooltip("name");
        }}
        onMouseLeave={(e) => {
          setOpenTooltip("");
        }}>
        <input
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          className={`${resubmitModel && "color-white"}`}
          onChange={(e) => {
            // Setting the model name for sec 1
            setSec1Details({
              ...sec1Details,
              model_name: e.target.value.trim(),
            });
            setError("");
          }}
          onBlur={(e) => {
            if (e.target.value == "") {
              setError("not blank");
            } else if (e.target.value.length <= 3) {
              setError("Model Name must be at least 4 characters");
            } else {
              setSec1Details({ ...sec1Details, model_name: e.target.value });
            }
          }}
          value={sec1Details.model_name}
          type='text'
          name=''
          id=''
          disabled={resubmitModel ? true : false}
        />
        {openTooltip == "name" &&
          proMode == true &&
          (error == "" || error == "not blank") && (
            <InformativeTooltip
              info='A unique name for your Model.'
              doc=''
              icon={true}
              link='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-name'
            />
          )}
      </div>
      {error != "" && error != "not blank" && (
        <div className='error-div'>
          <div style={{ position: "relative" }}>
            <div className='pointer-error'></div>
            <div>{error}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModelName;
