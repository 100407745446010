import { RESUBMIT_MODEL } from "../../Actions/ResubmitModel/ResubmitModel"

const init = {
    resubmitModel:null
}

export const resubmitModelReducer = (state = init, { type, payload }) => {
    // console.log(payload);
    switch (type) {
        case RESUBMIT_MODEL:
            return {...state,resubmitModel:payload};
        default:
            return state;
    }
}