import React, { useEffect, useRef, useState } from "react";
import "./ImageSection.css";
import { BsCaretDownFill } from "react-icons/bs";
function OutputImageSection({
  giveOutPutData,
  singleOutputObj,
  outputData,
  addNew,
  setOutputData,
  setOpenOutputOptions,
}) {
  const [openDataType, setOpenDataType] = useState(false);
  const [openPrecesion, setOpenPrecesion] = useState(false);
  const [dtypeError, setDtypeError] = useState("");
  const [minError, setMinError] = useState("");
  const [maxError, setMaxError] = useState("");
  const [precisionError, setPrecisionError] = useState("");
  const [minThresholdErrors, setMinThresholdErrors] = useState("");
  const [maxThresholdErrors, setMaxThresholdErrors] = useState("");
  const [imageType, setImageType] = useState({
    output_type: "",
    dtype: "",
    min_value: "",
    max_value: "",
    precision: "",
    min_threshold_value: "",
    max_threshold_value: "",
  });
  const precision = [
    {
      name: "Very Low",
      value: 2,
    },
    {
      name: "Low",
      value: 4,
    },
    {
      name: "Medium",
      value: 8,
    },
    {
      name: "High",
      value: 12,
    },
    {
      name: "Very High",
      value: 16,
    },
  ];
  const inputRef = useRef(null);

  // This function is used to handle the wheel event on the input field (We dont need that wheen in the Number field)
  const handleWheel = (e) => {
    const input = inputRef.current;

    if (input === document.activeElement) {
      e.preventDefault();
      const delta = Math.sign(e.deltaY);
      input.stepUp(delta);
    }
  };

  // When try to update the single output object then it will update the state of the image type
  // First to fill all the field of the selected output object
  useEffect(() => {
    if (singleOutputObj) {
      let obj = { ...imageType };
      for (let x in obj) {
        obj[x] = singleOutputObj[x];
      }
      setImageType(obj);
    }
  }, [singleOutputObj]);

  function validateNumber(event) {
    const inputValue = event.replace(/[^\d]/g, "");
    return inputValue;
  }

  // Checking the validation of the output data
  function savingOutPut() {
    setMinError("");
    setMaxError("");
    setMinThresholdErrors("");
    setMaxThresholdErrors("");
    setPrecisionError("");

    const {
      min_value,
      max_value,
      min_threshold_value,
      max_threshold_value,
      dtype,
      precision,
    } = imageType;
    // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
    if (typeof min_value !== "number") {
      setMinError("Provide min value");
    }
    // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
    if (typeof max_value != "number") {
      setMaxError("Provide max value");
    }
    // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
    if (typeof min_threshold_value != "number") {
      setMinThresholdErrors("Provide min threshold value");
    }
    // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
    if (typeof max_threshold_value != "number") {
      setMaxThresholdErrors("Provide max threshold value");
    }
    if (dtype == "") {
      setDtypeError("Provide output type");
    }
    if (precision == "") {
      setPrecisionError("Provide precision value");
    }
    if (min_value > max_value) {
      setMinError("Min Value must be less than or equal to Max Value");
    }
    if (max_threshold_value < min_threshold_value) {
      setMinThresholdErrors(
        "Max Threshold Value must be greater than or equal to Min Threshold Value"
      );
    }
    if (min_threshold_value < min_value) {
      setMinThresholdErrors(
        "Min Threshold Value must be greater than or equal Min Value"
      );
    }
    if (max_threshold_value > max_value) {
      setMaxThresholdErrors(
        "Max Threshold Value must be less than or equal Max Value"
      );
    }
    if (
      // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
      typeof min_value == "number" &&
      // Comparing with number type because it can be a 0(zero) aw well and if we compare with 0 then it will false
      typeof max_value == "number" &&
      typeof min_threshold_value == "number" &&
      typeof max_threshold_value == "number" &&
      min_value <= max_value &&
      min_threshold_value <= max_threshold_value &&
      min_value <= min_threshold_value &&
      max_threshold_value <= max_value &&
      dtype != "" &&
      precision != ""
    ) {
      giveOutPutData(imageType);
      setOpenOutputOptions(false);
    }
  }
  // Validation for integer and float
  function validatingIfItIsANumberOrNot(e, type) {
    const inputValue = e.target.value;

    if (type == "int" && inputValue.includes(".") == true) {
      return undefined;
    }
    if (isValidNumber(inputValue, type)) {
      return inputValue;
    }
  }

  const isValidNumber = (inputValue, type) => {
    // Ensure input is not empty
    if (inputValue.trim() === "") {
      return true; // or false, depending on your requirement
    }

    // Check if the input matches the pattern of a valid integer or decimal number
    if (type == "float") {
      if (inputValue === "" || /^-?\d*\.?\d*$/.test(inputValue)) {
        return true;
      }
      return false;
    } else {
      if (inputValue === "" || /^-?\d*\.?\d*$/.test(inputValue)) {
        return true;
      }
      return false;
    }
  };
  useEffect(() => {
    if (addNew == true) {
      setImageType({
        output_type: "",
        dtype: "",
        min_value: "",
        max_value: "",
        precision: "",
        min_threshold_value: "",
        max_threshold_value: "",
      });
    }
  }, [addNew]);
  // If anything is missing from the image input then disable the save button
  function isDisabled() {
    const {
      min_value,
      max_value,
      min_threshold_value,
      max_threshold_value,
      dtype,
      precision,
    } = imageType;
    if (
      min_value == "" ||
      max_value == "" ||
      min_threshold_value == "" ||
      max_threshold_value == "" ||
      dtype == "" ||
      precision == ""
    ) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <div>
      <div className='image-type'>
        <div className='label-head'>Pixel Value Data Type : </div>
        <div
          onClick={() => {
            setOpenDataType(!openDataType);
          }}
          className='selected-output pointer-hand'>
          <div>
            {imageType.dtype == ""
              ? "Select data type"
              : imageType.dtype[0].toUpperCase() +
                imageType.dtype.substring(1, imageType.dtype.length)}
          </div>
          <div className={`${openDataType == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
        </div>
        <div
          className={`${
            openDataType == true ? "all-data-type" : "all-data-type-close"
          }`}>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setImageType({
                ...imageType,
                dtype: "integer",
                output_type: "image_integer",
                min_threshold_value: "",
                max_threshold_value: "",
                min_value: "",
                max_value: "",
              });
              setDtypeError("");
              setOpenDataType(false);
            }}>
            Integer
          </div>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setImageType({
                ...imageType,
                dtype: "float",
                output_type: "image_float",
                min_threshold_value: "",
                max_threshold_value: "",
                min_value: "",
                max_value: "",
              });
              setDtypeError("");
              setOpenDataType(false);
            }}>
            Float
          </div>
        </div>
        {dtypeError != "" && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{dtypeError}</div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className='label-head'>Values : </div>
        <div className='min-max-image'>
          <div className='image-min-val'>
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  if (imageType.dtype == "") {
                    setDtypeError("Add data type first");
                    e.target.value = "";
                  } else if (imageType.dtype == "integer") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "int");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        min_value: isNumber,
                      });
                    }
                  } else if (imageType.dtype == "float") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "float");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        min_value: isNumber,
                      });
                    }
                  }
                  setMinError("");
                } else if (e.target.value == "") {
                  setImageType({ ...imageType, min_value: "" });
                  setMinError("");
                }
              }}
              onBlur={(e) => {
                if (
                  imageType.min_value != "" &&
                  imageType.max_value != "" &&
                  imageType.max_value < imageType.min_value
                ) {
                  setMinError(
                    "Min value must be less than or equal to max value"
                  );
                }
                if (e.target.value == "") {
                  // console.log("Min value must be");
                  setMinError("Should add a min value");
                }
                if (+imageType.min_value !== NaN && imageType.min_value != "") {
                  setImageType({
                    ...imageType,
                    min_value: +imageType.min_value,
                  });
                }
              }}
              type='text'
              name=''
              id=''
              placeholder='Min value'
              value={imageType.min_value}
            />
            {minError != "" && (
              <div className='error-div'>
                <div style={{ position: "relative" }}>
                  <div className='pointer-error'></div>
                  <div>{minError}</div>
                </div>
              </div>
            )}
          </div>
          <div className='image-max-val'>
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  if (imageType.dtype == "") {
                    setDtypeError("Add data type first");
                    e.target.value = "";
                  } else if (imageType.dtype == "integer") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "int");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        max_value: isNumber,
                      });
                    }
                  } else if (imageType.dtype == "float") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "float");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        max_value: isNumber,
                      });
                    }
                  }
                  setMaxError("");
                } else if (e.target.value == "") {
                  setImageType({ ...imageType, max_value: "" });
                  setMaxError("");
                }
              }}
              onBlur={(e) => {
                if (
                  imageType.min_value != "" &&
                  imageType.max_value != "" &&
                  imageType.max_value < imageType.min_value
                ) {
                  setMaxError(
                    "Max value must be greater than or equal to min value"
                  );
                }
                if (e.target.value == "") {
                  // console.log("Invalid");
                  setMaxError("Should add a max value");
                }
                if (+imageType.max_value !== NaN && imageType.max_value != "") {
                  setImageType({
                    ...imageType,
                    max_value: +imageType.max_value,
                  });
                }
              }}
              type='text'
              name=''
              id=''
              value={imageType.max_value}
              placeholder='Max value'
            />
            {maxError != "" && (
              <div className='error-div'>
                <div style={{ position: "relative" }}>
                  <div className='pointer-error'></div>
                  <div>{maxError}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className='label-head'>Threshold Value : </div>
        <div className='min-max-image-threshold'>
          <div className='image-min-threshold-val'>
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  if (imageType.dtype == "") {
                    setDtypeError("Add data type first");
                    e.target.value = "";
                  } else if (imageType.dtype == "integer") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "int");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        min_threshold_value: isNumber,
                      });
                    }
                  } else if (imageType.dtype == "float") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "float");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        min_threshold_value: isNumber,
                      });
                    }
                  }
                  setMinThresholdErrors("");
                } else if (e.target.value == "") {
                  setImageType({
                    ...imageType,
                    min_threshold_value: "",
                  });
                  setMinThresholdErrors("");
                }
              }}
              onBlur={(e) => {
                if (
                  imageType.min_threshold_value != "" &&
                  imageType.max_threshold_value != "" &&
                  imageType.min_threshold_value > imageType.max_threshold_value
                ) {
                  setMinThresholdErrors(
                    "Min Threshold should be less than or equal to Max threshold value"
                  );
                }
                if (
                  imageType.min_threshold_value != "" &&
                  imageType.max_threshold_value != "" &&
                  imageType.min_threshold_value < imageType.min_value
                ) {
                  setMinThresholdErrors(
                    "Min Threshold should be greater than or equal to Min value"
                  );
                }
                if (imageType.min_threshold_value == "") {
                  setMinThresholdErrors("Add Min Threshold");
                }
                if (
                  +imageType.min_threshold_value !== NaN &&
                  imageType.min_threshold_value != ""
                ) {
                  setImageType({
                    ...imageType,
                    min_threshold_value: +imageType.min_threshold_value,
                  });
                }
              }}
              type='text'
              name=''
              id=''
              value={imageType.min_threshold_value}
              placeholder='Min Threshold Value'
            />
            {minThresholdErrors != "" && (
              <div className='error-div'>
                <div style={{ position: "relative" }}>
                  <div className='pointer-error'></div>
                  <div>{minThresholdErrors}</div>
                </div>
              </div>
            )}
          </div>
          <div className='image-max-threshold-val'>
            <input
              onChange={(e) => {
                if (e.target.value != "") {
                  if (imageType.dtype == "") {
                    setDtypeError("Add data type first");
                    e.target.value = "";
                  } else if (imageType.dtype == "integer") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "int");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        max_threshold_value: isNumber,
                      });
                    }
                  } else if (imageType.dtype == "float") {
                    let isNumber = validatingIfItIsANumberOrNot(e, "float");
                    if (isNumber) {
                      setImageType({
                        ...imageType,
                        max_threshold_value: isNumber,
                      });
                    }
                  }
                  setMaxThresholdErrors("");
                } else if (e.target.value == "") {
                  setImageType({
                    ...imageType,
                    max_threshold_value: "",
                  });
                  setMaxThresholdErrors("");
                }
              }}
              onBlur={(e) => {
                if (
                  imageType.min_threshold_value != "" &&
                  imageType.max_threshold_value != "" &&
                  imageType.min_threshold_value > imageType.max_threshold_value
                ) {
                  setMaxThresholdErrors(
                    "Max Threshold should be greater than or equal to Min threshold value"
                  );
                }
                if (
                  imageType.min_threshold_value != "" &&
                  imageType.max_threshold_value != "" &&
                  imageType.max_threshold_value > imageType.max_value
                ) {
                  setMaxThresholdErrors(
                    "Max Threshold should be less than or equal to Max value"
                  );
                }
                if (imageType.max_threshold_value == "") {
                  setMaxThresholdErrors("Add Max Threshold");
                }
                if (
                  +imageType.max_threshold_value !== NaN &&
                  imageType.max_threshold_value != ""
                ) {
                  setImageType({
                    ...imageType,
                    max_threshold_value: +imageType.max_threshold_value,
                  });
                }
              }}
              type='text'
              name=''
              id=''
              value={imageType.max_threshold_value}
              ref={inputRef}
              onWheel={handleWheel}
              placeholder='Max Threshold Value'
              onKeyDown={(e) => {
                // Prevent arrow keys from incrementing or decrementing the value
                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                  e.preventDefault();
                }
              }}
            />
            {maxThresholdErrors != "" && (
              <div className='error-div'>
                <div style={{ position: "relative" }}>
                  <div className='pointer-error'></div>
                  <div>{maxThresholdErrors}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='image-precision-val'>
          <div className='label-head'>Precision value : </div>
          <div
            onClick={() => {
              setOpenPrecesion(!openPrecesion);
            }}
            className='selected-precesion pointer-hand'>
            <div>
              {imageType.precision == ""
                ? "select data type"
                : imageType.precision == 2
                ? "Very Low"
                : imageType.precision == 4
                ? "Low"
                : imageType.precision == 8
                ? "Medium"
                : imageType.precision == 12
                ? "High"
                : "Very High"}
            </div>
            <div className={`${openPrecesion == true ? "goUp" : "goDown"}`}>
              <BsCaretDownFill />
            </div>
          </div>
          <div
            className={`${
              openPrecesion == true
                ? "list-precesion-open"
                : "list-precesion-close"
            }`}>
            {precision.map((el) => (
              <div
                className='single-type pointer-hand'
                onClick={() => {
                  setImageType({ ...imageType, precision: el.value });
                  setOpenPrecesion(false);
                }}>
                {el.name}
              </div>
            ))}
          </div>
          {precisionError != "" && (
            <div className='error-div'>
              <div style={{ position: "relative" }}>
                <div className='pointer-error'></div>
                <div>{precisionError}</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='add-config-btn'>
        <button
          onClick={() => {
            setImageType({
              output_type: "",
              dtype: "",
              min_value: "",
              max_value: "",
              precision: "",
              min_threshold_value: "",
              max_threshold_value: "",
            });
            setOutputData({ name: "" });
          }}>
          Reset
        </button>
        <button
          style={{
            background: isDisabled() && "gray",
            border: isDisabled() && "1px solid gray",
            cursor: isDisabled() && "not-allowed",
          }}
          disabled={isDisabled() && true}
          onClick={() => {
            savingOutPut();
          }}>
          {singleOutputObj ? "Update" : "Save"}
        </button>
      </div>
    </div>
  );
}

export default OutputImageSection;
