import React, { useEffect, useRef, useState } from "react";
import { BsAsterisk, BsCaretDownFill } from "react-icons/bs";
import "./LevelOfProcessing.css";
import { useSelector } from "react-redux";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
function LevelOfProcessing({ setSec3Details, sec3Details, proMode }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [openLop, setOpenLop] = useState(false);
  const [error, setError] = useState("");
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);
  const userMissionAccess = localStorage.getItem("missionAccess");
  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "level_of_processing") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);
  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenLop(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div ref={divRef} className='lop'>
      <div className='input-label'>
        Input product type <BsAsterisk color='red' size={"0.6vw"} />
      </div>
      <div className='lop-selection-div'>
        <div
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("lop");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          className='selected-lop pointer-hand'
          onClick={() => {
            setOpenLop(!openLop);
            setError("");
          }}>
          {openTooltip == "lop" &&
            openLop == false &&
            proMode == true &&
            error == "" && (
              <InformativeTooltip
                info='Select the Product type/degree of data processing applied to your model.'
                doc=''
                icon={true}
                link='https://skyserve.gitbook.io/skyserve-docs/model-submission#input-product-type'
              />
            )}

          <div>
            {sec3Details.level_of_processing != ""
              ? sec3Details.level_of_processing == "L1A"
                ? "Radiometrically corrected Scene"
                : sec3Details.level_of_processing == "L1B"
                ? "Haze Compensated Scene"
                : "Geo-referenced Scene"
              : "Please Select"}
          </div>
          <div className={`${openLop == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
        </div>
        {error != "" && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{error}</div>
            </div>
          </div>
        )}
        <div
          className={`${
            openLop == true ? "lop-lists-open" : "lop-lists-close"
          }`}>
          {(userMissionAccess == "Denali" || userMissionAccess == "K2") && (
            <div
              className='single-type pointer-hand'
              onClick={() => {
                setSec3Details({ ...sec3Details, level_of_processing: "L1A" });
                setOpenLop(false);
              }}>
              Radiometrically corrected Scene{" (L1A)"}
            </div>
          )}

          <div
            className='single-type pointer-hand'
            onClick={() => {
              setSec3Details({ ...sec3Details, level_of_processing: "L1B" });
              setOpenLop(false);
            }}>
            Haze Compensated Scene{"(L1B)"}
          </div>
          {(userMissionAccess == "Denali" || userMissionAccess == "K2") && (
            <div
              className='single-type pointer-hand'
              onClick={() => {
                setSec3Details({ ...sec3Details, level_of_processing: "L2A" });
                setOpenLop(false);
              }}>
              Geo-referenced Scene{"(L2A)"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LevelOfProcessing;
