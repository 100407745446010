import React, { useEffect, useState } from "react";
import ModelDescription from "./ModelDescription/ModelDescription";
import ModelName from "./ModelName/ModelName";
import ModelType from "./ModelType/ModelType";
import ModelVisibility from "./Visibility/ModelVisibility";
import { BsPlusLg } from "react-icons/bs";
import "./Section1.css";
function Section1({
  sec1Response,
  giveInput,
  submitModelDetails,
  setNextBtnDisabled,
  proMode,
  setSubmitModelDetails,
  resubmitModel,
  draftModel,
}) {
  const [openInfo, setOpenInfo] = useState();
  // Form state for Section 1
  const [sec1Details, setSec1Details] = useState({
    model_name: "",
    model_description: "",
    model_type: "",
    visibility: "private",
  });

  //------------------------It is optimized below----------------------------//
  if (giveInput && proMode == false) {
    sec1Response(sec1Details);
  }
  useEffect(() => {
    if (
      sec1Details.model_description != "" &&
      sec1Details.model_name != "" &&
      sec1Details.model_type != "" &&
      sec1Details.visibility != "" &&
      proMode == false
    ) {
      setNextBtnDisabled(false);
    } else if (proMode == false) {
      setNextBtnDisabled(true);
    }
    if (proMode == true) {
      if (sec1Details.model_type != "") {
        setSubmitModelDetails({
          ...submitModelDetails,
          model_type: sec1Details.model_type,
          visibility: "private",
        });
      }
    }
  }, [sec1Details]);
  useEffect(() => {
    if (
      sec1Details.model_name != "" &&
      proMode == true &&
      !resubmitModel &&
      !draftModel
    ) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_name: sec1Details.model_name,
      });
    }
  }, [sec1Details.model_name]);
  useEffect(() => {
    if (sec1Details.model_description != "" && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_description: sec1Details.model_description,
      });
    }
  }, [sec1Details.model_description]);
  useEffect(() => {
    if (
      sec1Details.model_type != "" &&
      proMode == true &&
      !resubmitModel &&
      !draftModel
    ) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_type: sec1Details.model_type,
      });
    }
  }, [sec1Details.model_type]);

  //-----------------------------------------------------------------------//

  // It is used to store the values coming from every field even after section changes to show that value in the input fields
  useEffect(() => {
    setSec1Details({
      ...sec1Details,
      model_name: submitModelDetails.model_name,
      model_description: submitModelDetails.model_description,
      model_type: submitModelDetails.model_type,
      visibility: "private",
    });
  }, []);

  // ------------------------------------This needs to be replaced with upper code----------------------------------------
  // useEffect(() => {
  //   // when giveInput is true (which is set by its parent) then send the sec1Details to the parent
  //   if (giveInput && !proMode) {
  //     sec1Response(sec1Details);
  //   }

  //   // If it is beginner mode then check if all the fields are filled or not
  //   if (!proMode) {
  //     const isFormValid = Object.values(sec1Details).every(
  //       (value) => value !== ""
  //     );
  //     setNextBtnDisabled(!isFormValid); // If any of the field is missing("") then make the button to be disabbled
  //   }
  //   // If it is pro mode then just set then set the values to the submitModelDetails state
  //   // Which is in future go to the whole form of model submission
  //   else {
  //     let newModelDetails = { ...submitModelDetails };

  //     if (sec1Details.model_name !== "" && !resubmitModel && !draftModel) {
  //       newModelDetails.model_name = sec1Details.model_name;
  //     }

  //     if (sec1Details.model_description !== "") {
  //       newModelDetails.model_description = sec1Details.model_description;
  //     }

  //     if (sec1Details.model_type !== "" && !resubmitModel && !draftModel) {
  //       newModelDetails.model_type = sec1Details.model_type;
  //       newModelDetails.visibility = "private";
  //     }

  //     setSubmitModelDetails(newModelDetails);
  //   }
  // }, [sec1Details, proMode, resubmitModel, draftModel, giveInput]);
  //-------------------------------------------------------------------------------------------------------

  // If there is resubmit flow then just store all the values in the section state to show them in the respective field
  useEffect(() => {
    if (resubmitModel || draftModel) {
      const model = resubmitModel || draftModel;
      setSec1Details({
        model_name: model.model_name,
        model_description: model.model_description,
        model_type: model.model_type,
        visibility: "private",
      });
    }
  }, [resubmitModel, draftModel]);
  return (
    <div className='sec1-div'>
      <div className='sec1-div-left'>
        <div
          onClick={() => {
            setOpenInfo(1);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <ModelName
            submitModelDetails={submitModelDetails}
            sec1Details={sec1Details}
            setSec1Details={setSec1Details}
            proMode={proMode}
            resubmitModel={resubmitModel}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(2);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <ModelDescription
            submitModelDetails={submitModelDetails}
            sec1Details={sec1Details}
            setSec1Details={setSec1Details}
            proMode={proMode}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(3);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <ModelType
            submitModelDetails={submitModelDetails}
            sec1Details={sec1Details}
            setSec1Details={setSec1Details}
            proMode={proMode}
            resubmitModel={resubmitModel}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(4);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <ModelVisibility
            submitModelDetails={submitModelDetails}
            sec1Details={sec1Details}
            setSec1Details={setSec1Details}
            proMode={proMode}
          />
        </div>
      </div>
      {proMode == false && (
        <div className='sec1-div-right'>
          <div className='sec-info sec1-1'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Name ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 1) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(1);
                    }
                  }}
                  className={`${
                    openInfo == 1 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 1 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                A unique name for your Model, ensuring it does not exceed 30
                characters in length
              </div>
            </div>
          </div>
          <div className='sec-info sec1-2' id={`${openInfo != 1 && "sec2"}`}>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Description ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 2) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(2);
                    }
                  }}
                  className={`${
                    openInfo == 2 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 2 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                A detailed description of your model, ensure the description
                must be within 400 characters in length.
              </div>
            </div>
          </div>
          <div className='sec-info sec1-3'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Type ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 3) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(3);
                    }
                  }}
                  className={`${
                    openInfo == 3 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 3 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                Specify whether your model falls under the category of deep
                learning or non-deep learning.
              </div>
            </div>
          </div>
          <div className='sec-info sec1-4'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Visibility ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 4) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(4);
                    }
                  }}
                  className={`${
                    openInfo == 4 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 4 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                Specify whether you want your models to have Private or Public
                visibility.
                <div>
                  <span style={{ fontWeight: "bold" }}>{"a . "}Private : </span>
                  The models will be visible and accessible only to you as the
                  owner/developer who submitted them and not be visible on the
                  marketplace.
                </div>
                <div>
                  <span style={{ fontWeight: "bold" }}>{"b . "}Public : </span>
                  The models are visible on the marketplace. Users will be able
                  to run your models to obtain insights on the User Portal.
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section1;
