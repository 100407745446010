import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllModels } from "../../Redux/Actions/AllModels/AllModelsAction";
import { getModel } from "../../Redux/Actions/Models/ModelAction";
import "./ConfirmationPage.css";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";

// This page is not being used in the current version of the project
// As in this version we are not giving access user to change the state of the model
function ConfirmationPage({
  setOpenModal,
  modelId,
  modelState,
  modelVisibility,
  toBeChanged,
}) {
  const dispatch = useDispatch();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const [responseForeDeactivation, setResponseForDeactivation] = useState({
    result: "",
    id: "",
  });
  const changeState = () => {
    if (modelState == "Deactivated") {
      var myHeaders = new Headers();
      myHeaders.append("Session-Token", Session_Token);

      var raw = "";

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL +
          `/v1/model/changeModelState/${modelId}/Activated/true`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          fetchAndStore();
        })
        .catch((error) => {
          dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
          console.log("error", error)});
    } else if (modelState == "Activated") {
      let myHeaders = new Headers();
      myHeaders.append("Session-Token", Session_Token);
      myHeaders.append("Content-Type", "application/json");

      let raw = JSON.stringify({
        model_id: modelId,
        status: "VerificationInProgress",
        confirm: true,
      });

      let requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL +
          `/v1/model/changeModelState/${modelId}/Deactivated/false`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          setResponseForDeactivation({ result: result, id: modelId });
          confirmActiveToInactive(result.Message, modelId);
        })
        .catch((error) => {
          dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
          console.log("error", error)});
    }
  };
  const fetchAndStore = () => {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        "/v1/model/listModelsWithVersionByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(getAllModels(result.reverse()));
        for (let i = 0; i < result.length; i++) {
          for (let j = 0; j < result[i].model_version_obj.length; j++) {
            if (
              result[i].model_version_obj[j].model_details.model_id == modelId
            ) {
              dispatch(getModel(result[i].model_version_obj[j]));
            }
          }
        }
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  };

  function confirmActiveToInactive(message, id) {
    alert("Do you want to really want to change your active status ?");
    var myHeader = new Headers();
    myHeader.append("Session-Token", Session_Token);

    var raw = "";

    var requestOption = {
      method: "PATCH",
      headers: myHeader,
      body: raw,
      redirect: "follow",
    };
    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/changeModelState/${id}/Deactivated/true`,
      requestOption
    )
      .then((response) => response.text())
      .then((result) => {
        fetchAndStore();
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  function changeVisibility() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/changeModelVisibility/${modelId}/${
          modelVisibility != "private" ? "private" : "public"
        }`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => fetchAndStore())
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  return (
    <div>
      <div className='warning-text'>
        {toBeChanged == "state"
          ? "Are sure to change the state of the model ?"
          : "Are sure to change the access control of the model ?"}
      </div>
      <div className='action-btn-div'>
        <button
          className='cancel-btn'
          onClick={() => {
            setOpenModal(false);
            // setChangeStatus(false);
          }}>
          Cancel
        </button>
        <button
          className='confirm-btn'
          onClick={() => {
            setOpenModal(false);
            // setChangeStatus(true);
            if (toBeChanged == "state") {
              changeState();
            } else if (toBeChanged == "visibility") {
              changeVisibility();
            }
          }}>
          Confirm
        </button>
      </div>
    </div>
  );
}

export default ConfirmationPage;
