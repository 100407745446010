import React, { useEffect, useRef, useState } from "react";
import "./ProfilePage.css";
import ChangePassword from "./ChangePassword/ChangePassword";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import PortalNavbarPage from "../../DeveloperPortalComponents/PortalNavbarPage/PortalNavbarPage";
const ProfilePage = () => {
  const allTokens = JSON.parse(localStorage.getItem("tokenList"));
  const [openingSubHeadProfile, setOpeningSubHeadProfile] = useState("profile");
  const [hoverSubHeadProfile, setHoverSubHeadProfile] = useState("");
  const [exp, setExp] = useState(new Date().getTime());
  const [tokenLists, setTokenLists] = useState(allTokens);
  const [openChangePasswordPop, setOpenChangePasswordPop] = useState(false);
  useEffect(() => {
    setExp(new Date().getTime());
  }, []);
  useEffect(() => {
    let arr = [];
    if (allTokens) {
      for (let i = 0; i < allTokens.length; i++) {
        if (exp < allTokens[i].expiringOn) {
          arr.push(allTokens[i]);
        }
      }
      if (arr.length > 0) {
        setTokenLists(arr);
        localStorage.setItem("tokenList", JSON.stringify(arr));
      } else {
        setTokenLists();
        localStorage.removeItem("tokenList");
      }
    }
  }, [exp]);
  function changePassword() {
    setOpenChangePasswordPop(true);
  }
  return (
    <div className='profile'>
      <div className='nav-profile'>
        <PortalNavbarPage portal='developer' />
      </div>

      <div className='profile-page-body'>
        {/* <div className='profile-page-heading'>Profile Page</div> */}
        <div className='profile-options'>
          <div
            className='sub-head-profile'
            onMouseEnter={() => {
              setHoverSubHeadProfile("profile");
            }}
            onMouseLeave={() => {
              setHoverSubHeadProfile("");
            }}
            onClick={() => {
              setOpeningSubHeadProfile("profile");
            }}>
            Profile
            {(hoverSubHeadProfile == "profile" ||
              openingSubHeadProfile == "profile") && (
              <div className='sub-head-profile-underline'></div>
            )}
          </div>
          <div
            className='sub-head-profile'
            onMouseEnter={() => {
              setHoverSubHeadProfile("manage");
            }}
            onMouseLeave={() => {
              setHoverSubHeadProfile("");
            }}
            onClick={() => {
              setOpeningSubHeadProfile("manage");
            }}>
            Manage
            {(hoverSubHeadProfile == "manage" ||
              openingSubHeadProfile == "manage") && (
              <div className='sub-head-profile-underline'></div>
            )}
          </div>
        </div>
        {openingSubHeadProfile == "profile" ? (
          <ProfileInfo />
        ) : (
          openingSubHeadProfile == "manage" && <ChangePassword />
        )}
        {/* <div className='token-lists'>
          <div className='side-head'>Activated Tokens : </div>
          <div className='all-tokens'>
            {tokenLists &&
              tokenLists.map((el) => {
                return (
                  <div className='token-clipboard-expire'>
                    <div className='token-text'>
                      {el.token.length > 15
                        ? el.token.substring(0, 14) + "..."
                        : el.token}
                    </div>
                    <div
                      className='clipboard-icon'
                      onClick={() => {
                        navigator.clipboard.writeText(session_token);
                      }}>
                      <MdContentCopy />
                    </div>
                    <div className='expiring-time'>
                      {"("}
                      <span>
                        {" " +
                          Math.floor(
                            ((el.expiringOn - exp) / 1000 / 60 / 60) % 24
                          )}
                        hrs
                        {" " +
                          Math.floor(((el.expiringOn - exp) / 1000 / 60) % 60)}
                        mins
                        {" " + Math.floor(((el.expiringOn - exp) / 1000) % 60)}s
                      </span>
                      {")"}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>  */}

        {/* <div className='edit-btns'>
          <button>Edit</button>
          <button>Save</button>
        </div> */}
      </div>
      {/* {openChangePasswordPop == true && (
        <div className='change-password-overlay'></div>
      )}
      {openChangePasswordPop == true && (
        <div className='change-password-box'>
          <ChangePassword setOpenChangePasswordPop={setOpenChangePasswordPop} />
        </div>
      )} */}
    </div>
  );
};

export default ProfilePage;
