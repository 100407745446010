import { SAVEDINPUTS } from "../../Actions/SavedInputs/SavedInputsAction"

const init = {
    savedInputs:undefined
}
export const savedInputsReducer = (state = init, { type, payload }) => {
    // console.log(payload);
    switch (type) {
        case SAVEDINPUTS:
            return {...state,savedInputs: payload}
        default:
            return state
    }
}