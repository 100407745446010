import React, { useEffect, useState } from "react";
import "./ImageSelection.css";
import PreviewSelectionPage from "../PreviewSelectionPage/PreviewSelectionPage";
import { AiOutlineClose, AiFillInfoCircle } from "react-icons/ai";
import { IoMdCheckbox } from "react-icons/io";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Skeleton from "@mui/material/Skeleton";
import ProgressiveImage from "react-progressive-graceful-image";
import placeHolder from "../../Images/dummy.png";
import InformativeTooltip from "../../Components/InformativeTooltip/InformativeTooltip";
import { TbArrowAutofitHeight, TbArrowAutofitWidth } from "react-icons/tb";
import { FaLayerGroup, FaSatellite } from "react-icons/fa";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { CgStack } from "react-icons/cg";
import ImageMetadata from "./ImageMetadata/ImageMetadata";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
import { useDispatch } from "react-redux";
function ImageSelection({
  setOpenImageSelectionPage,
  startTesting,
  setStartTesting,
  modelId,
  testingType,
  setStartDummyTesting,
  setShowHardwareResult,
  setOutputResult,
}) {
  const imageExtensionsGroup = [
    ".png",
    ".jpeg",
    ".jpg",
    ".image/png",
    ".image/jpeg",
  ];
  let imageExt = ".tif";
  const [images, setImages] = useState();
  const [constantImgResult, setConstantImgResult] = useState();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const [selectedImagesForTesting, setSelectedImagesForTesting] = useState();
  const [selectedImagesForPreview, setSelectedImageesForPreview] = useState();
  const [selectedImagesId, setSelectedImagesId] = useState();
  const [openImagePop, setOpenImagePop] = useState(false);
  const [hoverOverImage, setHoverOverImage] = useState(null);
  const [openImageMetadata, setOpenImageMetadata] = useState();
  const [openFilterDropdown, setOpenFilterDropdown] = useState(false);
  const [filteredSensor, setFilteredSensor] = useState();
  const [totalSensors, setTotalSensors] = useState([]);
  const dispatch = useDispatch();

  // First load it will call the function fetch the images
  useEffect(() => {
    fetchAllImages();
  }, []);

  // Fetch all image from the cloud
  function fetchAllImages() {
    let url =
      process.env.REACT_APP_BASE_URL + "/v1/images/getImagesFromCloudByUserId";
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        setConstantImgResult(result);
        setImages(result);
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }

  // Selecting the image for testing
  function selectImage(image) {
    // Previously we are checking the extension of the image but still it will work as we have all the .tif images
    if (
      (imageExtensionsGroup.includes(imageExt) == true &&
        imageExtensionsGroup.includes(image.extension) == true) ||
      (imageExtensionsGroup.includes(imageExt) == false &&
        imageExtensionsGroup.includes(image.extension) == false)
    ) {
      // Next phase development code

      // if (selectedImagesForTesting == undefined) {
      //   setSelectedImagesForTesting([
      //     {
      //       image_id: image.image_id,
      //       image_s3_link: image.s3_presigned_url,
      //       image_preview_link: image.preview_presigned_url,
      //       cloud_mask_url: image.cloud_mask_url,
      //       cloud_shadow_mask_url: image.cloud_shadow_mask_url,
      //       no_data_mask_url: image.no_data_mask_url,
      //     },
      //   ]);
      //   setSelectedImageesForPreview([
      //     {
      //       image_id: image.image_id,
      //       image_s3_link: image.preview_presigned_url,
      //       image_preview_link: image.preview_presigned_url,
      //       cloud_mask_url: image.cloud_mask_url,
      //       cloud_shadow_mask_url: image.cloud_shadow_mask_url,
      //       no_data_mask_url: image.no_data_mask_url,
      //     },
      //   ]);
      //   setSelectedImagesId([image.image_id]);
      // } else {
      //   let arr = [...selectedImagesForTesting];
      //   let arrPrv = [...selectedImagesForPreview];
      //   let present = false;
      //   let indexToRemove = -1;
      //   for (let i = 0; i < arr.length; i++) {
      //     if (arr[i].image_id == image.image_id) {
      //       present = true;
      //       indexToRemove = i;
      //       break;
      //     }
      //   }
      //   if (present == false) {
      //     setSelectedImagesId([...selectedImagesId, image.image_id]);
      //     arr.push({
      //       image_id: image.image_id,
      //       image_s3_link: image.s3_presigned_url,
      //       image_preview_link: image.preview_presigned_url,
      //       cloud_mask_url: image.cloud_mask_url,
      //       cloud_shadow_mask_url: image.cloud_shadow_mask_url,
      //       no_data_mask_url: image.no_data_mask_url,
      //     });
      //     arrPrv.push({
      //       image_id: image.image_id,
      //       image_s3_link: image.preview_presigned_url,
      //       image_preview_link: image.preview_presigned_url,
      //       cloud_mask_url: image.cloud_mask_url,
      //       cloud_shadow_mask_url: image.cloud_shadow_mask_url,
      //       no_data_mask_url: image.no_data_mask_url,
      //     });
      //   } else {
      //     arr.splice(indexToRemove, 1);
      //     arrPrv.splice(indexToRemove, 1);
      //     setSelectedImagesId(
      //       selectedImagesId.filter((el, id) => id != indexToRemove)
      //     );
      //   }
      //   setSelectedImagesForTesting(arr);
      //   setSelectedImageesForPreview(arrPrv);
      // }

      //For this phase of development
      // Just selecting one image at a time
      setSelectedImagesForTesting([
        {
          image_id: image.image_id,
          image_s3_link: image.s3_presigned_url,
          image_preview_link: image.preview_presigned_url,
          cloud_mask_url: image.cloud_mask_url,
          cloud_shadow_mask_url: image.cloud_shadow_mask_url,
          no_data_mask_url: image.no_data_mask_url,
        },
      ]);
      // Just selecting one image at a time to show the preview
      setSelectedImageesForPreview([
        {
          image_id: image.image_id,
          image_s3_link: image.preview_presigned_url,
          image_preview_link: image.preview_presigned_url,
          cloud_mask_url: image.cloud_mask_url,
          cloud_shadow_mask_url: image.cloud_shadow_mask_url,
          no_data_mask_url: image.no_data_mask_url,
        },
      ]);
      // It is used to check if the image is selected or not (mostly useful for multiple selection and checkbox)
      setSelectedImagesId([image.image_id]);
    }
  }
  // It is used to remove image from the selected image array
  function removingSelectedImages(id) {
    let arr = [...selectedImagesForTesting];
    let arrPrv = [...selectedImagesForPreview];
    let arrIds = [...selectedImagesId];

    arr.splice(id, 1);
    arrPrv.splice(id, 1);
    arrIds.splice(id, 1);
    setSelectedImagesForTesting(arr);
    setSelectedImageesForPreview(arrPrv);
    setSelectedImagesId(arrIds);
  }

  // Cloase the image selection pop up
  function closePop() {
    setOpenImagePop(false);
  }
  useEffect(() => {
    if (constantImgResult) {
      const totalSensorArr = ["All GSD"];
      for (let i = 0; i < constantImgResult.length; i++) {
        if (
          totalSensorArr.includes(
            constantImgResult[i].sensor_name.split(",")[1]
          ) == false
        ) {
          totalSensorArr.push(constantImgResult[i].sensor_name.split(",")[1]);
        }
      }
      const sortedGSD = [...totalSensorArr].sort((a, b) => getLengthInMeters(a) - getLengthInMeters(b));
      setTotalSensors(sortedGSD);
    }
  }, [constantImgResult]);

  // It is used to filter the images based on the sensor resolution
  useEffect(() => {
    if (filteredSensor == "All GSD") {
      setImages(constantImgResult);
    } else if (filteredSensor) {
      let filteredImgArr = [];
      for (let i = 0; i < constantImgResult.length; i++) {
        if (constantImgResult[i].sensor_name.split(",")[1] == filteredSensor) {
          filteredImgArr.push(constantImgResult[i]);
        }
      }
      setImages(filteredImgArr);
    }
  }, [filteredSensor]);
   
  function getLengthInMeters(sensorName) {
    if (sensorName == "All GSD") return -1;
    
    const regex = /(\d+(\.\d+)?)(cm|m)/;
    const match = sensorName.match(regex);
    
    if (!match) {
      throw new Error("Invalid length format");
    }
    
    const [_, value, , unit] = match;
    const length = parseFloat(value);
  
    // Convert everything to meters for uniformity
    const lengthInMeters = unit === 'cm' ? length / 100 : length;
  
    return lengthInMeters;
  }
  
  return (
    <div className='image-galary'>
      {!images && (
        <div>
          <div className='closing-btn-for-image-selction'>
            <div className='adding-img-text'>
              Select Images For Testing{" "}
              <span
                onMouseEnter={(e) => {
                  e.currentTarget.style.position = "relative";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.position = "static";
                }}
                className='informative-icon'>
                <AiFillInfoCircle />
                <InformativeTooltip
                  info='Select images in either TIF or JPEG format for testing purposes.'
                  doc=''
                  icon={false}
                />
              </span>
            </div>
            <button
              onClick={() => {
                setOpenImageSelectionPage(false);
              }}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='skeleton-image-div'>
            {Array(8)
              .fill(0)
              .map((el) => (
                <div>
                  <Skeleton variant='rectangular' width='100%' height='80%' />
                  <Skeleton variant='text' width='100%' height={50} />
                </div>
              ))}
          </div>
        </div>
      )}
      {images && (
        <div>
          <div className='closing-btn-for-image-selction'>
            <div className='adding-img-text'>
              Select Images For Testing{" "}
              <span
                onMouseEnter={(e) => {
                  e.currentTarget.style.position = "relative";
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.position = "static";
                }}
                className='informative-icon'>
                <AiFillInfoCircle />
                <InformativeTooltip
                  info='Select images in either TIF or JPEG format for testing purposes.'
                  doc=''
                  icon={false}
                />
              </span>
              {totalSensors.length > 2 && (
                <div className='filter-div-for-img'>
                  <div
                    onClick={() => {
                      setOpenFilterDropdown(!openFilterDropdown);
                    }}
                    className='selected-filter-for-img'>
                    <span>
                      {filteredSensor ? filteredSensor : "Select Resolution"}
                    </span>
                    {openFilterDropdown ? (
                      <BsCaretUpFill />
                    ) : (
                      <BsCaretDownFill />
                    )}
                  </div>
                  <div
                    className={`${
                      openFilterDropdown
                        ? "filter-options-for-img-open"
                        : "filter-options-for-img-close"
                    }`}>
                    <div className='filter-options-div'>
                      {totalSensors.map((sensor, id) => (
                        <div
                          key={id}
                          onClick={() => {
                            setFilteredSensor(sensor);
                            setOpenFilterDropdown((f) => !f);
                          }}>
                          {sensor}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={() => {
                setOpenImageSelectionPage(false);
                setShowHardwareResult(undefined);
              }}>
              <AiOutlineClose />
            </button>
          </div>
          <div className='images-box'>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 4 }}>
              <Masonry gutter='10px'>
                {images.map((image, id) => (
                  <div
                    onMouseEnter={() => {
                      setHoverOverImage(id);
                    }}
                    onMouseLeave={() => {
                      setHoverOverImage(null);
                    }}
                    onClick={(event) => {
                      if (
                        !event.target.classList.contains("metadata-more-btn")
                      ) {
                        selectImage(image);
                      }
                    }}
                    className='single-img-div'>
                    <ProgressiveImage
                      src={image.preview_presigned_url}
                      placeholder={placeHolder}>
                      {(src, loading) => (
                        <img
                          key={id}
                          src={src}
                          style={{
                            width: "100%",
                            display: "block",
                          }}
                          alt=''
                        />
                      )}
                    </ProgressiveImage>

                    {selectedImagesId == undefined ? (
                      <MdCheckBoxOutlineBlank
                        className='checkbox-icon-image-unchecked pointer-hand'
                        onClick={() => {
                          selectImage(image);
                        }}
                      />
                    ) : selectedImagesId.includes(image.image_id) == true ? (
                      <IoMdCheckbox
                        className='checkbox-icon-image-checked pointer-hand'
                        onClick={() => {
                          selectImage(image);
                        }}
                      />
                    ) : (
                      <MdCheckBoxOutlineBlank
                        className='checkbox-icon-image-unchecked pointer-hand'
                        onClick={() => {
                          selectImage(image);
                        }}
                      />
                    )}
                    {/* <div className='image-ext'>{image.extension}</div> */}
                    {selectedImagesId &&
                      selectedImagesId.includes(image.image_id) == true && (
                        <div className='image-overlay'>
                          {/* <p>Selected</p> */}
                        </div>
                      )}
                    {((imageExtensionsGroup.includes(imageExt) == true &&
                      imageExtensionsGroup.includes(image.extension) ==
                        false) ||
                      (imageExtensionsGroup.includes(imageExt) == false &&
                        imageExtensionsGroup.includes(image.extension) ==
                          true)) && (
                      <div className='image-overlay-not-to-be-selected'></div>
                    )}
                    <div
                      className={`${
                        hoverOverImage == id
                          ? "image-overlay-for-hover"
                          : "image-overlay-for-hover-null"
                      }`}></div>
                    <div
                      className={`${
                        hoverOverImage == id
                          ? "image-details"
                          : "image-details-null"
                      }`}>
                      <div className='image-details-heading'>Image Details</div>
                      <div
                        style={{
                          fontFamily: "cursive",
                        }}
                        className='image-details-content'>
                        <div>
                          <FaSatellite /> : {image.sensor_name.split(",")?.[0]}
                        </div>
                        <div>
                          <BsFillGrid3X3GapFill /> :{" "}
                          {image.sensor_name.split(",")?.[1]}
                        </div>

                        <div>
                          <TbArrowAutofitHeight /> :{" "}
                          {
                            JSON.parse(image.metadata)?.SensorData.Dimension
                              ?.Height
                          }
                        </div>
                        <div>
                          <TbArrowAutofitWidth /> :{" "}
                          {
                            JSON.parse(image.metadata)?.SensorData.Dimension
                              ?.Width
                          }
                        </div>
                        <div>
                          <FaLayerGroup /> :{" "}
                          {
                            JSON.parse(image.metadata)?.SensorData
                              ?.NumberOfBands
                          }
                        </div>
                        <div>
                          <CgStack /> :{" "}
                          {Object.values(
                            JSON.parse(image.metadata).SensorData.Bands
                          )
                            .filter((band) => band != "Nil")
                            .map((band) => {
                              if (band == "NIR") return "NIR";
                              else return band[0];
                            })
                            .join(", ")}
                        </div>
                        <div
                          className='metadata-more-btn pointer-hand'
                          onClick={(event) => {
                            event.stopPropagation();
                            console.log(image.metadata);
                            setOpenImageMetadata({
                              metadata: image.metadata,
                              imageUrl: image.preview_presigned_url,
                              imageId: image.image_id,
                            });
                          }}>
                          more
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
          <div className='saving-image-btn'>
            <button
              onClick={() => {
                setOpenImageSelectionPage(false);
                setShowHardwareResult(undefined);
              }}>
              Cancel
            </button>
            <button
              disabled={
                selectedImagesForPreview == undefined ||
                selectedImagesForPreview.length == 0
                  ? true
                  : false
              }
              onClick={() => {
                setOpenImagePop(true);
              }}>
              Next
            </button>
          </div>
          {(openImagePop || openImageMetadata) && (
            <div className='image-pop-preview-overlay'></div>
          )}
          {openImageMetadata && (
            <ImageMetadata
              metadata={openImageMetadata.metadata}
              setOpenImageMetadata={setOpenImageMetadata}
              imageUrl={openImageMetadata.imageUrl}
              imageId={openImageMetadata.imageId}
            />
          )}
          {openImagePop && (
            <div className='image-pop-preview'>
              <div className='image-pop-review-content'>
                <PreviewSelectionPage
                  //   setOpenImagePop={setOpenImagePop}
                  closePop={closePop}
                  setOpenImageSelectionPage={setOpenImageSelectionPage}
                  selectedImagesForPreview={selectedImagesForPreview}
                  selectedImagesForTesting={selectedImagesForTesting}
                  modelId={modelId}
                  testingType={testingType}
                  Session_Token={Session_Token}
                  removingSelectedImages={removingSelectedImages}
                  setStartTesting={setStartTesting}
                  setStartDummyTesting={setStartDummyTesting}
                  setShowHardwareResult={setShowHardwareResult}
                  setOutputResult={setOutputResult}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ImageSelection;
