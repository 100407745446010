import { getToken } from "firebase/messaging";

export async function callingFcmApp(email,sessionToken,messaging) {
  console.log(messaging)
    if (messaging) {
      try {
        const token = await getToken(messaging);
        console.log('Token:', token);
        sendingTokenToServer(token, email,sessionToken);
        // Send the token to your server here
      } catch (err) {
          if (err.code === 'messaging/permission-blocked') {
          alert('User has blocked notifications.');
          // Handle the case where the user has blocked notifications
        } else if (err.code === 'messaging/permission-default') {
          alert('User has not granted or denied notifications.');
          // Handle the case where the user has not granted or denied notifications
        } else {
          console.log('An error occurred while retrieving token. ', err);
          // Handle any other errors
        }
      }
    }
    else {
      // window.location.reload();
    }

    function sendingTokenToServer(token,email,sessionToken) {
      var myHeaders = new Headers();
      myHeaders.append("Session-Token", sessionToken);
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "token": token,
        "email": email
      });
      
      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(process.env.REACT_APP_BASE_URL+"/v1/notify/updateFCMTokenForUser", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }
  }