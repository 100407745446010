import React from "react";
import ReactLoading from "react-loading";

const LoadingSpinner = () => {
  return (
    <div className='spinner'>
      <ReactLoading
        type='spinningBubbles'
        color='#ffffff'
        height={80}
        width={80}
      />
    </div>
  );
};

export default LoadingSpinner;
