import { COORDINATES } from "../../Actions/coordinates/CoordinatesAction";

const init = {
    points: undefined
}
  
export const coordinatesReducer = (state = init, { type, payload }) => {
    // console.log(payload);
    switch (type) {
        case COORDINATES:
            return {...state,points:payload}
        default:
            return state
    }
}