import React, { useEffect, useState } from "react";
import { ImArrowRight2, ImArrowLeft2 } from "react-icons/im";
import "./ModelSubmission.css";
import { useDispatch } from "react-redux";
import SectionProgress from "./SectionProgress/SectionProgress";
import { useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllModels } from "../../Redux/Actions/AllModels/AllModelsAction";
import { getLatestModel } from "../../Redux/Actions/LatestSubmission/LatestSubmissionAction";
import { getModel } from "../../Redux/Actions/Models/ModelAction";
import { getSubmissionError } from "../../Redux/Actions/SubmissionError/SubmissionErrorAction";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import { getDraftSubmit } from "../../Redux/Actions/DraftSubmitModel/DraftSubmit";
import pro from "../../Images/pro.png";
import beginner from "../../Images/beginner.png";
import Section1 from "./Section1/Section1";
import Section2 from "./Section2/Section2";
import Section3 from "./Section3/Section3";
import Section4 from "./Section4/Section4";
import { getResubmitModel } from "../../Redux/Actions/ResubmitModel/ResubmitModel";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function Model_submission({ resubmitModel, draftModel, proMode, setProMode }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sectionNo, setSectionNo] = useState(1);
  const [giveInput, setGiveInput] = useState(false);
  const session_token = localStorage.getItem("session-token-skyserve");
  const [secResponse, setSecResponse] = useState();
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [submitModelDetails, setSubmitModelDetails] = useState({
    model_name: "",
    model_description: "",
    model_type: "",
    model_location: {
      model_repo_url: "",
      model_repo_auth_key: "",
      model_path: "",
      model_test_path: "",
    },
    visibility: "private",
    model_config_structure: [],
    input_bands: [],
    cloud_masks: [],
    output_bands: [],
    model_state: "",
    level_of_processing: "L1B",
  });

  // This function is used to update the submitModelDetails state with the data from each section of the form.
  function sectionWiseResponse(data) {
    let obj = { ...submitModelDetails };
    let newData = Object.assign(obj, data);
    setSubmitModelDetails(newData);
    validateInputAndSubmit("Activated", newData, sectionNo);
    setGiveInput(false);
  }

  // This function checks if any required field is missing in the form. If so, it returns true, otherwise false.
  function disableSubmitbtn() {
    const {
      model_name,
      model_description,
      model_type,
      model_location,
      input_bands,
      output_bands,
      level_of_processing,
    } = submitModelDetails;
    if (
      model_name == "" ||
      model_description == "" ||
      model_type == "" ||
      model_location.model_repo_url == "" ||
      model_location.model_repo_auth_key == "" ||
      (model_location.model_path == "" && model_type == "dl") ||
      input_bands == [] ||
      output_bands == [] ||
      level_of_processing == ""
    ) {
      return true;
    }
    return false;
  }

  // This function validates the form data and sends a POST request to the /v1/model/submitModel endpoint to submit the model.
  function validateInputAndSubmit(state, submitModelDetails, section) {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);
    myHeaders.append("Content-Type", "application/json");
    let details = { ...submitModelDetails };
    details.model_state = state;
    details.model_description = details.model_description.trim();
    if (proMode == false) {
      details.section = section;
    }
    var raw = JSON.stringify(details);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/submitModel",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // Spinner action to false after getting response from the backend
        dispatch(spinnerAction(false));
        if (result.Status == 200) {
          // Set the response from the backend to the state
          setSecResponse(result);
          // Set all the error to null if there is not any error from backend
          dispatch(getSubmissionError(null));
          // Set getModel to null so that we can view all the models in the monitor page rather than view any model details
          dispatch(getModel(null));
        } else if (
          (result.Status >= 410 && result.Status <= 418) ||
          result.Status == 435
        ) {
          if (result.Field == "") {
            toast.error(result.Message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            dispatch(getSubmissionError(result));
          }
        } else {
          // Set Model Response from result
          setSecResponse(result);
          // Make Seubmission Error to null if there is not any error from backend
          dispatch(getSubmissionError(null));
          // make getModel to null to view all the models in the monitor page rather than view any model details
          dispatch(getModel(null));
          // Fetching all model details after successful submission
          allModelsAfterSubmission();
          // Set latest Model result to the state to show the model details in the latest submission page
          dispatch(getLatestModel({ model_details: result }));
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }

  // This function sends a GET request to the /v1/model/listModelsWithVersionByUserId endpoint to fetch all models after a successful submission.
  function allModelsAfterSubmission() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        "/v1/model/listModelsWithVersionByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result == null) {
          // If there is not any models then No data will be there
          dispatch(getAllModels("No data"));
          navigate("/developer/monitor");
        } else {
          // If there are models then make it sort in reverse order.
          dispatch(getAllModels(result.reverse()));
          navigate("/developer/monitor");
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }

  // This function validates the form data and sends a PATCH request to the /v1/model/updateModelSubmission/${model_id} endpoint
  function validatingAndSave(state, submitModelDetails, model_id) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);
    myHeaders.append("Content-Type", "application/json");
    let details = { ...submitModelDetails };
    details.model_state = state;
    var raw = JSON.stringify(details);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/updateModelSubmission/${model_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.Status == 200) {
          setSecResponse(result);
          dispatch(getSubmissionError(null));
        } else if (
          (result.Status >= 410 && result.Status <= 418) ||
          result.Status == 435
        ) {
          if (result.Field == "") {
            // Toast error message for any error
            toast.error(result.Message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            dispatch(getSubmissionError(result));
          }
        } else {
          // Set Model Response from result
          setSecResponse(result);
          // Make Seubmission Error to null if there is not any error from backend
          dispatch(getSubmissionError(null));
          // make getModel to null to view all the models in the monitor page rather than view any model details
          dispatch(getModel(null));
          // Fetching all model details after successful submission
          allModelsAfterSubmission();
          // Set latest Model result to the state to show the model details in the latest submission page
          dispatch(getLatestModel({ model_details: result }));
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }

  // This function sets the giveInput state to true, indicating that the user can proceed to the next section of the form.
  function goingToNextSection() {
    setGiveInput(true);
  }

  //It is used to pre-fill the form data if the user is resubmitting or drafting a model.
  useEffect(() => {
    // When resubmit model or draft model is there then set the mode to ProMode and set the section number to 0
    if (resubmitModel || draftModel) {
      setProMode(true);
      setSectionNo(0);
    }
    if (resubmitModel) {
      let obj = { ...submitModelDetails };
      for (let key in obj) {
        if (resubmitModel[key]) {
          obj[key] = resubmitModel[key];
        }
      }
      setSubmitModelDetails(obj);
    } else if (draftModel) {
      let obj = { ...submitModelDetails };
      for (let key in obj) {
        if (draftModel[key]) {
          obj[key] = draftModel[key];
        }
      }
      setSubmitModelDetails(obj);
    }
  }, [resubmitModel, draftModel]);

  // It is used to navigate to the next section of the form or the /developer/monitor route based on the secResponse state.
  useEffect(() => {
    if (secResponse && proMode == false && secResponse.Status == 200) {
      if (sectionNo < 4) {
        // If section is not 4 then it just go to next section
        setSectionNo((prev) => prev + 1);
        setSecResponse();
      }
    } else if (secResponse && secResponse.Status == undefined) {
      navigate("/developer/monitor");
    }
  }, [secResponse]);
  return (
    <div className='submission-page'>
      <ToastContainer />
      <div className='model-heading'>
        <div className='submit-model-text'>
          Submit Model
          <div className='submit-model-underline'></div>
        </div>
        <div className='mode-selection-div'>
          <button
            className={`${
              proMode == true ? "mode-btn expert-mode" : "mode-btn"
            }`}
            onClick={() => {
              setProMode(true);
              setSectionNo(1);
              setSecResponse();
            }}>
            <img src={pro} alt='' /> Expert
          </button>
          <button
            className={`${
              proMode == false ? "mode-btn beginner-mode" : "mode-btn"
            }`}
            disabled={resubmitModel || draftModel ? true : false}
            onClick={() => {
              setProMode(false);
              setSectionNo(1);
            }}>
            <img src={beginner} alt='' /> Beginner
          </button>
        </div>
      </div>
      {proMode == false && !resubmitModel && !draftModel && (
        <div className='section-progress'>
          <SectionProgress sectionNo={sectionNo} setSectionNo={setSectionNo} />
        </div>
      )}
      {proMode == false && !resubmitModel && !draftModel && (
        <div>
          <div className='submit-model-body-beginner'>
            {sectionNo === 1 ? (
              <Section1
                submitModelDetails={submitModelDetails}
                sec1Response={sectionWiseResponse}
                giveInput={giveInput}
                setNextBtnDisabled={setNextBtnDisabled}
                proMode={proMode}
              />
            ) : sectionNo === 2 ? (
              <Section2
                submitModelDetails={submitModelDetails}
                sec2Response={sectionWiseResponse}
                giveInput={giveInput}
                setNextBtnDisabled={setNextBtnDisabled}
                proMode={proMode}
              />
            ) : sectionNo === 3 ? (
              <Section3
                submitModelDetails={submitModelDetails}
                sec3Response={sectionWiseResponse}
                giveInput={giveInput}
                setNextBtnDisabled={setNextBtnDisabled}
                proMode={proMode}
              />
            ) : (
              sectionNo === 4 && (
                <Section4
                  submitModelDetails={submitModelDetails}
                  sec4Response={sectionWiseResponse}
                  giveInput={giveInput}
                  setNextBtnDisabled={setNextBtnDisabled}
                  proMode={proMode}
                />
              )
            )}
          </div>
        </div>
      )}
      {proMode == true && (
        <div>
          <div className='proMode-active-div'>
            <div>
              <Section1
                submitModelDetails={submitModelDetails}
                sec1Response={sectionWiseResponse}
                setNextBtnDisabled={setNextBtnDisabled}
                proMode={proMode}
                setSubmitModelDetails={setSubmitModelDetails}
                resubmitModel={resubmitModel}
                draftModel={draftModel}
              />
            </div>
            <div className='proMode-left'>
              <Section2
                submitModelDetails={submitModelDetails}
                sec2Response={sectionWiseResponse}
                setNextBtnDisabled={setNextBtnDisabled}
                proMode={proMode}
                setSubmitModelDetails={setSubmitModelDetails}
                resubmitModel={resubmitModel}
                draftModel={draftModel}
              />
            </div>
            <div className='proMode-right'>
              <div>
                <Section3
                  submitModelDetails={submitModelDetails}
                  sec3Response={sectionWiseResponse}
                  setNextBtnDisabled={setNextBtnDisabled}
                  proMode={proMode}
                  setSubmitModelDetails={setSubmitModelDetails}
                  resubmitModel={resubmitModel}
                  draftModel={draftModel}
                />
                <div className='sec-4-pro-mode'>
                  <Section4
                    submitModelDetails={submitModelDetails}
                    sec4Response={sectionWiseResponse}
                    setNextBtnDisabled={setNextBtnDisabled}
                    proMode={proMode}
                    setSubmitModelDetails={setSubmitModelDetails}
                    resubmitModel={resubmitModel}
                    draftModel={draftModel}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='submission-btn-div'>
            <button
              className='save'
              onClick={() => {
                setSubmitModelDetails({
                  model_name: "",
                  model_description: "",
                  model_type: "",
                  model_location: {
                    model_repo_url: "",
                    model_repo_auth_key: "",
                    model_path: "",
                    model_test_path: "",
                  },
                  visibility: "",
                  model_config_structure: [],
                  input_bands: [],
                  cloud_masks: [],
                  output_bands: [],
                  model_state: "",
                  level_of_processing: "",
                });
                if (resubmitModel) {
                  dispatch(getResubmitModel());
                  navigate(-1);
                }
                if (draftModel) {
                  dispatch(getDraftSubmit());
                  navigate(-1);
                }
              }}>
              Cancel
            </button>
            <button
              disabled={disableSubmitbtn()}
              style={{
                cursor: disableSubmitbtn() ? "not-allowed" : "pointer",
              }}
              className='save'
              onClick={() => {
                if (
                  (!resubmitModel && !draftModel) ||
                  (resubmitModel && resubmitModel.model_state != "Draft")
                ) {
                  validateInputAndSubmit("Draft", submitModelDetails);
                } else if (draftModel) {
                  validatingAndSave(
                    "Draft",
                    submitModelDetails,
                    draftModel.model_id
                  );
                }
              }}>
              Save
            </button>
            <button
              disabled={disableSubmitbtn()}
              style={{
                backgroundColor: disableSubmitbtn() ? "gray" : "#0072bc",
                cursor: disableSubmitbtn() ? "not-allowed" : "pointer",
                color: disableSubmitbtn() ? "white" : "white",
                boxShadow: disableSubmitbtn()
                  ? "none"
                  : "0px 2px 4px rgba(26, 160, 83, 0.3)",
                border: disableSubmitbtn()
                  ? "1px solid gray"
                  : "1px solid #1AA053",
              }}
              className='submit'
              onClick={() => {
                // setGiveInputAtOnce(true);
                if (resubmitModel) {
                  validateInputAndSubmit("Activated", submitModelDetails);
                } else if (draftModel) {
                  validatingAndSave(
                    "Draft",
                    submitModelDetails,
                    draftModel.model_id
                  );
                } else {
                  validateInputAndSubmit("Activated", submitModelDetails);
                }
              }}>
              Submit
            </button>
          </div>
        </div>
      )}
      {proMode == false && !resubmitModel && !draftModel && (
        <div className='submission-btn-div'>
          <button
            className='save'
            onClick={() => {
              if (sectionNo > 1) {
                setSectionNo((prev) => prev - 1);
              }
            }}>
            <ImArrowLeft2 /> Prev
          </button>
          {sectionNo <= 3 && (
            <button
              style={{
                cursor: nextBtnDisabled ? "not-allowed" : "pointer",
              }}
              className='save'
              disabled={nextBtnDisabled}
              onClick={() => {
                if (sectionNo < 4) {
                  goingToNextSection();
                }
              }}>
              Next <ImArrowRight2 />
            </button>
          )}
          {sectionNo == 4 && (
            <button
              className='submit'
              disabled={nextBtnDisabled}
              onClick={() => {
                goingToNextSection();
              }}>
              Submit
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Model_submission;
