import { SPINNER } from "../../Actions/Spinner/spinnerAction"

const init = {
    spinner:undefined
}
export const spinnerReducer = (state = init, { type, payload }) => {
    // console.log(payload,"reducer")
    switch (type) {
        case SPINNER:
            return {...state,spinner:payload}
        default:
            return state
    }
}