import React from "react";
import logo from "../../Images//logo-png-1.png";
import "./PortalNavbarPage.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import { getAlertAfterEmailPop } from "../../Redux/Actions/AlertAfterEmailVerification/AlertAfterEmailVerification";
import BasicMenu from "./Menu/Menu";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function PortalNavbarPage({ portal }) {
  const router = useLocation();
  const emailVerified = useSelector(
    (store) => store.emailVerified.emailVerified
  );
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const dispatch = useDispatch();

  // Call the API to verify the email
  function verifyEmail() {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: userDetails.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/auth/verification_initialize",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(spinnerAction(false));
        dispatch(getAlertAfterEmailPop(true));
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }
  console.log(emailVerified);
  return (
    <div className='portal-navbar-page'>
      <div className='navbar-page'>
        <div className='logo-img'>
          <Link to='/'>
            <img src={logo} alt='' />
          </Link>
        </div>
        <div className='navbar-routes'>
          <div>
            <div>
              <a
                className='earthsights-link'
                target='_blank'
                href='https://earthsights.skyserve.ai/'>
                EarthSights
              </a>
            </div>
            <div className={`${portal == "developer" && "active-portal"}`}>
              <Link to='/developer/overview'>Surge</Link>

              {router.pathname.includes("/developer/") && (
                <div className='active-underline'></div>
              )}
            </div>
            {/* <div className={`${portal == "user" && "inactive-portal"}`}>
              User Portal
            </div> */}
            {/* <div>
              <a
                target='_blank'
                href='https://sky-serve-developer-portal-documentation.vercel.app/documentation/submit-model'>
                Docs
              </a>
            </div> */}
          </div>
          <div className='basic-menu'>
            {userDetails && emailVerified == false && (
              <div>
                <button
                  onClick={() => {
                    verifyEmail();
                  }}
                  className='email-verification-btn'>
                  Verify Your Email
                </button>
              </div>
            )}
            <BasicMenu />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortalNavbarPage;
