import React, { useState } from "react";
import "./ImageSubmittedSuccessPage.css";
import testing from "./images/testing.png";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { getAllModels } from "../../../Redux/Actions/AllModels/AllModelsAction";
import { getopenSessionToken } from "../../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function ImageSubmittedSuccessPage({ closePop, setOpenImageSelectionPage }) {
  const [time, setTime] = useState(10);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  if (time > 0) {
    setTime(time - 1);
  }
  if (time == 0) {
    setOpenImageSelectionPage(false);
    closePop();
    navigate("/developer/monitor");
    callingLatestModels();
  }

  function callingLatestModels() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        "/v1/model/listModelsWithVersionByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result == null) {
          dispatch(getAllModels("No data"));
        } else {
          dispatch(getAllModels(result.reverse()));
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }
  return (
    <div>
      <div className='img-sub-pop-box'>
        <div className='mgn-btn-white-box'>
          <div className='closing-icon-pop-div'>
            <IoCloseOutline
              onClick={() => {
                setOpenImageSelectionPage(false);
                closePop();
                navigate("/developer/monitor");
                callingLatestModels();
              }}
            />
          </div>
          <div className='testing-img'>
            <img src={testing} />
          </div>
          <div>
            <div className='testing-in-process'>Testing in process...</div>
            <div className='testing-content'>
              Image testing has started.
              <br /> We will notify you upon completion of the testing process.
            </div>
            <div className='testing-page-times'>
              This page will be closed automatically in the next{" "}
              <span style={{ color: "blue" }}>{time} sec.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSubmittedSuccessPage;
