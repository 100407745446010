import React from "react";
import "./TrackerDemoPage.css";
import tracker from "./images/trackerpage.png";
function TrackerDemoPage() {
  return (
    <div style={{ width: "100%", margin: "auto" }}>
      <div>
        <div className='tracker-model-text'>
          Model Status Tracker
          <div className='submit-model-underline'></div>
        </div>
        <img className='image-ss' src={tracker} />
      </div>
    </div>
  );
}

export default TrackerDemoPage;
