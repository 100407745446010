import React, { useEffect } from "react";
import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import "./ProfileInfo.css";
import { FaClipboardCheck } from "react-icons/fa";
import { spinnerAction } from "../../../Redux/Actions/Spinner/spinnerAction";
import { getopenSessionToken } from "../../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function ProfileInfo() {
  const dispatch = useDispatch();
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const session_token = localStorage.getItem("session-token-skyserve");
  const [showClipBoardPop, setShowClipBoardPop] = useState(false);
  const [changedDetails, setChangedDetails] = useState(userDetails);
  const emailVerified = useSelector(
    (store) => store.emailVerified.emailVerified
  );
  const [editStart, setEditStart] = useState(false);
  function editingInfo() {
    setEditStart(false);
  }
  useEffect(() => {
    setShowClipBoardPop(false);
  }, [showClipBoardPop]);
  function ChangingDetails() {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      method: "profile",
      traits: {
        email: changedDetails.email,
        access: {
          georisk: true,
          earthsights: true,
          ground_server: true,
          row_monitoring: true,
        },
        country: changedDetails.country,
        full_name: changedDetails.full_name,
        company_name: changedDetails.company_name,
        email_verified: emailVerified,
        developer_access: changedDetails.developer_access,
      },
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/auth_one/Change_profile",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(spinnerAction(false));
        localStorage.setItem("user-details", JSON.stringify(changedDetails));
        editingInfo();
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  useEffect(() => {
    if (userDetails.country == undefined) {
      setChangedDetails({ ...changedDetails, country: "" });
    }
    if (userDetails.company_name == undefined) {
      setChangedDetails({ ...changedDetails, company_name: "" });
    }
  }, []);
  return (
    <div>
      {userDetails && (
        <div className='profile-body'>
          {/* <div>
            <span className='side-head'>Active Token : </span>
            <span className='details-user'>
              <span className='toke-user'>{session_token}</span>

              <span
                className='clipboard-icon'
                onClick={() => {
                  navigator.clipboard.writeText(session_token);
                  setShowClipBoardPop(true);
                }}>
                <MdContentCopy />
              </span>
            </span>
          </div> */}
          <div>
            <span className='side-head'>Name : </span>
            {editStart == false && (
              <span className='details-user'>{changedDetails.full_name}</span>
            )}
            {editStart == true && (
              <input
                className='name-change-input'
                defaultValue={changedDetails.full_name}
                onChange={(e) => {
                  setChangedDetails({
                    ...changedDetails,
                    full_name: e.target.value,
                  });
                }}
              />
            )}
          </div>
          <div>
            <span className='side-head'>Email : </span>
            <span className='details-user'>{changedDetails.email}</span>
          </div>
          <div>
            <span className='side-head'>Company : </span>
            <span className='details-user'>{changedDetails.company_name}</span>
          </div>
          <div>
            <span className='side-head'>Country : </span>
            {editStart == false && (
              <span className='details-user'>{changedDetails.country}</span>
            )}
            {editStart == true && (
              <input
                className='name-change-input'
                defaultValue={changedDetails.country}
                onChange={(e) => {
                  setChangedDetails({
                    ...changedDetails,
                    country: e.target.value,
                  });
                }}
              />
            )}
          </div>
          <div className='edit-store-btns'>
            <button
              className={`${
                changedDetails.email_verified == undefined && "disabled"
              }`}
              onClick={() => {
                if (changedDetails.email_verified == true) {
                  setEditStart(true);
                }
              }}>
              Edit
            </button>
            <button
              className={`${
                changedDetails.email_verified == undefined && "disabled"
              }`}
              onClick={() => {
                if (changedDetails.email_verified == true) {
                  ChangingDetails();
                }
              }}>
              Save
            </button>
          </div>
        </div>
      )}
      {showClipBoardPop == true && (
        <div className='bounce-left-div'>
          <div className='bounce-in-left'>
            Copied To Clipboard <FaClipboardCheck />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileInfo;
