import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import dev_not_access from "../Images/overview.png";
import sat_img from "../Images/sat-img.png";
import "./CSS/overview.css";
import AssignedImgPop from "../DeveloperPortalComponents/AssignedImgPop/AssignedImgPop";
import { getAlertAfterEmailPop } from "../Redux/Actions/AlertAfterEmailVerification/AlertAfterEmailVerification";
import { spinnerAction } from "../Redux/Actions/Spinner/spinnerAction";
import { getopenSessionToken } from "../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";

const DevHomeOverview = () => {
  const userEmail = JSON.parse(localStorage.getItem("user-details"));
  const dispatch = useDispatch();
  // Getting email verification status from Redux store
  const emailVerified = useSelector(
    (store) => store.emailVerified.emailVerified
  );
  // State for assigned image and its popup
  const [assignedImg, setAssignedImg] = useState();
  const [openAssignedImgPop, setOpenAssignedImgPop] = useState(false);
  const navigate = useNavigate();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const allModels = useSelector((store) => store.allModels.allModels);
  useEffect(() => {
    if (allModels) {
    }
  }, [allModels]);
  function verifyEmail() {
    // Dispatching spinner action
    dispatch(spinnerAction(true));

    // Setting up headers and body for fetch request
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: userEmail.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    // Making fetch request for email verification
    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/auth/verification_initialize",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(spinnerAction(false));
        dispatch(getAlertAfterEmailPop(true));
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
  }
  return (
    <div className='overview-page'>
      {(emailVerified == false || emailVerified == true) && (
        <div className='without-access'>
          <div className='without-access-heading'>
            <div className='without-access-heading-text'>
              Build continuous monitoring applications using your pre-trained
              models to keep a pulse on the change as it happens.
            </div>
            <div className='without-access-head-img'>
              <img src={dev_not_access} />
            </div>
          </div>
          <div
            style={{ marginTop: "-7%" }}
            className='without-data-text-button-monitor'>
            <div className='button-shadow-monitor'></div>
            {/* <Link to='/developer/submit-model'> */}
            <div
              className='content-btn-monitor add-first-model'
              onClick={() => {
                if (emailVerified == true) {
                  navigate("/developer/submit-model");
                }
              }}>
              Add Your Model
            </div>
            {/* </Link> */}
          </div>
          <div className='widthout-access-description'>
            <div>
              <div className='tick-icon'>
                <img src={sat_img} style={{ height: "1.5vw" }} />
              </div>
              <div>
                Have complete control on the sensor and mission inputs to your
                models.
              </div>
            </div>
            <div>
              <div className='tick-icon'>
                <img src={sat_img} style={{ height: "1.5vw" }} />
              </div>
              <div>
                Check the validity of your model along with any pre-processing
                scripts in the submitted repo.
              </div>
            </div>
            <div>
              <div className='tick-icon'>
                <img src={sat_img} style={{ height: "1.5vw" }} />
              </div>
              <div>
                Test on synthetic and actual sensor data from currently flying
                satellites.
              </div>
            </div>
            <div>
              <div className='tick-icon'>
                <img src={sat_img} style={{ height: "1.5vw" }} />
              </div>
              <div>
                Obtain run times, error logs, tracebacks, and outputs for review
                before requesting deployment to the satellite of interest.
              </div>
            </div>
          </div>
          {emailVerified == false && (
            <div className='checking-email-in-overview'>
              <span
                onClick={() => verifyEmail()}
                className='click-here-btn-for-email'>
                Click here
              </span>
              to verify your email address !
            </div>
          )}
        </div>
      )}
      {/* {openAssignedImgPop && <div className='overlay-assignedImg'></div>}
      {openAssignedImgPop && (
        <div className='assignedPop-modal'>
          <AssignedImgPop
            assignedImg={assignedImg}
            setOpenAssignedImgPop={setOpenAssignedImgPop}
          />
        </div>
      )} */}
    </div>
  );
};

export default DevHomeOverview;
