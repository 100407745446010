import { INACTIVE_VERSION } from "../../Actions/InactiveVersion/InactiveVersion"

const init = {
    inactive:null
}

export const inactiveReducer = (state = init, { type, payload }) => {
    switch (type) {
        case INACTIVE_VERSION:
            return {...state,inactive:payload};
        default:
            return state;
        
    }
}