import React, { useEffect } from "react";
import "./ToastifyPop.css";
import { IoCheckmarkDone } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { getFcmNotification } from "../../Redux/Actions/FCM/fcm";
function ToastifyPop({fcmNotification,setNotificationPayload}) {
  const dispatch = useDispatch();
  const sseMsg = useSelector((store) => store.fcm.notificationPayload);
  useEffect(() => {
    setTimeout(() => {
      setNotificationPayload();
    }, 5000);
  }, [fcmNotification]);
  console.log(fcmNotification)
  return (
    <div className='toastifying'>
      <span className='done-icon'>
        <IoCheckmarkDone />
      </span>
      <span>{fcmNotification?.notification.title}</span>
    </div>
  );
}

export default ToastifyPop;
