import { SSEMSG } from "../../Actions/SSEmsg/SSEmsg"

const init = {
    sseMsg:"",
}

export const sseMsgReducer = (state = init, { type, payload }) => {
    switch (type) {
        case SSEMSG:
            return {...state,sseMsg: payload}
        default: 
            return state
    }
}