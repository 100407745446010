import React, { useEffect, useState } from "react";
import "./ProgressMonitor.css";
import { createUtcToLocalDateOnly } from "../../services/convertUtcToLocalDate";
import { convertUtcToLocal } from "../../services/convertUtcToLocal";
const ProgressMonitor = ({
  model,
  startTesting,
  setStartTesting,
  startHardwareTesting,
}) => {
  const [progressState, setProgressState] = useState(0);
  const [testingInProgress, setTestingInProgress] = useState(false);
  const [validating, setValidating] = useState(false);
  const userMissionAccess = localStorage.getItem("missionAccess");
  const [hardwareTestingInProgress, setHardwareTestingInProgress] = useState(false);
  const [hwTesting, setHwTesting] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState([
    "Submitted",
    "VerificationInProgress",
    "ValidationSuccessful",
    "TestingSuccess",
  ]);

  useEffect(() => {
    if (userMissionAccess === "K2" && (model.status == "HWTestingFailed" || model.status == "HWTestingSuccess" || model.status == "HWTestingInProgress")) {
      setVerificationStatus(prevStatus => {
        const newStatus = [...prevStatus, "HWTestingSuccess"];
        return newStatus;
      });
      setHwTesting(true);
    }
  }, []);
  
  useEffect(() => {
    // If the model is not unsubmitted then it will check the status of the model and then set the progress state
    if (model.status != "Unsubmitted") {
      // If status is in the verificationStatus[] then it will set the progress state
      if (
        verificationStatus.includes(model.status) &&
        model.status != "VerificationInProgress"
      ) {
        setProgressState(verificationStatus.indexOf(model.status));
        // If sets the validating to false and testingInProgress to false
        // So that blinking of the progress bar will stop
        setValidating(false);
        setTestingInProgress(false);
      }
      // If the status is not in the verificationStatus[] then it will check the status and set the progress state
      else {
        if (model.status == "ValidationFailed") {
          setProgressState(2);
          verificationStatus[2] = "ValidationFailed";
          setValidating(false);
          setTestingInProgress(false);
        } else if (
          model.status == "DownloadRepoSuccess" ||
          model.status == "UploadModelSuccess" ||
          model.status == "VerificationInProgress"
        ) {
          setProgressState(2);
          setValidating(true);
        } else if (model.status == "TestingInProgress") {
          // console.log("line 70");
          setProgressState(3);
          setTestingInProgress(true);
        } else if (
          model.status == "TestingFailed" ||
          model.status == "TestingSuccess"
        ) {
          // console.log("line 74");
          setProgressState(3);
          setTestingInProgress(false);
        } else if (model.status == "HWTestingInProgress") {
          setProgressState(4);
          setHardwareTestingInProgress(true);
        } else if (
          model.status == "HWTestingFailed" ||
          model.status == "HWTestingSuccess"
        ) {
          setProgressState(4);
          setHardwareTestingInProgress(false);
        }
        // }
      }
    } else {
      verificationStatus[0] = "Unsubmitted";
      setProgressState(0);
    }
  }, [model, startHardwareTesting]);

  // It is used to convert the UTC time to local time
  function checkingTime(model, status) {
    let time;
    for (let i = 0; i < model.status_change_log.length; i++) {
      if (status == model.status_change_log[i].status_name) {
        time = model.status_change_log[i].change_time;
      }
      if (
        model.status_change_log[i].status_name == "ValidationFailed" &&
        status == "ValidationSuccessful"
      ) {
        time = model.status_change_log[i].change_time;
      }
    }
    return time;
  }

  // It is used to check the date of the status
  function checkDate(model, status) {
    let date;
    for (let i = 0; i < model.status_change_log.length; i++) {
      if (status == model.status_change_log[i].status_name) {
        date = model.status_change_log[i].change_time.split(" ")[0];
      }
      if (
        model.status_change_log[i].status_name == "ValidationFailed" &&
        status == "ValidationSuccessful"
      ) {
        date = model.status_change_log[i].change_time.split(" ")[0];
      }
    }
    if (date) {
      return date;
    }
  }

  function getCurrentTime() {
    const now = new Date();

    let hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    const ampm = hours >= 12 ? "pm" : "am";

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    }

    // Ensure hours and minutes are always displayed as two digits
    hours = String(hours).padStart(2, "0");
    const formattedTime = `${hours}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")} ${ampm}`;

    return formattedTime;
  }

  console.log(hwTesting)
  console.log(progressState)
  console.log(model.status)
  console.log(verificationStatus)
  console.log(userMissionAccess)

  return (
    <div>
      <div className='progressbar-div'>
        <div className='progress-area'>
          {verificationStatus.map((status, id) => {
            return (
              <div className='progress-text-area'>
                {progressState >= id && (
                  <div className='timestamp-box'>
                    <div>
                      {id == 0 && createUtcToLocalDateOnly(model.CreatedAt)}
                      {model &&
                        id != 0 &&
                        id <= model.status_change_log.length &&
                        checkDate(model, status)}
                    </div>
                    <div>
                      {id == 0 && convertUtcToLocal(model.CreatedAt)}
                      {model &&
                        id != 0 &&
                        id <= model.status_change_log.length &&
                        convertUtcToLocal(checkingTime(model, status))}
                    </div>
                  </div>
                )}

                <div
                  id={`${
                    progressState == 0 &&
                    model.status == "Unsubmitted" &&
                    id == 1 &&
                    "go-up"
                  }`}
                  className='text-show'>
                  <div
                    className={`${
                      id == 0 && model.status == "Unsubmitted"
                        ? "failed-circle"
                        : id == 2 && model.status == "ValidationFailed"
                        ? "failed-circle"
                        : id == 2 && validating == true
                        ? "testing-progress"
                        : id == 3 && testingInProgress == true
                        ? "testing-progress"
                        : id == 3 && model.status == "TestingFailed"
                        ? "failed-circle"
                        : id == 4 && model.status == "HWTestingFailed"
                        ? "failed-circle"
                        : id == 4 && model.status == "HWTestingInProgress"
                        ? "testing-progress"
                        : id <= progressState
                        ? "passed"
                        : id > progressState && "genric"
                    }`}></div>
                  {
                    id == 0 && model.status == "Unsubmitted"
                      ? "Unsubmitted"
                      : id == 0 && model.status != "Unsubmitted"
                      ? "Submitted"
                      : id == 1
                      ? "In Progress"
                      : id == 2 && validating == true
                      ? "Validating"
                      : id == 2 && model.status == "ValidationFailed"
                      ? "Validation Failed"
                      : id == 2 && model.status == "ValidationSuccessful"
                      ? "Validation Successful"
                      : id == 2
                      ? "Validation Successful"
                      : id == 3 &&
                        testingInProgress == false &&
                        model.status == "TestingSuccess"
                      ? "Testing Successful"
                      : id == 3 &&
                        testingInProgress == false &&
                        model.status == "TestingFailed"
                      ? "Testing Failed"
                      : id == 3 && model.status == "TestingInProgress"
                      ? "Testing In Progress"
                      : id == 3
                      ? "Testing"
                      : id == 4 && model.status == "HWTestingSuccess"
                      ? "H/W Testing Successful"
                      : id == 4 && model.status == "HWTestingInProgress"
                      ? "Hardware Testing"
                      : id == 4 && model.status == "HWTestingFailed"
                      ? "H/W Testing Failed"
                      : id == 4 && "H/W testing not allowed" 
                  }
                </div>
              </div>
            );
          })}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: "-10px",
            zIndex: 2,
            borderRadius: "70px",
          }}>
          <div
            style={{ borderRadius: "70px" }}
            className={`${
              progressState == 0 && model.status != "Unsubmitted"
                ? "first"
                : progressState == 0 && model.status == "Unsubmitted"
                ? "first-unsubmit"
                : progressState == 1
                ? "second"
                : progressState == 2 && model.status == "ValidationFailed"
                ? "third-failed"
                : progressState == 2 && model.status == "ValidationSuccessful"
                ? "third-passed"
                : progressState == 2 && validating == true
                ? "third-in-progress"
                : progressState == 3 &&
                  testingInProgress == false &&
                  model.status == "TestingSuccess" && hwTesting == false
                ? "fourth without-hw"
                : progressState == 3 &&
                  testingInProgress == false &&
                  model.status == "TestingFailed" && hwTesting == false
                ? "fourth-failed without-hw"
                : progressState == 3 && testingInProgress == true && hwTesting == false
                ? "fourth-in-progress without-hw"
                : progressState == 3 &&
                  testingInProgress == false &&
                  model.status == "TestingSuccess" && hwTesting == false
                ? "fourth without-hw"
                : progressState == 3 &&
                  testingInProgress == false &&
                  model.status == "TestingFailed" && hwTesting == false
                ? "fourth-failed without-hw"
                : progressState == 3 && testingInProgress == true && hwTesting == false
                ? "fourth-in-progress without-hw"
                : progressState == 3 &&
                  testingInProgress == false &&
                  model.status == "TestingSuccess"
                ? "fourth"
                : progressState == 3 &&
                  testingInProgress == false &&
                  model.status == "TestingFailed"
                ? "fourth-failed"
                : progressState == 3 && testingInProgress == true
                ? "fourth-in-progress"
                : progressState == 4 &&
                  model.status == "HWTestingSuccess" &&
                  hardwareTestingInProgress == false
                ? "fifth"
                : progressState == 4 &&
                  model.status == "HWTestingFailed" &&
                  hardwareTestingInProgress == false
                ? "fifth-failed"
                : progressState == 4 && hardwareTestingInProgress == true
                ? "fifth-in-progress"
                : progressState == 8 && "ninth"
            }`}></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressMonitor;
