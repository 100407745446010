import { EARLY_ACCESS } from "../../Actions/EarlyAccessPopup/EarlyAccess"

const init = {
    early:false
}

export const earlyAccessReducer = (state = init, { type, payload }) => {
    switch (type) {
        case EARLY_ACCESS:
            return {...state,early:payload};
        default: return state
    }
}