import React, { useEffect, useState } from "react";
import Config_Parameter from "./ConfigParameter/ConfigParameter";
import InputBands from "./InputBands/InputBands";
import LevelOfProcessing from "./LevelOfProcessing/LevelOfProcessing";
import Masking from "./Masking/Masking";
import "./Section3.css";
import { BsPlusLg } from "react-icons/bs";
function Section3({
  sec3Response,
  giveInput,
  submitModelDetails,
  setNextBtnDisabled,
  proMode,
  setSubmitModelDetails,
  resubmitModel,
  draftModel,
}) {
  const [openInfo, setOpenInfo] = useState();
  const [openInfoAdditional, setOpenInfoAdditional] = useState();
  const [sec3Details, setSec3Details] = useState({
    model_config_structure: [],
    input_bands: [],
    cloud_masks: [],
    level_of_processing: "L1B",
  });

  //---------------------------------------------This part can be optimized as sec1 and sec2----------------------------------------//
  if (giveInput && proMode == false) {
    sec3Response(sec3Details);
  }
  // if (proMode == true) {
  //   getAllDetailsAtOnce(sec3Details);
  // }
  useEffect(() => {
    if (
      sec3Details.input_bands.length > 0 &&
      sec3Details.level_of_processing != "" &&
      proMode == false
    ) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }
  }, [sec3Details]);
  useEffect(() => {
    if (sec3Details.cloud_masks.length > 0 && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        cloud_masks: sec3Details.cloud_masks,
      });
    }
  }, [sec3Details.cloud_masks]);
  useEffect(() => {
    if (sec3Details.input_bands.length > 0 && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        input_bands: sec3Details.input_bands,
      });
    }
  }, [sec3Details.input_bands]);
  useEffect(() => {
    if (sec3Details.model_config_structure.length > 0 && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_config_structure: sec3Details.model_config_structure,
      });
    }
  }, [sec3Details.model_config_structure]);
  useEffect(() => {
    if (sec3Details.level_of_processing != "" && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        level_of_processing: sec3Details.level_of_processing,
      });
    }
  }, [sec3Details.level_of_processing]);

  //---------------------------------------------This part can be optimized as sec1 and sec2----------------------------------------//
  // It is used to store the values coming from every field even after section changes to show that value in the input fields
  useEffect(() => {
    setSec3Details({
      model_config_structure: submitModelDetails.model_config_structure,
      input_bands: submitModelDetails.input_bands,
      cloud_masks: submitModelDetails.cloud_masks,
      level_of_processing: submitModelDetails.level_of_processing,
    });
  }, []);
  // If there is resubmit flow then just store all the values in the section state to show them in the respective field
  useEffect(() => {
    if (resubmitModel) {
      setSec3Details({
        model_config_structure: resubmitModel.model_config_structure,
        input_bands: resubmitModel.input_bands,
        cloud_masks: resubmitModel.cloud_masks,
        level_of_processing: resubmitModel.level_of_processing,
      });
    } else if (draftModel) {
      setSec3Details({
        model_config_structure: draftModel.model_config_structure,
        input_bands: draftModel.input_bands,
        cloud_masks: draftModel.cloud_masks,
        level_of_processing: draftModel.level_of_processing,
      });
    }
    // else if (!draftModel || !resubmitModel) {
    //   setSec3Details({
    //     model_config_structure: [],
    //     input_bands: [],
    //     cloud_masks: [],
    //     level_of_processing: "",
    //   });
    // }
  }, [resubmitModel, draftModel]);
  useEffect(() => {
    setOpenInfoAdditional();
  }, [openInfo]);
  return (
    <div className='sec3-div'>
      <div id={`${proMode == false && "fix-height"}`} className='sec3-div-left'>
        <div
          onClick={() => {
            setOpenInfo(1);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro"
              : "section-single-box"
          }`}>
          <Config_Parameter
            setSec3Details={setSec3Details}
            sec3Details={sec3Details}
            setOpenInfoAdditional={setOpenInfoAdditional}
            proMode={proMode}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(2);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro"
              : "section-single-box"
          }`}>
          <InputBands
            setSec3Details={setSec3Details}
            sec3Details={sec3Details}
            proMode={proMode}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(3);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro"
              : "section-single-box"
          }`}>
          <LevelOfProcessing
            setSec3Details={setSec3Details}
            sec3Details={sec3Details}
            proMode={proMode}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(4);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro"
              : "section-single-box"
          }`}>
          <Masking
            setSec3Details={setSec3Details}
            sec3Details={sec3Details}
            proMode={proMode}
          />
        </div>
      </div>
      {proMode == false && (
        <div className='sec3-div-right'>
          <div className='sec-info sec1-1'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Config ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 1) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(1);
                    }
                  }}
                  className={`${
                    openInfo == 1 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 1 ? "sec-info-desc extra" : "sec-info-desc-close"
                }`}>
                {" "}
                If the model’s inference can be configured by a user (through
                the User Portal) or by the developer during testing, Model
                Configs can be used to define these. Any command-line arguments
                that can be passed to the model inference script would come
                under this. These arguments allow customization of the model's
                behavior by adjusting specific parameters. Each argument can be
                described using the following fields:{" "}
                <a
                  style={{ color: "gold" }}
                  href='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-configs'
                  target='_blank'>
                  {" "}
                  Link
                </a>
                <div className='additional-data'>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>What is Model Argument Name ?</div>
                      <div
                        onClick={() => {
                          if (openInfoAdditional == 1.1 && openInfo == 1) {
                            setOpenInfoAdditional();
                          } else {
                            setOpenInfoAdditional(1.1);
                          }
                        }}
                        className={`${
                          openInfoAdditional == 1.1 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        openInfoAdditional == 1.1 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      {" "}
                      The name of the configurable parameter.
                    </div>
                  </div>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>What is Data Type ?</div>
                      <div
                        onClick={() => {
                          if (openInfoAdditional == 1.2 && openInfo == 1) {
                            setOpenInfoAdditional();
                          } else {
                            setOpenInfoAdditional(1.2);
                          }
                        }}
                        className={`${
                          openInfoAdditional == 1.2 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        openInfoAdditional == 1.2 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      {" "}
                      The data type of the argument (int or float).
                    </div>
                  </div>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>What is Min Value ?</div>
                      <div
                        onClick={() => {
                          if (openInfoAdditional == 1.3 && openInfo == 1) {
                            setOpenInfoAdditional();
                          } else {
                            setOpenInfoAdditional(1.3);
                          }
                        }}
                        className={`${
                          openInfoAdditional == 1.3 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        openInfoAdditional == 1.3 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      {" "}
                      The minimum allowed value for the argument.
                    </div>
                  </div>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>What is Max Value ?</div>
                      <div
                        onClick={() => {
                          if (openInfoAdditional == 1.4 && openInfo == 1) {
                            setOpenInfoAdditional();
                          } else {
                            setOpenInfoAdditional(1.4);
                          }
                        }}
                        className={`${
                          openInfoAdditional == 1.4 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        openInfoAdditional == 1.4 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      {" "}
                      The maximum allowed value for the argument.
                    </div>
                  </div>
                  <div>
                    <div className='sec-info-head'>
                      <div>What is Default Value ?</div>
                      <div
                        onClick={() => {
                          if (openInfoAdditional == 1.5 && openInfo == 1) {
                            setOpenInfoAdditional();
                          } else {
                            setOpenInfoAdditional(1.5);
                          }
                        }}
                        className={`${
                          openInfoAdditional == 1.5 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        openInfoAdditional == 1.5 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      {" "}
                      The default value assigned to the argument if no value is
                      provided by the user.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='sec-info sec3-2' id={`${openInfo != 1 && "sec2"}`}>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Inputs ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 2) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(2);
                    }
                  }}
                  className={`${
                    openInfo == 2 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 2 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                Specify the band order in this section for the model. Ensure
                that RGB bands are in the same order for PNG/JPEG images without
                any preprocessing steps that change the order.
              </div>
            </div>
          </div>
          <div className='sec-info sec1-3'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Input Product Type ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 3) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(3);
                    }
                  }}
                  className={`${
                    openInfo == 3 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 3 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                Specify the desired level of processing applicable to your use
                case and the type of input required by the model to work.The
                system currently offers 3 products. Read more about it in the
                <a
                  style={{
                    color: "gold",
                    textDecoration: "underline",
                  }}
                  target='_blank'
                  href='https://skyserve.gitbook.io/skyserve-docs/model-submission#input-product-type'>
                  {" "}
                  Link
                </a>
              </div>
            </div>
          </div>
          <div className='sec-info sec1-4'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Data Mask ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 4) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(4);
                    }
                  }}
                  className={`${
                    openInfo == 4 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 4 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                Data masks can be requested as input along with the image to be
                used either for pre-processing the image before inferencing or
                to post-process the result after inferencing. Read more about it
                in the{" "}
                <a
                  style={{
                    color: "gold",
                    textDecoration: "underline",
                  }}
                  target='_blank'
                  href='https://skyserve.gitbook.io/skyserve-docs/model-submission#data-mask'>
                  {" "}
                  Link
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section3;
