import React, { useState } from "react";
import "./PreviewSelectionPage.css";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { BsDownload } from "react-icons/bs";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import ImageSubmittedSuccessPage from "./ImageSubmittedSuccessPage/ImageSubmittedSuccessPage";
import { getModel } from "../../Redux/Actions/Models/ModelAction";
import { getLatestModel } from "../../Redux/Actions/LatestSubmission/LatestSubmissionAction";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function PreviewSelectionPage({
  closePop,
  selectedImagesForPreview,
  selectedImagesForTesting,
  setOpenImageSelectionPage,
  modelId,
  Session_Token,
  setStartTesting,
  testingType,
  setStartDummyTesting,
  removingSelectedImages,
  setShowHardwareResult,
}) {
  // If there are no images selected for testing then it will close the pop up
  if (selectedImagesForTesting.length == 0 || !selectedImagesForTesting) {
    closePop();
  }
  const [openSubmissionPop, setOpenSubmissionPop] = useState(false);
  const dispatch = useDispatch();

  // It will call the API to start testing
  function callingStartTesting() {
    let url;
    if (testingType == "first-testing") {
      url = process.env.REACT_APP_BASE_URL + "/v1/model/SubmitModelForTesting";
    } else {
      url = process.env.REACT_APP_BASE_URL + "/v1/model/startHardwareTesting";
      setStartDummyTesting(true);
    }
    // dispatch(spinnerAction(true));

    // setOpenImageSelectionPage(false);
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model_id: modelId,
      test_images: selectedImagesForTesting,
      // image_preview_link:
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // setOpenImageSelectionPage(false);
        if (testingType != "first-testing") {
          setShowHardwareResult(result);
        }
        // if (testingType == "first-testing") {
        //   setStartTesting(true);
        // } else {
        //   setStartDummyTesting(true);
        //   localStorage.setItem("hardwareTestingResult", "waiting");
        // }
        getModelById(modelId);
        fetchLatestModel();
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }

  // It will call the API to get the model details by model id
  function getModelById(modelId) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/getModelDetailsById/${modelId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(getModel({ model_details: result }));
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }

  // It will call the API to get the latest model details
  function fetchLatestModel() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/getLatestModelByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(getLatestModel({ model_details: result }));
        // console.log(result);
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }
  return (
    <div className='prev-page'>
      <div className='download-img-head'>Download Images</div>

      <div className='preview-underline'></div>
      <div className='image-preview-box'>
        {selectedImagesForPreview &&
          selectedImagesForPreview.map((el, id) => (
            <div className='prev-content'>
              <div className='image-prev-div'>
                <img src={el.image_s3_link} />
              </div>
              <div className='preview-img-id'>{el.image_id}</div>
              <div>
                <a
                  download={true}
                  href={selectedImagesForTesting[id].image_s3_link}>
                  <div className='image-prev-link'>
                    Image
                    <BsDownload />
                  </div>
                </a>
              </div>
              {selectedImagesForTesting[id].cloud_mask_url != "null" && (
                <div className='cloudmask-download-btn'>
                  <a
                    download={true}
                    href={selectedImagesForTesting[id].cloud_mask_url}>
                    <div className='image-prev-link'>
                      Cloud Mask
                      <BsDownload />
                    </div>
                  </a>
                </div>
              )}
              {selectedImagesForTesting[id].cloud_shadow_mask_url != "null" && (
                <div className='cloudshadowmask-download-btn'>
                  <a
                    download={true}
                    href={selectedImagesForTesting[id].cloud_shadow_mask_url}>
                    <div className='image-prev-link'>
                      Cloud Shadow Mask
                      <BsDownload />
                    </div>
                  </a>
                </div>
              )}
              {selectedImagesForTesting[id].no_data_mask_url != "null" && (
                <div className='nodatamask-download-btn'>
                  <a
                    download={true}
                    href={selectedImagesForTesting[id].no_data_mask_url}>
                    <div className='image-prev-link'>
                      NODATA Mask
                      <BsDownload />
                    </div>
                  </a>
                </div>
              )}

              <div className='image-delete-div'>
                <button
                  onClick={() => {
                    removingSelectedImages(id);
                  }}>
                  <AiOutlineClose />
                </button>
              </div>
            </div>
          ))}
      </div>
      <div className='image-prev-pop-div-btn'>
        <button
          onClick={() => {
            setShowHardwareResult(undefined);
            closePop();
          }}>
          Cancel
        </button>
        <button
          onClick={() => {
            callingStartTesting();
            setOpenSubmissionPop(true);
          }}>
          Start Testing
        </button>
      </div>
      {openSubmissionPop == true && (
        <div className='preview-submission-overlay'></div>
      )}
      {openSubmissionPop == true && (
        <div className='preview-submission-content'>
          <ImageSubmittedSuccessPage
            closePop={closePop}
            setOpenImageSelectionPage={setOpenImageSelectionPage}
          />
        </div>
      )}
    </div>
  );
}

export default PreviewSelectionPage;
