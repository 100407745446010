import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getMessaging, onMessage } from 'firebase/messaging';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCRcBTpd_tcgs8Qj_rgxDfpC8eq0n1WC_A",
    authDomain: "dev-portal-7b586.firebaseapp.com",
    projectId: "dev-portal-7b586",
    storageBucket: "dev-portal-7b586.appspot.com",
    messagingSenderId: "731313286016",
    appId: "1:731313286016:web:971946d23190a6b6916f65",
    measurementId: "G-WRM7H5NXYS"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

let messaging;

// Register the Service Worker - make sure to register worker just once and then Start and Stop accordingly if already exists
if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      console.log("Inside Service Worker Registration")
      window.addEventListener('load', () => {
        console.log("Registering the Service Worker")
        navigator.serviceWorker.register('./firebase-messaging-sw.js').then((registration) => {
          console.log('Service Worker registered with scope: ', registration.scope);
          });
        }, (err) => {
          console.log('Service Worker registration failed: ', err);
        });
    };

// Handle any new messages that arrive as Push Notifications
messaging = getMessaging(app);
console.log(messaging);
// dispatch(getFcmMessage(messaging));


export { messaging };