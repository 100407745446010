export function convertUtcToLocal(dateInUtc) {
    if (dateInUtc) {
        if (dateInUtc?.includes("T")) {
            const utcTimeString = dateInUtc; // Replace with your UTC time string

    // Create a Date object from the UTC time string
            const utcDate = new Date(utcTimeString);

            // Get the local time string in the user's timezone
            const localTime = utcDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
            return localTime
        }
        else {
            const inputDateString = dateInUtc;

            // Split the input string into date and time parts
            const [datePart, timePart] = inputDateString.split(' ');

            // Split the date part into month, day, and year
            const [month, day, year] = datePart.split('-');

            // Split the time part into hours, minutes, and seconds
            const [hours, minutes, seconds] = timePart.split(':');

            // Create a new Date object in the local time zone using the extracted values
            const localDate = new Date(year, month - 1, day, hours, minutes, seconds);
            // Convert the local date to UTC
            const utcDate = new Date(localDate.toISOString());

            // Get the local time zone offset in minutes
            const timeZoneOffset = localDate.getTimezoneOffset();
            // Calculate the local time by subtracting the offset
            const localTime = new Date(utcDate.getTime() - timeZoneOffset * 60000);

            // Use toLocaleTimeString to format the local time as a string
                const formattedLocalTime = localTime.toLocaleTimeString().toString()
                return formattedLocalTime;
        }
    }
    

}