import React from "react";
import "./ModelDetailsPop.css";
function ModelDetailsPop({ details }) {
  return (
    <div className='output-details'>
      <div className='output-details-content'>
        {details && details.output_type.includes("obj_detection") && (
          <div className='content-part'>
            <div style={{ paddingLeft: "0%" }}>
              <div>
                <div>Output Suffix : </div>
                <div className='content-text'>{details.name}</div>
              </div>
              <div>
                <div>Output Type : </div>
                <div className='content-text'>Object Detection</div>
              </div>
              <div>
                <div>Geometry Type : </div>
                <div className='content-text'>
                  {details.dtype == "bbox" ? "Boundingbox" : "Point"}
                </div>
              </div>
            </div>
          </div>
        )}
        {details && details.output_type.includes("obj_classification") && (
          <div className='content-part'>
            <div>
              <div>
                <div>Output Suffix : </div>
                <div className='content-text'>{details.name}</div>
              </div>
              <div>
                <div>Output Type : </div>
                <div className='content-text'>Object Classification</div>
              </div>
              <div>
                <div>Object Detection Geometry Type : </div>
                <div className='content-text'>
                  {details.dtype == "bbox" ? "Boundingbox" : "Point"}
                </div>
              </div>
            </div>
            <div>
              <div>Number Of Classess : </div>
              <div>{details.max_classes}</div>
              <div className='classes-grid'>
                {details.classes.map((el, id) => (
                  <div>
                    <div>{el.value}</div>
                    <div>
                      {el.name.length > 30
                        ? el.name.substring(0, el.name.length) + "..."
                        : el.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {details && details.output_type.includes("image") && (
          <div className='content-part-img'>
            <div style={{ paddingLeft: "0%" }}>
              <div>
                <div>Output Suffix : </div>
                <div className='content-text'>{details.name}</div>
              </div>
              <div>
                <div>Output Type : </div>
                <div className='content-text'>Image</div>
              </div>
              <div>
                <div>Pixel Value Data Type : </div>
                <div className='content-text'>
                  {details.dtype == "integer" ? "Integer" : "Float"}
                </div>
              </div>
            </div>
            <div className='image-value'>
              <div>
                <div>Min : </div>
                <div>{details.min_value}</div>
              </div>
              <div>
                <div>Max : </div>
                <div>{details.max_value}</div>
              </div>
              <div>
                <div>Min Threshold : </div>
                <div>{details.min_threshold_value}</div>
              </div>
              <div>
                <div>Max Threshold : </div>
                <div>{details.max_threshold_value}</div>
              </div>
              <div>
                <div>Precision : </div>
                <div>{details.precision}</div>
              </div>
            </div>
          </div>
        )}
        {details && details.output_type.includes("segmentation") && (
          <div className='content-part'>
            <div>
              <div>
                <div>Output Suffix : </div>
                <div className='content-text'>{details.name}</div>
              </div>
              <div>
                <div>Output Type : </div>
                <div className='content-text'>Segmentation</div>
              </div>
              <div>
                <div>Segmentation Type : </div>
                <div className='content-text'>
                  {details.dtype == "multiclass" ? "Multiclass" : "Binary Mask"}
                </div>
              </div>
            </div>
            <div>
              <div>Number Of Classess : </div>
              <div>{details.max_classes}</div>
              <div className='classes-grid'>
                {details.classes.map((el, id) => (
                  <div>
                    <div>{el.value}</div>
                    <div>
                      {el.name.length > 30
                        ? el.name.substring(0, el.name.length) + "..."
                        : el.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ModelDetailsPop;
