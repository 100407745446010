import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { Avatar } from "@mui/material";
import { userAction } from "../../../Redux/Actions/users/UserAction";
import { useNavigate } from "react-router";
import { spinnerAction } from "../../../Redux/Actions/Spinner/spinnerAction";
import { getDeveloperAccess } from "../../../Redux/Actions/DeveloperAccess/DeveloperAccess";
import { getEmailVerification } from "../../../Redux/Actions/EmailVerification/EmailVerification";
import { getopenSessionToken } from "../../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";

export default function BasicMenu() {
  const navigate = useNavigate();
  const user = useSelector((store) => store.user.user);
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const session_token = localStorage.getItem("session-token-skyserve");
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function logoutFn() {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      session_token: session_token,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "/v1/auth/logout", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        handleClose();
        dispatch(userAction());
        navigate("/");
        localStorage.removeItem("session-token-skyserve");
        localStorage.removeItem("user-details");
        sessionStorage.removeItem("accessable");
        sessionStorage.removeItem("opened-model");
        localStorage.removeItem("missionAccess");
        dispatch(spinnerAction(false));
        window.location.reload();
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
  }
  if (!userDetails && user == "") {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "/v1/auth/whoami", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.Message.active == true) {
          localStorage.setItem("session-token-skyserve", session_token);
          localStorage.setItem(
            "user-details",
            JSON.stringify(result.Message.identity.traits)
          );
          if (result.Message.identity.traits.email_verified == true) {
            dispatch(getEmailVerification(true));
          } else if (
            result.Message.identity.traits.email_verified == undefined
          ) {
            dispatch(getEmailVerification(false));
          }
          if (result.Message.identity.traits.developer_access) {
            dispatch(
              getDeveloperAccess(
                result.Message.identity.traits.developer_access
              )
            );
            localStorage.setItem(
              "developer-access",
              result.Message.identity.traits.developer_access
            );
          } else {
            dispatch(getDeveloperAccess(false));
            localStorage.setItem("developer-access", false);
          }
        } else {
          // logoutFn();
        }
      })
      .catch((error) => {
        // dispatch(userAction());
        // localStorage.removeItem("session-token-skyserve");
        // localStorage.removeItem("user-details");
        // navigate("/");
      });
  }
  return (
    <div>
      <Button
        id='basic-button'
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup='true'
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}>
        <Avatar
          style={{
            backgroundColor: "rgb(14,10,63)",
            fontSize: "1.3vw",
          }}>
          {(userDetails && userDetails.full_name[0]) ||
            (user && user.full_name[0])}
        </Avatar>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/profile");
          }}>
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            navigate("/developer/overview");
          }}>
          Dashboard
        </MenuItem>
        <MenuItem
          onClick={() => {
            logoutFn();
          }}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
