import React, { useEffect, useMemo, useRef, useState } from "react";
import { BsFillChatFill } from "react-icons/bs";
import { BsFillChatDotsFill } from "react-icons/bs";
import { IoSend } from "react-icons/io5";
import ScrollableFeed from "react-scrollable-feed";
import "./ChatPage.css";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../Images/Owl_Logo-min.png";
import { openingChat } from "../../Redux/Actions/OpenChatFromNotification/OpenChatFromNotification";
import { getNotification } from "../../Redux/Actions/Notifications/NotificationsAction";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function ChatPage({ modelId, modelStatus, modelQueries }) {
  const [openChat, setOpenChat] = useState(false);
  const openingChatFromNotification = useSelector(
    (store) => store.openChat.openChat
  );
  const [queries, setQuires] = useState();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const messages = [];
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const divRef = useRef(null);

  // It is used to close the dropdown when click outside of the chat box
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        divRef.current &&
        !divRef.current.contains(event.target) &&
        openChat == true
      ) {
        setOpenChat(false); //You have to add the stateName which is closing or opening the collapsable div
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openChat]);

  // Send chat to the Admin
  const handleSend = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model_id: modelId,
      model_status: modelStatus,
      msg: msg,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/submitUserQuery",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        updateChat();
        setMsg("");
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  };
  // useEffect(() => {
  //   if (openChat) {
  //     readAllNotifications();
  //     readAllNotifications();
  //   }
  // }, [openChat, Session_Token, modelId]);
  // if (openChat) {
  //   readAllNotifications();
  // }

  useEffect(() => {
    // It is used to open the chat box when user click on the notification of the chat
    if (openingChatFromNotification == true) {
      setOpenChat(true);
    }
    if (openChat == true || openingChatFromNotification == true) {
      readAllNotifications();
      dispatch(openingChat(false));
    }
  }, [openChat, openingChatFromNotification]);

  // When chat opens it will mark all the notifications as read
  function readAllNotifications() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model_id: modelId,
      model_status: modelStatus,
      read_all_msg: true,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      mode: "cors",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/submitUserQuery",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        updateChat();
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  useEffect(() => {
    setQuires(modelQueries);
  }, [modelQueries]);

  // After sending chat the new chat should show on the chat page
  // That is why we are fetching the chat again from this API and store that
  function updateChat() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/getModelsByUserId/all",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        for (let i = 0; i < result.length; i++) {
          if (result[i].model_id == modelId) {
            setQuires(result[i].model_queries);
            setMsg("");
            // readAllNotifications();
            fetchAllNotification();
          }
        }
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }

  // Fetching all the notification if there is any
  function fetchAllNotification() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/getAllNotificationsForUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result == null) {
          // setAllNotifications();
          dispatch(getNotification());
        }
        if (result.Status == 401) {
          // setAllNotifications();
          dispatch(getNotification());
        } else {
          // setAllNotifications(result);
          dispatch(getNotification(result));
        }
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  return (
    <div ref={divRef} className='chatpage'>
      <div
        onClick={() => {
          setOpenChat(!openChat);
          if (openingChatFromNotification == true) {
            dispatch(openingChat(false));
          }
        }}
        className='chat-icon'>
        <BsFillChatFill />
        <BsFillChatDotsFill className='reverse-chat' />
      </div>

      <div
        className={`${
          openChat == true || openingChatFromNotification == true
            ? "chat-body-open"
            : "chat-body-close"
        }`}>
        <div className='chat-logo'>
          <div>
            <img src={logo} alt='Logo' />
          </div>
          <span>SkyServe</span>
        </div>
        <div className='chat-body-box-content'>
          <div style={{ height: "100%", overflow: "hidden" }}>
            <ScrollableFeed>
              <div className='scrollable'>
                {queries &&
                  queries.map((el, id) => {
                    return (
                      <div>
                        {el.status == modelStatus &&
                          el.queries.map((query, id) => {
                            return (
                              <div
                                className={`${
                                  query.sender == "user" ? "user" : "admin"
                                }`}>
                                <div>{query.msg}</div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
              {/* {!queries && (
              <h3
                style={{
                  textAlign: "center",
                  color: "black",
                  marginTop: "9%",
                }}>
                Get In Touch With Us !
              </h3>
            )} */}
            </ScrollableFeed>
          </div>
        </div>

        <div className='chatting-box-div'>
          <form
            onSubmit={(e) => {
              handleSend(e);
            }}>
            <input
              onChange={(e) => setMsg(e.target.value)}
              className='input-box-chat'
              type='text'
              placeholder='Send Message'
              value={msg}
            />
            <button
              onClick={(e) => {
                handleSend(e);
              }}>
              <IoSend />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
