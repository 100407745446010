import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getModel } from "../Redux/Actions/Models/ModelAction";
import ConfirmationPage from "../Components/ConfirmationPage/ConfirmationPage";
import {
  BsFillCaretRightFill,
  BsFillCaretUpFill,
  BsFillCaretDownFill,
} from "react-icons/bs";
import "./CSS/monitor.css";
import { getResubmitModel } from "../Redux/Actions/ResubmitModel/ResubmitModel";
import { getInactiveVersion } from "../Redux/Actions/InactiveVersion/InactiveVersion";
import { getDraftSubmit } from "../Redux/Actions/DraftSubmitModel/DraftSubmit";
import { getAllModels } from "../Redux/Actions/AllModels/AllModelsAction";
import monitorImg from "../Images/tracker.png";
import { Link, useNavigate } from "react-router-dom";
import DevTracker from "./DevHomeTracker";
import TrackerDemoPage from "../DeveloperPortalComponents/StaticDemoPages/TrackerDemoPage/TrackerDemoPage";
import ModelDemoPage from "../DeveloperPortalComponents/StaticDemoPages/ModelDemoPage/ModelDemoPage";
import { getopenSessionToken } from "../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
const Modal = ({
  setOpenModal,
  modelId,
  modelState,
  modelVisibility,
  toBeChanged,
}) => {
  return (
    <div>
      <ConfirmationPage
        setOpenModal={setOpenModal}
        modelId={modelId}
        modelState={modelState}
        modelVisibility={modelVisibility}
        toBeChanged={toBeChanged}
      />
    </div>
  );
};
const DevHomeMonitor = ({ openJoy, state, fcmNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const openedModelStoredInSession = JSON.parse(
    sessionStorage.getItem("opened-model")
  );
  const devAccess = useSelector((store) => store.access.access);
  const [modelId, setModelId] = useState();
  const [modelState, setModelState] = useState();
  const [modelVisibility, setModelVisibility] = useState();
  const [toBeChanged, setToBeChanged] = useState("");
  const allModels = useSelector((store) => store.allModels.allModels);
  const modelDetails = useSelector((store) => store.model.model);
  const [openModal, setOpenModal] = useState(false);
  const [openingModelIdx, setOpeningModelIdx] = useState();
  const [activatedVersion, setActivatedVersion] = useState();
  const [inActiveVersion, setInactiveVersion] = useState();
  const [openDetailsPage, setOpenDetailsPage] = useState();
  const gotSse = useSelector((store) => store.fcm.notificationPayload);
  const [filterLists, setFilterList] = useState({
    status: "",
    activation: "",
    control: "",
  });
  if (userDetails.email_verified == undefined) {
    navigate(-1);
  }
  useEffect(() => {
    setOpenDetailsPage(modelDetails);
  }, [modelDetails]);
  useEffect(() => {
    if (fcmNotification) {
      var myHeaders = new Headers();
      myHeaders.append("Session-Token", Session_Token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL +
          "/v1/model/listModelsWithVersionByUserId",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result == null) {
            dispatch(getAllModels("No data"));
          } else {
            dispatch(getAllModels(result.reverse()));
          }
        })
        .catch((error) => {
          dispatch(
            getopenSessionToken({ session: true, message: "Session Expired" })
          );
          console.log("error", error);
        });
      if (modelDetails) {
        setTimeout(() => {
          getModelDetails(modelDetails.model_details.model_id);
        }, 1000);
      }
    }
  }, [fcmNotification]);
  useEffect(() => {
    if (allModels && allModels != "No data") {
      let activatedVer = [];
      let inactivated = [];
      for (let i = 0; i < allModels.length; i++) {
        let activated = allModels[i].model_version_obj;
        let inactiveVersion = [];
        for (let j = activated.length - 1; j >= 0; j--) {
          if (activated[j].active == 1) {
            activatedVer.push(activated[j]);
            // setActivatedVersion([...activatedVersion, activated[j]]);
          } else {
            inactiveVersion.push(activated[j]);
          }
        }
        inactivated.push(inactiveVersion);
        // setInactiveVersion([...inActiveVersion, inactiveVersion]);
      }
      setActivatedVersion(activatedVer);
      setInactiveVersion(inactivated);
    }
  }, [allModels?.length, allModels]);
  // useEffect(() => {
  //   setOpenDetailsPage();
  // }, []);
  // useEffect(() => {
  //   if (Session_Token && devAccess == true) {
  //     setTimeout(() => {

  //     }, 110);
  //   }
  // }, [Session_Token, devAccess]);

  function getModelDetails(model_id) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/getModelDetailsById/${model_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(getModel({ model_details: result }));
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
  }
  useEffect(() => {
    if (openJoy == true && state.stepIndex == 5) {
      dispatch(getModel());
    }
  }, [openJoy, state.stepIndex]);
  useEffect(() => {
    //For now not taking developer access
    // if (devAccess) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        "/v1/model/listModelsWithVersionByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result == null) {
          dispatch(getAllModels("No data"));
        } else {
          dispatch(getAllModels(result.reverse()));
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
    if (modelDetails || openedModelStoredInSession) {
      setTimeout(() => {
        if (openedModelStoredInSession) {
          getModelDetails(openedModelStoredInSession.model_details.model_id);
        } else {
          getModelDetails(modelDetails.model_details.model_id);
        }
      }, 1000);
    }
    // }
  }, []);

  useEffect(() => {
    if (openedModelStoredInSession) {
      dispatch(getModel(openedModelStoredInSession));
    }
  }, []);
  console.log(process.env.REACT_APP_BASE_URL);
  return (
    <div>
      {allModels != "No data" && allModels != null ? (
        <div>
          {!modelDetails ? (
            <div style={{ width: "95%", margin: "auto" }}>
              <div className='monitor-model-text'>
                Monitor Models
                <div className='submit-model-underline'></div>
              </div>
              <div className='monitor-table'>
                {openModal && <div className='modal-overlay'></div>}
                {openModal && (
                  <div className='new-modal'>
                    <Modal
                      setOpenModal={setOpenModal}
                      modelId={modelId}
                      modelState={modelState}
                      modelVisibility={modelVisibility}
                      toBeChanged={toBeChanged}
                    />
                  </div>
                )}
                <div>
                  <div className='static-head'>
                    <div>Name</div>
                    <div>Date</div>
                    <div>Status</div>
                    <div>Action</div>
                    <div>Activation Status</div>
                    <div>Visibility</div>
                    <div>Details</div>
                  </div>
                </div>
                {!allModels && <h2 style={{ marginTop: "10%" }}>Loading...</h2>}
                {allModels && allModels == "No data" ? (
                  <p style={{ fontSize: "2vw", marginTop: "3vw" }}>
                    You have not added any model to view
                  </p>
                ) : (
                  allModels &&
                  activatedVersion &&
                  inActiveVersion &&
                  allModels
                    .filter((el) => {
                      if (
                        filterLists.activation != "" &&
                        filterLists.control != "" &&
                        filterLists.status != ""
                      ) {
                        return (
                          el.model_state == filterLists.activation &&
                          el.visibility == filterLists.control &&
                          el.status == filterLists.status
                        );
                      } else if (
                        filterLists.activation != "" &&
                        filterLists.control != ""
                      ) {
                        return (
                          el.model_state == filterLists.activation &&
                          el.visibility == filterLists.control
                        );
                      } else if (
                        filterLists.control != "" &&
                        filterLists.status != ""
                      ) {
                        return (
                          el.visibility == filterLists.control &&
                          el.status == filterLists.status
                        );
                      } else if (
                        filterLists.activation != "" &&
                        filterLists.status != ""
                      ) {
                        return (
                          el.status == filterLists.status &&
                          el.model_state == filterLists.activation
                        );
                      } else if (filterLists.activation != "") {
                        return el.model_state == filterLists.activation;
                      } else if (filterLists.control != "") {
                        return el.model_state == filterLists.control;
                      } else if (filterLists.status != "") {
                        return el.status == filterLists.status;
                      } else {
                        return el;
                      }
                    })
                    .map((element, idx) => {
                      return (
                        <div>
                          <div className='table-content'>
                            <div
                              onClick={() => {
                                if (openingModelIdx == idx) {
                                  setOpeningModelIdx();
                                } else {
                                  setOpeningModelIdx(idx);
                                }
                              }}
                              className='model-name-box'>
                              <div className='arrow-div'>
                                {openingModelIdx != idx ? (
                                  <BsFillCaretRightFill />
                                ) : (
                                  <BsFillCaretDownFill />
                                )}
                              </div>
                              <div>
                                {" [ " + activatedVersion[idx]?.version + " ] "}
                                {element.model_name}
                              </div>
                            </div>
                            <div>
                              {activatedVersion[
                                idx
                              ]?.model_details.UpdatedAt.substring(0, 10)}
                            </div>
                            <div
                              onClick={() => {
                                dispatch(
                                  getInactiveVersion(inActiveVersion[idx])
                                );
                                // dispatch(getModel(activatedVersion[idx]));
                                setOpenDetailsPage(activatedVersion[idx]);
                              }}
                              className={`${
                                activatedVersion[idx]?.model_details.status ==
                                  "ValidationFailed" ||
                                activatedVersion[idx]?.model_details.status ==
                                  "HWTestingFailed" ||
                                activatedVersion[idx]?.model_details.status ==
                                  "TestingFailed"
                                  ? "red"
                                  : activatedVersion[idx]?.model_details
                                      .status == "VerifiedModelLocally" ||
                                    activatedVersion[idx]?.model_details
                                      .status == "ValidationSuccessful" ||
                                    activatedVersion[idx]?.model_details
                                      .status == "TestingSuccess" ||
                                    activatedVersion[idx]?.model_details
                                      .status == "HWTestingSuccess"
                                  ? "green"
                                  : activatedVersion[idx]?.model_details
                                      .status == "Unsubmitted"
                                  ? "sky"
                                  : activatedVersion[idx]?.model_details
                                      .status != "ValidationFailed" &&
                                    (activatedVersion[idx]?.model_details
                                      .status != "VerifiedModelLocally" ||
                                      activatedVersion[idx]?.model_details
                                        .status != "ValidationSuccessful") &&
                                    activatedVersion[idx]?.model_details
                                      .status != "Unsubmitted" &&
                                    "yellow"
                              }`}>
                              {activatedVersion[idx]?.model_details.status ==
                              "ValidationFailed"
                                ? "Validation Failed"
                                : activatedVersion[idx]?.model_details.status ==
                                  "ValidationSuccessful"
                                ? "Validation Successful"
                                : activatedVersion[idx]?.model_details.status ==
                                  "Unsubmitted"
                                ? "Unsubmitted"
                                : activatedVersion[idx]?.model_details.status ==
                                  "UploadModelSuccess"
                                ? "Validating"
                                : activatedVersion[idx]?.model_details.status ==
                                  "VerificationInProgress"
                                ? "In Progress"
                                : activatedVersion[idx]?.model_details.status ==
                                  "TestingSuccess"
                                ? "Testing Successful"
                                : activatedVersion[idx]?.model_details.status ==
                                  "TestingFailed"
                                ? "Testing Failed"
                                : activatedVersion[idx]?.model_details.status ==
                                  "TestingFailed"
                                ? "Testing Failed"
                                : activatedVersion[idx]?.model_details.status ==
                                  "DownloadRepoSuccess"
                                ? "Validating"
                                : activatedVersion[idx]?.model_details.status ==
                                  "HWTestingSuccess"
                                ? "H/W Test Successful"
                                : activatedVersion[idx]?.model_details.status ==
                                  "HWTestingFailed"
                                ? "H/W Test Failed"
                                : activatedVersion[idx]?.model_details.status}
                            </div>
                            <div>
                              {activatedVersion[idx]?.model_details.status ==
                                "ValidationFailed" ||
                              activatedVersion[idx]?.model_details.status ==
                                "TestingFailed" ||
                              activatedVersion[idx]?.model_details.status ==
                                "HWTestingFailed" ? (
                                <button
                                  className='resubmission-monitor'
                                  onClick={() => {
                                    dispatch(
                                      getResubmitModel(
                                        activatedVersion[idx]?.model_details
                                      )
                                    );
                                    dispatch(getDraftSubmit(null));
                                    dispatch(getModel());
                                    sessionStorage.removeItem("opened-model");
                                    // dispatch(getDevPage("deployment"));
                                    navigate("/developer/submit-model");
                                  }}>
                                  Resubmit
                                </button>
                              ) : activatedVersion[idx]?.model_details.status ==
                                "Veified" ? (
                                ""
                              ) : (
                                activatedVersion[idx]?.model_details.status ==
                                  "Unsubmitted" && (
                                  <button
                                    className='resubmission-monitor'
                                    onClick={() => {
                                      if (
                                        activatedVersion[idx]?.model_details
                                          .status == "ValidationFailed" ||
                                        activatedVersion[idx]?.model_details
                                          .status == "Unsubmitted"
                                      ) {
                                        dispatch(
                                          getDraftSubmit(
                                            activatedVersion[idx]?.model_details
                                          )
                                        );
                                        dispatch(getResubmitModel(null));
                                        // dispatch(getDevPage("deployment"));
                                        navigate("/developer/submit-model");
                                      }
                                    }}>
                                    Submit Model
                                  </button>
                                )
                              )}
                            </div>
                            <div>
                              <div
                                className={`${
                                  activatedVersion[idx]?.model_details
                                    .model_state == "Activated"
                                    ? "acivated-model"
                                    : "inactivated-model"
                                }`}>
                                {
                                  activatedVersion[idx]?.model_details
                                    .model_state
                                }
                              </div>
                            </div>
                            <div>
                              <div className='access-control-button'>
                                {activatedVersion[idx]?.model_details.visibility
                                  .charAt(0)
                                  .toUpperCase() +
                                  activatedVersion[
                                    idx
                                  ]?.model_details.visibility.slice(1)}
                              </div>
                            </div>
                            <div
                              className='view-button'
                              onClick={() => {
                                dispatch(
                                  getInactiveVersion(inActiveVersion[idx])
                                );
                                dispatch(getModel(activatedVersion[idx]));
                                sessionStorage.setItem(
                                  "opened-model",
                                  JSON.stringify(activatedVersion[idx])
                                );
                                // setOpenDetailsPage(activatedVersion[idx]);
                                console.log("storing in sessions");
                              }}>
                              View
                            </div>
                          </div>
                          <div className='collapseable-main'>
                            <div
                              className={`${
                                openingModelIdx == idx
                                  ? "expandable-div"
                                  : "collapseable-div"
                              }`}>
                              {inActiveVersion[idx]?.map((el) => {
                                return (
                                  <div className='expanded-content'>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}>
                                      <div style={{ flex: "1.5" }}>
                                        {"[ " + el.version + " ]"}
                                      </div>
                                      <div
                                        style={{
                                          flex: "6",

                                          textAlign: "left",
                                        }}>
                                        {el?.model_details.model_id}
                                      </div>
                                    </div>
                                    <div>
                                      {el?.model_details.CreatedAt.substring(
                                        0,
                                        10
                                      )}
                                    </div>
                                    <div
                                      id='model-status'
                                      onClick={() => {
                                        setOpenDetailsPage(el);
                                      }}
                                      className={`${
                                        el?.model_details.status ==
                                          "ValidationFailed" ||
                                        el?.model_details.status ==
                                          "VerificationFailed"
                                          ? "red"
                                          : el?.model_details.status ==
                                              "VerifiedModelLocally" ||
                                            el?.model_details.status ==
                                              "ValidationSuccessful" ||
                                            el?.model_details.status ==
                                              "TestingSuccess"
                                          ? "green"
                                          : el?.model_details.status ==
                                            "Unsubmitted"
                                          ? "sky"
                                          : el?.model_details.status !=
                                              "Unsubmitted" &&
                                            (el?.model_details.status !=
                                              "VerifiedModelLocally" ||
                                              el?.model_details.status !=
                                                "ValidationSuccessful") &&
                                            el?.model_details.status !=
                                              "ValidationFailed" &&
                                            "yellow"
                                      }`}>
                                      {el?.model_details.status ==
                                      "ValidationFailed"
                                        ? "Validation Failed"
                                        : el?.model_details.status ==
                                          "ValidationSuccessful"
                                        ? "Validation Successful"
                                        : el?.model_details.status ==
                                          "Unsubmitted"
                                        ? "Unsubmitted"
                                        : el?.model_details.status ==
                                          "UploadModelSuccess"
                                        ? "Validating"
                                        : el?.model_details.status ==
                                          "VerificationInProgress"
                                        ? "In Progress"
                                        : el?.model_details.status ==
                                          "TestingSuccess"
                                        ? "Testing Successful"
                                        : el?.model_details.status ==
                                          "TestingFailed"
                                        ? "Testing Failed"
                                        : el?.model_details.status ==
                                          "DownloadRepoSuccess"
                                        ? "Validating"
                                        : el?.model_details.status}
                                    </div>
                                    <div></div>
                                    <div>{el?.model_details.model_state}</div>
                                    <div>
                                      {el?.model_details.visibility
                                        .charAt(0)
                                        .toUpperCase() +
                                        el?.model_details.visibility.slice(1)}
                                    </div>
                                    <div
                                      onClick={() => {
                                        dispatch(getModel(el));
                                        // setOpenDetailsPage(el);
                                        sessionStorage.setItem(
                                          "opened-model",
                                          JSON.stringify(el)
                                        );
                                      }}
                                      className='view-button'>
                                      View
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })
                )}
              </div>
            </div>
          ) : (
            <div>
              <DevTracker
                modelDetails={openDetailsPage}
                setOpenDetailsPage={setOpenDetailsPage}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {(!allModels || allModels == "No data") &&
          openJoy == true &&
          state.stepIndex != 6 ? (
            <ModelDemoPage />
          ) : (!allModels || allModels == "No data") &&
            openJoy == true &&
            state.stepIndex == 6 ? (
            <TrackerDemoPage />
          ) : (
            (!allModels || allModels == "No data") &&
            openJoy == false && (
              <div className='tracker-page-without-data-monitor'>
                <div className='without-data-text-monitor'>
                  <div className='without-data-text-head-monitor'>
                    Looks like
                    <br /> you have no <br /> models submitted.
                  </div>
                  <div className='without-data-text-subhead-monitor'>
                    You can find all your models, their versions, and current
                    Status based on which you can take necessary actions.
                  </div>
                  <div className='without-data-text-button-monitor'>
                    <div className='button-shadow-monitor'></div>
                    <Link to='/developer/submit-model'>
                      <div
                        className='content-btn-monitor add-first-model'
                        onClick={() => {
                          // dispatch(getDevPage("deployment"));
                          navigate("/developer/submit-model");
                        }}>
                        Submit Your First Model
                      </div>
                    </Link>
                  </div>
                </div>
                <div className='without-data-img-monitor'>
                  <img src={monitorImg} />
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default DevHomeMonitor;
