import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { getEarlyAccess } from "../../Redux/Actions/EarlyAccessPopup/EarlyAccess";
import { MdClose } from "react-icons/md";
import "./EarlyAccessPopup.css";
function EarlyAccessPopup() {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const initialValues = {
    name: "",
    company_name: "",
    email: "",
    purpose: "",
  };
  const onSubmit = (values) => {
    fetch("https://skyserve.ai/contact.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: values.name,
        message: values.purpose,
        company_name: values.company_name,
        email: values.email,
      }),
    });
    setSubmitted(true);
  };
  const validationSchema = Yup.object({
    name: Yup.string().min(3).required("Required !"),
    company_name: Yup.string().required("Required !"),
    email: Yup.string().email("Invalid email address").required("Required !"),
    purpose: Yup.string().required("Required !"),
  });
  return (
    <div>
      {submitted == false && (
        <div className='early-access-form'>
          <div className='add-details'>
            <div className='add-details-text'>Add details here </div>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
              onClick={() => dispatch(getEarlyAccess(false))}>
              <MdClose />
            </button>
          </div>
          <div className='add-details-sub-heading'>
            Provide following details to get the access .
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values)}>
            {(formik) => (
              <Form>
                <label className='label' htmlFor='name'>
                  Name
                </label>
                <Field required className='input-box' type='text' name='name' />
                {/* <ErrorMessage name='name' /> */}
                <label className='label' htmlFor='company_name'>
                  Company Name
                </label>
                <Field
                  required
                  className='input-box'
                  name='company_name'
                  type='text'
                />
                {/* <ErrorMessage name='company_name' /> */}
                <label className='label' htmlFor='email'>
                  Email Address
                </label>
                <Field
                  required
                  className='input-box'
                  name='email'
                  type='email'
                />
                {/* <ErrorMessage name='email' /> */}
                <label className='label' htmlFor='purpose'>
                  Purpose to access
                </label>
                <Field
                  required
                  className='input-box'
                  name='purpose'
                  type='text'
                />
                {/* <ErrorMessage name='purpose' /> */}
                <button className='formik-submit' type='submit'>
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </div>
      )}
      {submitted && (
        <div className='submission-done'>
          <div className='submission-head'>
            <div>Submitted !</div>
            <div>
              <button
                onClick={() => {
                  dispatch(getEarlyAccess(false));
                }}>
                <MdClose />
              </button>
            </div>
          </div>
          <div className='submission-sub-head'>
            Thank you for your interest !
          </div>
          <div className='submission-description'>
            We've sent a copy to the email address you provided. Please check
            your inbox (and your spam folder, just in case) to access the
            brochure.If you don't receive it within the next few minutes, please
            contact us at [insert contact information].
          </div>
        </div>
      )}
    </div>
  );
}

export default EarlyAccessPopup;
