import React from "react";
import "./NotAccessText.css";
function NotAccessText() {
  return (
    <div className='not-access-div'>
      <div className='pointer-div'></div>
      <div>Please Verify Your Email</div>
    </div>
  );
}

export default NotAccessText;
