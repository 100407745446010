import { NOTIFICATION } from "../../Actions/Notifications/NotificationsAction"

const init = {
    notification:null
}
export const notificationReducer = (state = init, { type, payload }) => {
    switch (type) {
        case NOTIFICATION:
            return { ...state , notification: payload ? payload.sort((a, b) => b.timestamp - a.timestamp) : null }
        default:
            return state
    }
}