import { POP } from "../Actions/OpenPop"

const init = {
    pop:""
}
export const openPopReducer = (state = init, { type, payload }) => {
    switch (type) {
        case POP:
            return {...state,pop: payload}
        default:
            return state
    }
}