import React, { useEffect, useRef, useState } from "react";
import { BsAsterisk, BsCaretDownFill } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import "./InputBands.css";
import { useSelector } from "react-redux";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
function InputBands({ setSec3Details, sec3Details, proMode }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [numberOfBandError, setNumberOfBandError] = useState();
  const [openInput, setOpenInput] = useState(false);
  const [openColorLists, setOpenColorLists] = useState();
  // const colors = ["Red", "Green", "Blue"];
  const [numberOfBand, setNumberOfBand] = useState();
  const [tempBands, setTempBands] = useState([]);
  const [error, setError] = useState("");
  const divRef = useRef(null);
  const [openNumberInputs, setOpenNumberInputs] = useState(false);
  const fieldErr = useSelector((store) => store.subError.subError);
  const userMissionAccess = localStorage.getItem("missionAccess");
  const missionSpecificBands = {
    Matterhorn: ["Red", "Green", "Blue"],
    Denali: ["Red", "Green", "Blue"],
    K2: ["Red", "Green", "Blue", "NIR"],
  };
  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "input_bands") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);

  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenInput(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  function validatingInputFiledForNumber(e) {
    const inputValue = e.target.value;

    // Validate input as integer
    if (isValidInteger(inputValue)) {
      return inputValue;
    }
  }

  const isValidInteger = (inputValue) => {
    // Ensure input is not empty
    if (inputValue.trim() === "") {
      return true; // or false, depending on your requirement
    }

    // Check if the input is a valid integer
    return /^[1-9]\d*$/.test(inputValue);
  };

  // Set value in the input field and then change the mode of submission then
  // it will set the value to input bands
  useEffect(() => {
    setNumberOfBand(
      sec3Details.input_bands.length == 0 ? null : sec3Details.input_bands
    );
    setTempBands(sec3Details.input_bands);
  }, [sec3Details]);

  // When ever try to edit and change the value of number of bands then it will set [] the value to the input bands
  useEffect(() => {
    setTempBands([]);
  }, [numberOfBand && numberOfBand.length]);
  const bandNumber = [];

  // It creates the number dropdown that is being selected by the user (Red,Green,Blue,NIR)
  for (let i = 0; numberOfBand && i < numberOfBand.length; i++) {
    bandNumber.push(
      <div>
        <div className='selection'>
          <div>Band - {i + 1} </div>
          <div className='select-color-div'>
            <div
              onClick={() => {
                if (openColorLists == i) {
                  setOpenColorLists();
                } else {
                  setOpenColorLists(i);
                }
              }}
              className='selected-color pointer-hand'>
              <div>
                {tempBands.length > 0 && tempBands[i] != 0
                  ? tempBands[i] == "red"
                    ? "Red"
                    : tempBands[i] == "green"
                    ? "Green"
                    : tempBands[i] == "blue"
                    ? "Blue"
                    : tempBands[i] == "nir"
                    ? "NIR"
                    : "Select ..."
                  : "Select ..."}
              </div>
              <div className={`${openColorLists == i ? "goUp" : "goDown"}`}>
                <BsCaretDownFill />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${
            openColorLists == i ? "open-list-of-colors" : "close-list-of-colors"
          }`}>
          {missionSpecificBands[userMissionAccess]?.map((el) => (
            <div
              id={`${
                tempBands.includes(el.toLowerCase()) == true && "disabled-color"
              }`}
              className='single-type pointer-hand'
              onClick={() => {
                const arr = [...tempBands];
                if (arr.includes(el.toLowerCase()) == false) {
                  arr[i] = el.toLowerCase();
                  // setInputColor(arr);
                  setTempBands(arr);
                  setOpenColorLists();
                }
              }}>
              {el}
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <div ref={divRef} className='input-band-div'>
      <div className='input-label'>
        Model Input <BsAsterisk color='red' size={"0.6vw"} />{" "}
      </div>
      <div
        onMouseEnter={(e) => {
          e.currentTarget.style.position = "relative";
          setOpenTooltip("input");
        }}
        onMouseLeave={(e) => {
          // e.currentTarget.style.position = "static";
          setOpenTooltip("");
        }}
        onClick={() => {
          setOpenInput(!openInput);
        }}
        className='selected-input-bands pointer-hand'>
        <div>
          {sec3Details.input_bands.length == 0 ||
          sec3Details.input_bands[0] == "" ? (
            <div className='band-not-selected-text'>Select Input Bands</div>
          ) : (
            <div className='bands-selected'>
              {sec3Details.input_bands.map((el, id) => (
                <div>
                  {el == "red"
                    ? "Red"
                    : el == "green"
                    ? "Green"
                    : el == "blue"
                    ? "Blue"
                    : el == "nir" && "NIR"}{" "}
                  <MdClose
                    className='pointer-hand'
                    onClick={() => {
                      let arr = [...sec3Details.input_bands];
                      arr.splice(id, 1);
                      setSec3Details({ ...sec3Details, input_bands: arr });
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className={`${openInput == true ? "goUp" : "goDown"}`}>
          <BsCaretDownFill />
        </div>

        {error != "" && (
          <div className='error-div'>
            <div style={{ position: "relative" }}>
              <div className='pointer-error'></div>
              <div>{error}</div>
            </div>
          </div>
        )}
        {openTooltip == "input" &&
          openInput == false &&
          proMode == true &&
          error == "" && (
            <InformativeTooltip
              info='The specific bands of data that are used as input for your model.'
              doc=''
              icon={true}
              link='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-input'
            />
          )}
      </div>
      <div
        className={`${
          openInput == true ? "open-input-selection" : "close-input-selection"
        }`}>
        <div className='input-band-selection'>
          <div>Select Number of Input Bands : </div>
          <div ref={divRef} className='lop'>
            <div className='lop-selection-div'>
              <div
                onMouseEnter={(e) => {
                  e.currentTarget.style.position = "relative";
                  setOpenTooltip("lop");
                }}
                onMouseLeave={(e) => {
                  // e.currentTarget.style.position = "static";
                  setOpenTooltip("");
                }}
                className='selected-lop pointer-hand'
                onClick={() => {
                  setOpenNumberInputs(!openNumberInputs);
                  setError("");
                }}>
                <div>
                  {numberOfBand ? (
                    <span>{numberOfBand.length}</span>
                  ) : (
                    "Number of bands"
                  )}
                </div>
                <div
                  className={`${openNumberInputs == true ? "goUp" : "goDown"}`}>
                  <BsCaretDownFill />
                </div>
              </div>
              {error != "" && (
                <div className='error-div'>
                  <div style={{ position: "relative" }}>
                    <div className='pointer-error'></div>
                    <div>{error}</div>
                  </div>
                </div>
              )}
              <div
                className={`${
                  openNumberInputs == true
                    ? "lop-lists-open"
                    : "lop-lists-close"
                }`}>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setNumberOfBandError();
                    setNumberOfBand(Array(+1).fill(""));
                    setOpenNumberInputs(false);
                  }}>
                  1
                </div>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setNumberOfBandError();
                    setNumberOfBand(Array(+2).fill(""));
                    setOpenNumberInputs(false);
                  }}>
                  2
                </div>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setNumberOfBandError();
                    setNumberOfBand(Array(+3).fill(""));
                    setOpenNumberInputs(false);
                  }}>
                  3
                </div>
                {userMissionAccess == "K2" && (
                  <div
                    className='single-type pointer-hand'
                    onClick={() => {
                      setNumberOfBandError();
                      setNumberOfBand(Array(+4).fill(""));
                      setOpenNumberInputs(false);
                    }}>
                    4
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='error-div'>
            {numberOfBandError && numberOfBandError}
          </div>
        </div>
        <div className='zero'>
          <div className='number-of-bands'>{bandNumber}</div>
        </div>

        <div className='add-config-btn'>
          <button
            onClick={() => {
              setNumberOfBand();
              setSec3Details({ ...sec3Details, input_bands: [] });
            }}>
            Cancel
          </button>
          <button
            style={{
              backgroundColor:
                tempBands.length == 0 || tempBands.includes(undefined)
                  ? "gray"
                  : "",
              border:
                tempBands.length == 0 || tempBands.includes(undefined)
                  ? "1px solid gray"
                  : "",
            }}
            disabled={tempBands.includes(0) == true ? true : false}
            onClick={() => {
              setSec3Details({ ...sec3Details, input_bands: tempBands });
              if (tempBands.length == 0 || tempBands.includes(undefined)) {
                setOpenInput(true);
              } else {
                setOpenInput(false);
              }
            }}>
            {sec3Details.input_bands.length == 0 ? "Add Input" : "Update Input"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default InputBands;
