import React, { useEffect, useState } from "react";
import "./AssignedImgPop.css";
import { FaClipboard, FaClipboardCheck } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

// This component is not used in the recent overview page
function AssignedImgPop({ assignedImg, setOpenAssignedImgPop }) {
  const [imagesLink, setImagesLink] = useState();
  const [selectedIds, setSelectedIds] = useState();
  const [showClipBoardPop, setShowClipboardPop] = useState(false);
  function selectImg(image) {
    if (imagesLink == undefined) {
      setImagesLink([
        {
          image_id: image.image_id,
          image_s3_link: image.s3_presigned_url,
          image_preview_link: image.preview_presigned_url,
        },
      ]);
      setSelectedIds([image.image_id]);
    } else {
      let arr = [...imagesLink];
      let arrId = [...selectedIds];
      let present = false;
      let idx;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].image_id == image.image_id) {
          present = true;
          idx = i;
          break;
        }
      }
      if (present == true) {
        arr.splice(idx, 1);
        arrId.splice(idx, 1);
      } else {
        arr.push({
          image_id: image.image_id,
          image_s3_link: image.s3_presigned_url,
          image_preview_link: image.preview_presigned_url,
        });
        arrId.push(image.image_id);
      }
      setImagesLink(arr);
      setSelectedIds(arrId);
    }
  }
  useEffect(() => {
    if (showClipBoardPop == true) {
      setShowClipboardPop(false);
    }
  }, [showClipBoardPop]);
  return (
    <div className='assignedImg-pop'>
      <div
        className='heading-assignedimg'
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div>Select Image URL to use</div>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "white",
          }}>
          <AiOutlineClose onClick={() => setOpenAssignedImgPop(false)} />
        </button>
      </div>
      <div className='preview-img-div'>
        {assignedImg &&
          assignedImg.map((el, id) => (
            <div className='image-url-div'>
              <div className='image-box-to-be-selected'>
                <img src={el.preview_presigned_url} alt='img' />
                {selectedIds && selectedIds.includes(el.image_id) == true && (
                  <div className='img-overlay'>Selected</div>
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    selectImg(el);
                  }}>
                  Image URL
                  <div className='clipboard-icon'>
                    {selectedIds &&
                    selectedIds.includes(el.image_id) == true ? (
                      <FaClipboardCheck />
                    ) : (
                      <FaClipboard />
                    )}
                  </div>
                </button>
              </div>
            </div>
          ))}
      </div>
      <div>
        <button
          className='copy-selected-btn'
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(imagesLink));
            setShowClipboardPop(true);
          }}>
          Copy
        </button>
      </div>
      {showClipBoardPop == true && (
        <div className='bounce-left-div'>
          <div className='bounce-in-left'>
            Copied To Clipboard <FaClipboardCheck />
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignedImgPop;
