import React, { useEffect, useState } from "react";
import "./ObjectDetection.css";
import { BsCaretDownFill } from "react-icons/bs";
function ObjectDetection({
  giveOutPutData,
  singleOutputObj,
  outputData,
  addNew,
  setOutputData,
  setOpenOutputOptions,
}) {
  const [openSelectDtype, setOpenSelectDtype] = useState(false);
  const [selectObjectDetection, setSelectObjectDetection] = useState({
    output_type: "",
    dtype: "",
  });

  // When try to change the values of the singleOutputObj then it will update the objectDetection
  // It stores values of the singleOutputObj in objectDetection
  useEffect(() => {
    if (singleOutputObj) {
      setSelectObjectDetection({
        output_type: singleOutputObj.output_type,
        dtype: singleOutputObj.dtype,
      });
    }
  }, [singleOutputObj]);

  // It will reset the values of the objectDetectionObj when addNew is true so that we can add new values
  useEffect(() => {
    if (addNew == true) {
      setSelectObjectDetection({
        output_type: "",
        dtype: "",
      });
    }
  }, [addNew]);
  return (
    <div>
      <div className='label-head'>Geometry Type :</div>
      <div>
        <div
          onClick={() => {
            setOpenSelectDtype(!openSelectDtype);
          }}
          className='selected-detection-data-type pointer-hand'>
          <div>
            {selectObjectDetection.dtype == ""
              ? "Select ..."
              : selectObjectDetection.dtype == "point"
              ? "Point"
              : "Boundingbox"}
          </div>
          <div className={`${openSelectDtype == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
        </div>
        <div
          className={`${
            openSelectDtype == true ? "open-dtype-list" : "close-dtype-list"
          }`}>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setSelectObjectDetection({
                ...selectObjectDetection,
                dtype: "point",
                output_type: "obj_detection_point",
              });
              setOpenSelectDtype(false);
            }}>
            Point
          </div>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setSelectObjectDetection({
                ...selectObjectDetection,
                dtype: "bbox",
                output_type: "obj_detection_bbox",
              });
              setOpenSelectDtype(false);
            }}>
            Boundingbox
          </div>
        </div>
      </div>

      <div className='add-config-btn'>
        <button
          onClick={() => {
            setSelectObjectDetection({
              output_type: "",
              dtype: "",
            });
            setOutputData({ name: "" });
          }}>
          Reset
        </button>
        <button
          style={{
            background:
              (selectObjectDetection.dtype == "" ||
                selectObjectDetection.output_type == "" ||
                outputData.name == "") &&
              "gray",
            cursor:
              (selectObjectDetection.dtype == "" ||
                selectObjectDetection.output_type == "" ||
                outputData.name == "") &&
              "not-allowed",
            border:
              (selectObjectDetection.dtype == "" ||
                selectObjectDetection.output_type == "" ||
                outputData.name == "") &&
              "1px solid gray",
          }}
          disabled={
            (selectObjectDetection.dtype == "" ||
              selectObjectDetection.output_type == "" ||
              outputData.name == "") &&
            true
          }
          onClick={() => {
            giveOutPutData(selectObjectDetection);
            setOpenOutputOptions(false);
          }}>
          {singleOutputObj ? "Update" : "Save"}
        </button>
      </div>
    </div>
  );
}

export default ObjectDetection;
