import { ALERTAFTEREMAILVERIFICATION } from "../../Actions/AlertAfterEmailVerification/AlertAfterEmailVerification"

const init = {
    alertOpen:false
}

export const alertAfterEmailVerificationReducer = (state = init, { type, payload }) => {
    switch (type) {
        case ALERTAFTEREMAILVERIFICATION:
            return {...state,alertOpen:payload};
        default:
            return state
    }
}