import React from "react";
import "./ModelDemoPage.css";
function ModelDemoPage() {
  const staticObj = [
    {
      Name: "MSNT-1",
      Date: "2021-01-03",
      Status: "Verified",
      Action: "",
      Activation_Status: "Disable",
      Visibility: "Public",
      Details: "View",
    },
    {
      Name: "Object Detection",
      Date: "2021-03-28",
      Status: "Verification Failed",
      Action: "Resubmit",
      Activation_Status: "Disable",
      Visibility: "Private",
      Details: "View",
    },
    {
      Name: "Test Model",
      Date: "2022-10-09",
      Status: "Draft",
      Action: "Submit",
      Activation_Status: "Enabled",
      Visibility: "Private",
      Details: "View",
    },
  ];
  return (
    <div>
      <div className='monitor-model-text'>
        Monitor Models
        <div className='submit-model-underline'></div>
      </div>
      <div style={{ marginTop: "5%" }} className='monitor-table'>
        <div>
          <div className='static-head'>
            <div>Name</div>
            <div>Date</div>
            <div>Status</div>
            <div>Action</div>
            <div>Activation Status</div>
            <div>Visibility</div>
            <div>Details</div>
          </div>
        </div>
        {staticObj.map((el) => (
          <div>
            <div className='table-content'>
              <div className='model-name-box'>{el.Name}</div>
              <div>{el.Date}</div>
              <div
                id='model_status'
                className={`${
                  el.Status == "Verification Failed"
                    ? "red"
                    : el.Status == "Draft"
                    ? "sky"
                    : "green"
                }`}>
                {el.Status}
              </div>
              <div className='resubmission-monitor'>{el.Action}</div>
              <div>{el.Activation_Status}</div>
              <div>{el.Visibility}</div>
              <div
                style={{
                  color: "gold",
                  padding: "1% 0",
                }}
                className='view-button'>
                {el.Details}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModelDemoPage;
