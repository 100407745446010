import React from "react";
import "./InformativeTooltip.css";
import { AiFillInfoCircle } from "react-icons/ai";
function InformativeTooltip({ info, doc, icon, link }) {
  // if (icon == true) {
  //   document.querySelector(".tooltip-content-div").backgroundColor =
  //     "rgba(55, 73, 166, 1)";
  // }
  return (
    <div className='tooltip-div'>
      <div
        className={`${
          icon == false ? "tooltip-content-div-white" : "tooltip-content-div"
        }`}>
        <div
          className={`${
            icon == false ? "pointer-to-icon-white" : "pointer-to-icon"
          }`}></div>

        <div
          style={{
            lineHeight: "1vw",
            display: "flex",
            alignItems: "center",
            gap: "0.5vw",
          }}>
          <div>
            {icon == true && <AiFillInfoCircle className='informative-btn' />}
          </div>

          <div>
            {info + " "}
            {link && (
              <a
                style={{ color: "pink", textDecoration: "underline" }}
                href={link}
                target='_blank'>
                Know more
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InformativeTooltip;
