import { FCM_MESSAGE, FCM_NOTIFICATION } from "../../Actions/FCM/fcm"


const init = {
    messaging: undefined,
    notificationPayload:undefined
}

export const fcmReducer = (state=init,{type,payload}) => {
    if (type == FCM_MESSAGE) {
        return {...state,messaging:payload}
    }
    else if (type == FCM_NOTIFICATION) {
        return {...state,notificationPayload:payload}
    }
    return state
}