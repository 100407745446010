export const updateTos = async (email, token, tos) => {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({ tos_accepted: tos }),
        redirect: 'follow'
    };

    try {
        const response = await fetch(process.env.REACT_APP_BASE_URL+`/v1/user/updateStatusOfTermsAndService/${email}`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return { error: error.message };
    }
}