export const checkIfUserAcceptedTerms = async (email,token) => {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", token);

    var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
    };

    try {
        const response = await fetch(process.env.REACT_APP_BASE_URL+`/v1/user/checkUserHasAcceptedTermsAndService/${email}`, requestOptions);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return { error: error.message };
    }
}