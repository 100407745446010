import React, { useEffect, useState } from "react";
import "./Login.css";
import logo from "../../Images/logo-png-1.png";
// import register_background from "../../Images/i2(1).png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../Redux/Actions/users/UserAction";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import FailurePop from "../RegistartionPopups/FailurePop";
import { getSSEmsg } from "../../Redux/Actions/SSEmsg/SSEmsg";
import { updateTos } from "../../services/updateTos";
import { callingFcmApp } from "../../services/fcmInitialize";
import { messaging } from "../../fcmIntegration/firebase";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
// import { getModel } from "../../Redux/Actions/Models/ModelAction";
// import { getAllModels } from "../../Redux/Actions/AllModels/AllModelsAction";
const Login = ({ checkUserAccessForMissionFn }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store) => store.user.user);
  const userSaved = JSON.parse(localStorage.getItem("user-details"));
  const [openFailurePop, setOpenFailurePop] = useState(false);
  const [loginError, setLoginError] = useState("");
  const spinner = useSelector((store) => store.spinner.spinner);
  const [tosAccepted, setTosAccepted] = useState(true);

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setLoginForm({
      ...loginForm,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    dispatch(spinnerAction(true));
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      identifier: loginForm.email,
      password: loginForm.password,
      method: "password",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL + "/v1/auth/login", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // When we get the user account details
        if (result.Status != 400) {
          // setOpenSuccessPop(true);
          // Call the FCM function to get the token
          callingFcmApp(
            result.Message.session.identity.traits.email,
            result.Message.session_token,
            messaging
          );
          // Set the user details in the redux store
          dispatch(userAction(result.Message.session.identity.traits));
          // if (
          //   result.Message.session.identity.traits.developer_access != undefined
          // ) {
          //   dispatch(getDeveloperAccess(true));
          // } else {
          //   dispatch(getDeveloperAccess(false));
          // }
          localStorage.setItem(
            "user-details",
            JSON.stringify(result.Message.session.identity.traits)
          );
          localStorage.setItem(
            "session-token-skyserve",
            result.Message.session_token
          );
          //expiringOn: new Date().getTime() + 86400000,

          // Call the TOS function to set that to true
          updateTermsAndService(
            loginForm.email,
            result.Message.session_token,
            tosAccepted
          );

          // Check the user mission access
          checkUserAccessForMissionFn(
            result.Message.session_token,
            loginForm.email
          );
        } else {
          dispatch(spinnerAction(false));
          // alert(result.Message[0].text);
          // setOpenFailurePop(true);
          setLoginError("Invalid Credentials!! Please Contact SkyServe Team");
        }
        // deleteToken();
        // setLoginForm({
        //   email: "",
        //   password: "",
        // });
      })
      .catch((error) => {
        console.log("error", error)});
  };

  // Function to update the TOS in the DB
  async function updateTermsAndService(email, token, tos) {
    console.log("login tos");
    const response = await updateTos(email, token, tos);
    console.log(response);
    dispatch(spinnerAction(false));
    navigate("/developer/overview");
  }

  // SSE integration tests
  let source;
  function SseIntegration(uniqueId, session_token) {
    source = new EventSource(
      process.env.REACT_APP_BASE_URL + "/stream?sse-unique-id=" + uniqueId
    );

    source.onopen = function (event) {};

    source.onerror = function (event) {};

    source.onmessage = function (event) {
      dispatch(getSSEmsg(JSON.parse(event.data)));
    };
  }

  useEffect(() => {
    if (user || userSaved) {
      // navigate(-1);
      navigate("/developer/overview");
    }
  }, [user, userSaved]);
  return (
    <div className='login-page-div'>
      <div className='login-page-image'>
        <div className='login-page-logo'>
          <img src={logo} alt='logo' />
        </div>
        <div className='login-page-quotes'>
          Enabling Insights as a Service from the Edge
        </div>
      </div>
      <div className='login-form-details'>
        <Link to='/'>
          <div className='signin-mobile-logo'>
            <img src={logo} alt='logo' />
          </div>
        </Link>
        <div className='signup-heading'>Sign In !</div>
        <div className='signup-subheading'>
          Please fill the details to accesss your account
        </div>
        <div className='registation-form'>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}>
            <input
              onChange={handleChange}
              name='email'
              type='email'
              placeholder='Email'
            />
            <input
              onChange={handleChange}
              name='password'
              type='password'
              placeholder='Password'
            />
            <div className='checkbox-developer-login'>
              <div>
                {/* <input
                  className='checkbox-input pointer-hand'
                  type='checkbox'
                  name=''
                  id=''
                  onChange={(e) => {
                    setTosAccepted(e.target.checked);
                  }}
                  value={tosAccepted}
                />
                <span style={{ paddingLeft: "5px" }}>
                  Accept Terms and Conditions
                </span> */}
                <div className='term-of-service-text'>
                  <span className='grayed-out-text'>
                    By continuing, you agree to SkyServe's{" "}
                  </span>
                  <span>
                    <a
                      className='terms-of-service-text-color'
                      href='https://drive.google.com/file/d/1LBxgVm3nomdyjg78_OYfK4IeOPFq396O/view'
                      target='_blank'>
                      Terms of Service
                    </a>
                    ,{" "}
                    <a
                      className='terms-of-service-text-color'
                      href='https://skyserve.ai/Privacy-policy'
                      target='_blank'>
                      Privacy Policy
                    </a>
                  </span>
                </div>
              </div>
              {/* <div className='login-link'>
                <Link to='/forgot-password'>Forgot Password?</Link>
              </div> */}
            </div>
            <div className='error-login'>
              {loginError != "" ? loginError : ""}
            </div>
            <div
              className={`${
                loginForm.email == "" || loginForm.password == ""
                  ? "login-btn-form-disabled"
                  : "login-btn-form"
              }`}>
              <button
                disabled={
                  loginForm.email == "" || loginForm.password == ""
                    ? true
                    : false
                }
                type='submit'>
                Sign in
              </button>
            </div>
          </form>
          {/* <div className='account-logined'>
            Don't have an account?
            <span className='login-link'>
              <Link to='/register'>Sign up</Link>
            </span>
          </div> */}
        </div>
      </div>
      {openFailurePop && (
        <div className='register-success-pop'>
          <FailurePop
            heading='Sorry !'
            setOpenFailurePop={setOpenFailurePop}
            msg='Email or password is incorrect.'
          />
        </div>
      )}
    </div>
  );
};

export default Login;
