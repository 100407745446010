import React, { useEffect, useRef, useState } from "react";
import OutputImageSection from "./OutputFiledSections/ImageSection/ImageSection";
import Segmentation from "./OutputFiledSections/Segmentation/Segmentation";
import ObjectClassification from "./OutputFiledSections/ObjectClassification/ObjectClassification";
import ObjectDetection from "./OutputFiledSections/ObjectDetection/ObjectDetection";
import "./OutputBands.css";
import { BsAsterisk, BsCaretDownFill, BsPlusLg } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import { AiFillInfoCircle } from "react-icons/ai";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
function OutputBands({
  sec4Details,
  setSec4Details,
  proMode,
  setAdditionalOpenInfo,
}) {
  const [openOutputTypeSelection, setOpenOutputTypeSelection] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [openOutputOptions, setOpenOutputOptions] = useState(false);
  const [outputType, setOutputType] = useState("image");
  const [nameError, setNameError] = useState("");
  const [outputData, setOutputData] = useState({
    name: "",
  });
  const [singleOutputObj, setSingleOutputObj] = useState();
  const [singleOutputObjId, setSingleOutputObjId] = useState();
  const [openTooltip, setOpenTooltip] = useState("");
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);

  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "output_bands") {
      setNameError(fieldErr.Message);
    } else {
      setNameError("");
    }
  }, [fieldErr]);
  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenOutputOptions(false);
        setNameError("");
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // If add new is true whn click on "+" then it will just change the state to false
  useEffect(() => {
    if (addNew == true) {
      setAddNew(false);
    }
  }, [addNew]);

  const giveOutPutData = (data) => {
    // When try to update any output object "singleOutputObj" will have value starts from 0 to number of output objects
    // So if we compare singleOutputObj == 0th index then it will be false that is why we are comparing with singleOutputObjId
    // with its type
    if (singleOutputObj && typeof singleOutputObjId == "number") {
      const arr = [...sec4Details.output_bands];
      arr[singleOutputObjId] = data;
      arr[singleOutputObjId].name = outputData.name;
      // Then store that value to sec4Details so that it will be available to the user to change the values
      setSec4Details({ ...sec4Details, output_bands: arr });
    } else {
      if (outputData.name != "") {
        const sec1 = ["segmentation", "image"];
        const sec2 = ["classification", "detection"];
        const arrNamesOutput = [...sec4Details.output_bands];
        let namePresent = false;
        // This for loop is used to check if the name is already present in the output bands
        for (let i = 0; i < arrNamesOutput.length; i++) {
          // If the output name is already present then
          if (arrNamesOutput[i].name == outputData.name) {
            // Segmentation and Image section output suffix should not same
            // Object classification and Detetion section output suffix should not same
            if (
              ((arrNamesOutput[i].output_type.includes(sec1[0]) == true ||
                arrNamesOutput[i].output_type.includes(sec1[1]) == true) &&
                (data.output_type.includes(sec1[0]) == true ||
                  data.output_type.includes(sec1[1]) == true)) ||
              ((arrNamesOutput[i].output_type.includes(sec2[0]) == true ||
                arrNamesOutput[i].output_type.includes(sec2[1]) == true) &&
                (data.output_type.includes(sec2[0]) == true ||
                  data.output_type.includes(sec2[1]) == true))
            ) {
              namePresent = true;
              break;
            }
          }
        }
        if (namePresent == false) {
          setOutputData({ ...outputData, ...data });
          let obj = { ...outputData };
          obj = { ...obj, ...data };
          setSec4Details({
            ...sec4Details,
            output_bands: [...sec4Details.output_bands, obj],
          });
        }
        // else if (singleOutputObj) {
        //   console.log(data);
        //   let arr = [...sec4Details.output_bands];
        //   for (let i = 0; i < arr.length; i++) {
        //     if (arr[i].name == singleOutputObj.name) {
        //       arr[i] = { ...arr[i], ...data };
        //       console.log(arr[i]);
        //       break;
        //     }
        //   }
        //   setSec4Details({ ...sec4Details, output_bands: arr });
        //   setSingleOutputObj();
        //   setOutputData({ name: "" });
        //   setOpenOutputOptions(true);
        //   setOutputType("image");
        //   setNameError("");
        // }
        else {
          setNameError("This output name is already present");
          setOpenOutputOptions(true);
        }
      }
    }
  };
  useEffect(() => {
    if (proMode == false) {
      setOpenOutputOptions(true);
    }
  }, [proMode]);
  // useEffect(() => {
  //   if (openTooltip != "") {
  //     setTimeout(() => {
  //       setOpenTooltip("");
  //     }, 3000);
  //   }
  // }, [openTooltip]);
  return (
    <div className='output-div'>
      <div>
        Model Output <BsAsterisk color='red' size={"0.6vw"} />{" "}
      </div>
      <div ref={divRef}>
        <div
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("output");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          onClick={() => {
            if (sec4Details && sec4Details.output_bands.length == 0) {
              setOpenOutputOptions(true);
            }
          }}
          className='selected-outputs pointer-hand'>
          {openTooltip == "output" &&
            openOutputOptions == false &&
            proMode == true &&
            nameError == "" && (
              <InformativeTooltip
                info='Resulting bands of data produced by your model as output.'
                doc=''
                icon={true}
                link='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-output'
              />
            )}
          <div
            className={`${
              sec4Details.output_bands.length == 0
                ? "outputband-not-list"
                : "outputband-list-div"
            }`}>
            {sec4Details &&
              sec4Details.output_bands.length > 0 &&
              sec4Details.output_bands.map((el, id) => (
                <div>
                  <div
                    className='pointer-hand single-selection-output'
                    onClick={() => {
                      if (el.output_type.includes("image")) {
                        setOutputType("image");
                      } else if (el.output_type.includes("segmentation")) {
                        setOutputType("segmentation");
                      } else if (el.output_type.includes("detection")) {
                        setOutputType("detection");
                      } else if (el.output_type.includes("classification")) {
                        setOutputType("classification");
                      }
                      setOutputData({ name: el.name });
                      setSingleOutputObj(el);
                      setSingleOutputObjId(id);
                      setOpenOutputOptions(true);
                    }}>
                    {el.name.length > 30
                      ? el.name.substring(0, 30) + "..."
                      : el.name}
                  </div>

                  <MdClose
                    className='pointer-hand'
                    onClick={() => {
                      let arr = [...sec4Details.output_bands];
                      arr.splice(id, 1);
                      setSec4Details({
                        ...sec4Details,
                        output_bands: arr,
                      });
                      if (
                        singleOutputObj.name == el.name &&
                        singleOutputObj.output_type == el.output_type
                      ) {
                        setSingleOutputObj();
                      }
                    }}
                  />
                </div>
              ))}
            {sec4Details && sec4Details.output_bands.length == 0 && (
              <div style={{ color: "black" }}>Add Output bands</div>
            )}
          </div>
          <div>
            <div>
              <BsPlusLg
                className='pointer-hand'
                onClick={() => {
                  setAddNew(true);
                  setSingleOutputObj();
                  setOpenOutputOptions(true);
                  setOutputType("image");
                  setOutputData({ name: "" });
                  setNameError("");
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            openOutputOptions == true
              ? "open-output-selection-box"
              : "close-output-selection-box"
          }`}>
          <div>
            <div>Output Suffix : </div>
            <div className='output-name'>
              <input
                onChange={(e) => {
                  const pattern = /^[a-zA-Z0-9-]*$/;
                  let value = e.target.value;
                  // Check if the value matches the pattern and does not contain spaces
                  if (pattern.test(value) && !value.includes(" ")) {
                    setOutputData({ ...outputData, name: value.trim() });
                    setNameError("");
                  }
                }}
                type='text'
                name=''
                id=''
                value={outputData.name}
              />
              {nameError != "" && (
                <div className='error-div'>
                  <div style={{ position: "relative" }}>
                    <div className='pointer-error'></div>
                    <div>{nameError}</div>
                  </div>
                </div>
              )}
            </div>
            <div className='output-selection-div'>
              <div>Select Output Type : </div>
              <div
                onClick={() => {
                  if (!singleOutputObj) {
                    setOpenOutputTypeSelection(!openOutputTypeSelection);
                  }
                }}
                className={
                  singleOutputObj
                    ? "disabled-cursor selected-output pointer-hand"
                    : "selected-output pointer-hand"
                }>
                <div>
                  {outputType
                    ? outputType == "image"
                      ? "Image"
                      : outputType == "segmentation"
                      ? "Segmentation"
                      : outputType == "classification"
                      ? "Object Classification"
                      : outputType == "detection" && "Object Detection"
                    : "Select Output Type"}
                </div>
                <div
                  className={`${
                    openOutputTypeSelection == true ? "goUp" : "goDown"
                  }`}>
                  <BsCaretDownFill />
                </div>
                <div
                  className={`${
                    outputType && openOutputTypeSelection == false
                      ? "output-suffix-details"
                      : "output-suffix-details-hide"
                  }`}>
                  <p
                    onMouseEnter={(e) => {
                      e.currentTarget.style.position = "relative";
                      setOpenTooltip("output-suffix");
                    }}
                    onMouseLeave={(e) => {
                      // e.currentTarget.style.position = "static";
                      setOpenTooltip("");
                    }}>
                    <AiFillInfoCircle />
                    <a
                      target='_blank'
                      style={{
                        color: "rgb(62, 62, 209)",
                        marginLeft: "3px",
                      }}
                      href='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-output'>
                      Read more about model output
                    </a>
                    {/* {openTooltip == "output-suffix" && (
                        <InformativeTooltip
                          info={`If sample input image name is <input.tif> then expected output file name will be <input-${
                            outputData.name
                          }${
                            outputType == "classification" ||
                            outputType == "detection"
                              ? ".json"
                              : ".tif"
                          }>`}
                          doc=''
                          icon={true}
                          link=''
                        />
                      )} */}
                  </p>
                </div>
              </div>
              <div
                className={`${
                  openOutputTypeSelection == true
                    ? "all-output-types"
                    : "all-output-types-close"
                }`}>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setOutputType("image");
                    // setOutputData({
                    //   name: "",
                    // });
                    setOpenOutputTypeSelection(false);
                    setAdditionalOpenInfo(1.1);
                    setNameError("");
                  }}>
                  Image
                </div>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setOutputType("segmentation");
                    // setOutputData({
                    //   name: "",
                    // });
                    setOpenOutputTypeSelection(false);
                    setAdditionalOpenInfo(1.2);
                    setNameError("");
                  }}>
                  Segmentation
                </div>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setOutputType("classification");
                    // setOutputData({
                    //   name: "",
                    // });
                    setOpenOutputTypeSelection(false);
                    setAdditionalOpenInfo(1.3);
                    setNameError("");
                  }}>
                  Object Classification
                </div>
                <div
                  className='single-type pointer-hand'
                  onClick={() => {
                    setOutputType("detection");
                    // setOutputData({
                    //   name: "",
                    // });
                    setOpenOutputTypeSelection(false);
                    setAdditionalOpenInfo(1.4);
                    setNameError("");
                  }}>
                  Object Detection
                </div>
              </div>
            </div>
          </div>
          <div className='output-options'>
            {outputType == "image" ? (
              <OutputImageSection
                giveOutPutData={giveOutPutData}
                singleOutputObj={singleOutputObj}
                outputData={outputData}
                addNew={addNew}
                setOutputData={setOutputData}
                setOpenOutputOptions={setOpenOutputOptions}
              />
            ) : outputType == "segmentation" ? (
              <Segmentation
                giveOutPutData={giveOutPutData}
                singleOutputObj={singleOutputObj}
                outputData={outputData}
                addNew={addNew}
                setOutputData={setOutputData}
                setOpenOutputOptions={setOpenOutputOptions}
              />
            ) : outputType == "classification" ? (
              <ObjectClassification
                giveOutPutData={giveOutPutData}
                singleOutputObj={singleOutputObj}
                outputData={outputData}
                addNew={addNew}
                setOutputData={setOutputData}
                setOpenOutputOptions={setOpenOutputOptions}
              />
            ) : (
              outputType == "detection" && (
                <ObjectDetection
                  giveOutPutData={giveOutPutData}
                  singleOutputObj={singleOutputObj}
                  outputData={outputData}
                  addNew={addNew}
                  setOutputData={setOutputData}
                  setOpenOutputOptions={setOpenOutputOptions}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OutputBands;
