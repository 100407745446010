import React, { useEffect, useState } from "react";
import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import Login from "./Components/LoginPage/Login";
import { useDispatch, useSelector } from "react-redux";
import ScrollToTop from "./Components/ScrollToTop";
import { Helmet } from "react-helmet";
import LoadingSpinner from "./Components/LoadingSpinner/LoadingSpinner";
import { getDeveloperAccess } from "./Redux/Actions/DeveloperAccess/DeveloperAccess";
import EarlyAccessPopup from "./Components/EarlyAccessPopup/EarlyAccessPopup";
import ProfilePage from "./Components/ProfilePage/ProfilePage";
import AlertForCheckingDeveloperPage from "./Components/AlertBoxForCheckingDeveloperPage/AlertForCheckingDeveloperPage";
import { getEmailVerification } from "./Redux/Actions/EmailVerification/EmailVerification";
import AfterClickVerifyEmailPop from "./Components/AfterClickVerifyEmailPop/AfterClickVerifyEmailPop";
import ToastifyPop from "./Components/ToastifyPop/ToastifyPop";
import { getSSEmsg } from "./Redux/Actions/SSEmsg/SSEmsg";
import Cookies from "js-cookie";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { getopenSessionToken } from "./Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
import { getCookiePolicy } from "./Redux/Actions/CookiePolicy/CookiePolicy";
import { checkIfUserAcceptedTerms } from "./services/checkIfUserAcceptedTerms";
import { messaging } from "./fcmIntegration/firebase";
import { onMessage } from "firebase/messaging";
import 'firebase/messaging';
import { checkUserAccessForMission } from "./services/checkUserAccessForMission";
import DeveloperHome from "./DeveloperPortalComponents/Home/DeveloperHome";
import ImageSelection from "./DeveloperPortalComponents/ImageSelectionPage/ImageSelection";
import SessionTokenExpired from "./Components/SessionTokenExpired/SessionTokenExpired";
function App() {
  const earlyAccess = useSelector((store) => store.early.early);
  const scrollEffect = useSelector((store) => store.scrollPage.scroll);
  const spinner = useSelector((store) => store.spinner.spinner);
  const [openAlertForDev, setOpenAlertForDev] = useState(false);
  const emailVerified = useSelector(
    (store) => store.emailVerified.emailVerified
  );
  const dispatch = useDispatch();
  const session_token = localStorage.getItem("session-token-skyserve");
  const alertOpen = useSelector((store) => store.alertOpen.alertOpen);
  const sessionOpen = useSelector((store) => store.sessionExp.sessionExp);
  const developerAccess = localStorage.getItem("developer-access");
  const [previousRoute, setPreviousRoute] = useState();
  const pageShow = useSelector((store) => store.page.page);
  const sessionMessage = useSelector((store) => store.sessionExp.message);
  const cookiesPolicy = Cookies.get("cookie-policy-skyserve")
  
  const [versionCheck, setVersionCheck] = useState();
  // useEffect(() => {
  //   if (pageShow) {
  //     setDevTab(pageShow)
  //   }
  // },[pageShow])
  useEffect(() => {
    if (earlyAccess == true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [earlyAccess]);
  useEffect(() => {
    if (window.location.hash.startsWith("#/")) {
      window.history.replaceState({
        pathname: window.location.hash.replace(/#\/(.*)/, "$1"),
      });
    }
  },[window.location.hash])
  
  
  async function WhoamiFn(session_token) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", session_token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

      fetch(process.env.REACT_APP_BASE_URL+"/v1/auth/whoami", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.Message.active == true) {
            localStorage.setItem("session-token-skyserve", session_token);
            
            if (result.Message.identity.traits.email_verified == true) {
              dispatch(getEmailVerification(true));
              localStorage.setItem("user-details", JSON.stringify(result.Message.identity.traits));
            }
            else if (result.Message.identity.traits.email_verified == undefined) {
              dispatch(getEmailVerification(false));
              localStorage.setItem("user-details", JSON.stringify(result.Message.identity.traits));
            }
            if (result.Message.identity.traits.developer_access) {
              dispatch(
                getDeveloperAccess(
                  result.Message.identity.traits.developer_access
                )
              );
              localStorage.setItem("developer-access",result.Message.identity.traits.developer_access)
              
            } else {
              
              dispatch(getDeveloperAccess(false));
              localStorage.setItem("developer-access",false)
            }
            checkForTermsAndService(result.Message.identity.traits.email,session_token)
          } else {
            dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
            // logoutFn();
          }
        })
        .catch((error) => {
          dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
          console.log(error);
        });
  }

  async function checkForTermsAndService(email, token) {
    const result = await checkIfUserAcceptedTerms(email, token);
    if (result.message == "User has not accepted terms") {
      dispatch(getopenSessionToken({session:true,message:result.message}));
    }
  }

  async function checkUserAccessForMissionFn(token,email) {
    const response = await checkUserAccessForMission(token,email);
    localStorage.setItem("missionAccess",response.mission_access)
  }
  function reLoad() {}
  useEffect(() => {
    if (openAlertForDev) {
      document.body.style.overflow = "hidden";
    }
  }, [openAlertForDev]);

  const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: rgba(255, 100, 100, 0.9);
    transform: scale(1.6);
  }
`;

  useEffect(() => {
    dispatch(getCookiePolicy(cookiesPolicy));
  }, [cookiesPolicy])
  useEffect(() => {
    const versionStored = localStorage.getItem("SkyServe-Version");
    setVersionCheck(versionStored);
    
  }, [])
  useEffect(() => {
    const newVersion = "1.0.7";
    if (newVersion != versionCheck && versionCheck!=undefined) {
      localStorage.setItem("SkyServe-Version", newVersion);
      window.location.reload(true);
    } else if (versionCheck == undefined) {
      localStorage.setItem("SkyServe-Version", newVersion);
    }
    if (session_token) {
        WhoamiFn(session_token)
    } else {
      localStorage.removeItem("session-token-skyserve");
      localStorage.removeItem("user-details");
      sessionStorage.removeItem("accessable");
      localStorage.removeItem("developer-access");
    }
  }, [versionCheck]);
  

  // onMessageListener().then(payload => {
    
  //   console.log(payload);
  // }).catch(err => console.log('failed: ', err));
  const [notificationPayload,setNotificationPayload]=useState();
  onMessage(messaging, (payload) => {
    // dispatch(getFcmNotification(payload));
    // Customize notification here
    setNotificationPayload(payload)
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon,
    };

    if (Notification.permission === 'granted') {
        // dispatch(getFcmNotification(payload));
        new Notification(notificationTitle, notificationOptions);
    }
});
  return (
    <div className={`${scrollEffect == false ? "NotApp" : "App"}`}>
      
      {earlyAccess && (
        <div className="early-pop-div">
          <div className="early-pop-overlay"></div>
          <div className="early-pop-form">
            <EarlyAccessPopup />
          </div>
        </div>
      )}
      {spinner && <div className="spinner-overlay"></div>}
      {spinner && (
        <div className="spinner-div">
          <LoadingSpinner />
        </div>
      )}

      <Helmet>
        <title>SkyServe | Insights from Space for Earth</title>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="max-image-preview:large" />
        <title>SkyServe | Insights from Space for Earth</title>
        <meta
          name="description"
          content="SkyServe empowers businesses to integrate geospatial insights into their products and workflows through efficient use of edge and ground-based processing of satellite imagery."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://skyserve.ai/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="SkyServe | Insights from Space for Earth"
        />
        <meta
          property="og:description"
          content="SkyServe empowers businesses to integrate geospatial insights into their products and workflows through efficient use of edge and ground-based processing of satellite imagery."
        />
        <meta property="og:url" content="https://skyserve.ai/" />
        <meta property="og:site_name" content="SkyServe" />
        <meta
          property="og:image"
          content="https://skyserve.ai/assets/img/skyLogo.png"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SkyServe_" />
        <meta name="twitter:label1" content="Estimated reading time" />
        <meta name="twitter:data1" content="38 minutes" />
      </Helmet>
      <ScrollToTop />
      <Routes>
        {/* <Route path="/register" element={<Register previousRoute={previousRoute} />}></Route> */}
        <Route path="/" element={<Login checkUserAccessForMissionFn={checkUserAccessForMissionFn} />}></Route>
        <Route path="/profile" element={<ProfilePage/>}/>
        {/* <Route path="/forgot-password" element={<ForgotPassword />}></Route> */}
        {/* <Route path="/Home" element={<LandingPage devAccess={devAccess} />}></Route> */}
        <Route path="/image-selection" element={<ImageSelection />}></Route>
        <Route path="/form.html" onEnter={reLoad} />
        {/* <Route path="/password/:id" element={<Register/>}></Route> */}
        {/* <Route path="/product" element={<ProductDetails />}></Route> */}
        {/* <Route path="/newmap" element={<ProductDetailsPage />}></Route> */}
        {/* <Route path="/newmap" element={<ProductDetailsPage />}></Route> */}
        {/* <Route path="/add-credits" element={<AddCreditsPage />}></Route>
        <Route path="/success-paymet" element={<SuccessPage />}></Route>
        <Route path="/order-review" element={<OrderReviewPage />}></Route>
        <Route path="/all-orders" element={<TotalOrdersPage />}></Route>
        <Route path="/orders" element={<YourOrderPage/>}></Route> */}
        <Route
          path="/developer/:devTab"
          element={<DeveloperHome WhoamiFn={WhoamiFn} fcmNotification={notificationPayload} />}
        ></Route>
      </Routes>
      {(openAlertForDev || alertOpen) && (
        <div className="open-alert-dev-overlay"></div>
      )}
      {openAlertForDev == true && (
        <div className="open-alert-dev-content">
          <AlertForCheckingDeveloperPage
            setOpenAlertForDev={setOpenAlertForDev}
          />
        </div>
      )}

      {/* {emailVerified==false && <div className="open-alert-dev-overlay"></div>} */}
      {/* { session_token &&  emailVerified==false && <div className="pulsate-bck">
        <EmailVerificationPop />
      </div>} */}
      {alertOpen == true && <div className="open-alert-dev-content">
        <AfterClickVerifyEmailPop/>
      </div>}
      {notificationPayload && <div className="bounce-in-right">
        <ToastifyPop fcmNotification={notificationPayload} setNotificationPayload={setNotificationPayload}/>
      </div>}
      {sessionOpen == true && <div className="slide-in-blurred-top">
        <SessionTokenExpired message={sessionMessage} />
      </div>}
      {/* <div style={{
        zIndex: 100000000,
        backgroundColor: "red",
      }} onClick={() => {
        callingFcmApp();
      }}>FCM Button</div> */}
      {/* <FirebaseSetup/> */}
    </div>
  );
}

export default App;
