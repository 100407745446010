export const FCM_MESSAGE = "FCM_MESSAGE";
export const FCM_NOTIFICATION = "FCM_NOTIFICATION"
export const getFcmMessage = (data) => {
    return {
        type: FCM_MESSAGE,
        payload:data
    }
}

export const getFcmNotification = (data) => {
    return {
        type: FCM_NOTIFICATION,
        payload: data
    }
}