import React, { useEffect, useState } from "react";
import "./AuthKey.css";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
import { useSelector } from "react-redux";
import { BsAsterisk, BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

function AuthKey({ setSec2Details, sec2Details, proMode, resubmitModel }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [error, setError] = useState("");
  const fieldErr = useSelector((store) => store.subError.subError);
  const [showAuth, setShowAuth] = useState(false);

  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_repo_auth_key") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);

  // Used to make the input filed "text" or "password" when click on the visibility hidden/shown button
  useEffect(() => {
    const inputbox = document.getElementById("showing-auth-key");
    if (showAuth === true) {
      inputbox.type = "text";
    } else {
      inputbox.type = "password";
    }
  }, [showAuth]);
  return (
    <div className='auth-key'>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
        className='input-label'>
        <div>
          Auth Key <BsAsterisk color='red' size={"0.6vw"} />
        </div>

        <div
          className='eye-icon'
          onClick={() => {
            setShowAuth(!showAuth);
          }}>
          {showAuth === false ? <BsFillEyeSlashFill /> : <BsFillEyeFill />}
        </div>
      </div>
      <div
        onMouseEnter={(e) => {
          e.currentTarget.style.position = "relative";
          setOpenTooltip("authkey");
        }}
        onMouseLeave={(e) => {
          // e.currentTarget.style.position = "static";
          setOpenTooltip("");
        }}>
        <input
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          autoComplete='nowrap'
          id='showing-auth-key'
          className={`${resubmitModel && "color-white"}`}
          onChange={(e) => {
            setSec2Details({
              ...sec2Details,
              model_location: {
                ...sec2Details.model_location,
                model_repo_auth_key: e.target.value.trim(),
              },
            });
            setError("");
          }}
          onBlur={(e) => {
            if (e.target.value == "") {
              setError("not blank");
            }
          }}
          type='password'
          name=''
          value={sec2Details && sec2Details.model_location.model_repo_auth_key}
          disabled={resubmitModel ? true : false}
        />
        {openTooltip == "authkey" &&
          proMode == true &&
          (error == "" || error == "not blank") && (
            <InformativeTooltip
              info='Repository Personal Access Token.'
              doc=''
              icon={true}
              link='https://skyserve.gitbook.io/skyserve-docs/model-submission#auth-key'
            />
          )}
      </div>
      {/* {error != "" && (
        <div className='error-div'>
          <div style={{ position: "relative" }}>
            <div className='pointer-error'></div>
            <div>{error}</div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default AuthKey;
