export function createUtcToLocalDateOnly(date) {
    const utcTimeString = date; // Replace with your UTC time string

    // Create a Date object from the UTC time string
    const utcDate = new Date(utcTimeString);

    // Get the date components (day, month, year) in the desired format
    const day = utcDate.getUTCDate().toString().padStart(2, '0');
    const month = (utcDate.getUTCMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
    const year = utcDate.getUTCFullYear();

    // Combine the date components into the desired format
    const localDate = `${month}-${day}-${year}`;
    return localDate;
}