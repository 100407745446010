import { USER } from "../../Actions/users/UserAction"

const init = {
    user:""
}
export const userReducer = (state = init, { type, payload }) => {
    // console.log(payload)
    switch (type) {
        case USER:
            return {...state,user:payload}
        default:
            return state
    }
}