import React, { useEffect, useRef, useState } from "react";
import { BsCaretDownFill } from "react-icons/bs";
import { MdCheckBoxOutlineBlank, MdCheckBox, MdClose } from "react-icons/md";
import "./Masking.css";
import { useSelector } from "react-redux";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
function Masking({ setSec3Details, sec3Details, proMode }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [openMaskingList, setOpenMaskingList] = useState(false);
  const [error, setError] = useState("");
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);
  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "cloud_masks") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);
  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenMaskingList(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className='masking-div'>
      <div className='input-label'>Data Mask </div>
      <div ref={divRef}>
        <div
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("mask");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          className='selected-masks pointer-hand'
          onClick={() => {
            setOpenMaskingList(!openMaskingList);
          }}>
          {openTooltip == "mask" &&
            openMaskingList == false &&
            proMode == true &&
            error == "" && (
              <InformativeTooltip
                info='Select the process used to remove cloud cover from images/data.'
                doc=''
                icon={true}
                link='https://skyserve.gitbook.io/skyserve-docs/model-submission#data-mask'
              />
            )}
          {error != "" && (
            <div className='error-div'>
              <div style={{ position: "relative" }}>
                <div className='pointer-error'></div>
                <div>{error}</div>
              </div>
            </div>
          )}
          <div className='masking-area'>
            {sec3Details && sec3Details.cloud_masks.length > 0
              ? sec3Details.cloud_masks.map((el) => (
                  <div className='single-selected-mask'>
                    <div
                      className='selected-masking pointer-hand'
                      onClick={() => {}}>
                      {el == "cloud_mask"
                        ? "Cloud Mask"
                        : el == "cloud_shadow"
                        ? "Cloud shadow mask"
                        : "NODATA mask "}
                    </div>
                    <MdClose
                      className='pointer-hand'
                      onClick={() => {
                        // let arr = [...sec3Details.model_config_structure];
                        // arr.splice(id, 1);
                        // setSec3Details({
                        //   ...sec3Details,
                        //   model_config_structure: arr,
                        // });
                        let arr = [...sec3Details.cloud_masks];
                        arr = arr.filter((x) => x != el);
                        setSec3Details({
                          ...sec3Details,
                          cloud_masks: arr,
                        });
                      }}
                    />
                  </div>
                ))
              : "Please add Masking"}
          </div>

          <div className={`${openMaskingList == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
        </div>
        <div
          className={`${
            openMaskingList == true ? "open-masking-list" : "close-masking-list"
          }`}>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              if (sec3Details.cloud_masks.includes("cloud_mask") == false) {
                setSec3Details({
                  ...sec3Details,
                  cloud_masks: [...sec3Details.cloud_masks, "cloud_mask"],
                });
              } else {
                let arr = [...sec3Details.cloud_masks];
                arr = arr.filter((el) => el != "cloud_mask");
                setSec3Details({
                  ...sec3Details,
                  cloud_masks: arr,
                });
              }
            }}>
            Cloud mask
          </div>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              if (sec3Details.cloud_masks.includes("cloud_shadow") == false) {
                setSec3Details({
                  ...sec3Details,
                  cloud_masks: [...sec3Details.cloud_masks, "cloud_shadow"],
                });
              } else {
                let arr = [...sec3Details.cloud_masks];
                arr = arr.filter((el) => el != "cloud_shadow");
                setSec3Details({
                  ...sec3Details,
                  cloud_masks: arr,
                });
              }
            }}>
            Cloud shadow mask
          </div>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              if (sec3Details.cloud_masks.includes("no-data-mask") == false) {
                setSec3Details({
                  ...sec3Details,
                  cloud_masks: [...sec3Details.cloud_masks, "no-data-mask"],
                });
              } else {
                let arr = [...sec3Details.cloud_masks];
                arr = arr.filter((el) => el != "no-data-mask");
                setSec3Details({
                  ...sec3Details,
                  cloud_masks: arr,
                });
              }
            }}>
            NODATA mask
          </div>
        </div>
      </div>
    </div>
  );
}

export default Masking;
