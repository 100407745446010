import { ORDER_REVIEW } from "../../Actions/orderReview/OrderAction"

const init = {
    orderReview:undefined
}

export const orderReviewReducer = (state=init,{ type,payload}) => {
    switch (type) {
        case ORDER_REVIEW:
            return {...state,orderReview:payload}
        default:
            return state
    }
}