import React, { useEffect, useRef, useState } from "react";
import { BsAsterisk, BsCaretDownFill } from "react-icons/bs";
import "./ModelVisibility.css";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
import { useSelector } from "react-redux";
function ModelVisibility({ sec1Details, setSec1Details, proMode }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [openVisibility, setOpenVisibility] = useState(false);
  const [error, setError] = useState("");
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);

  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "visibility") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);

  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenVisibility(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div ref={divRef} className='model-visibility'>
      <div className='input-label'>
        Visibility <BsAsterisk color='red' size={"0.6vw"} />
      </div>
      <div className='model-visibility-selection-div'>
        <div
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          onClick={() => {
            setOpenVisibility(!openVisibility);
            setError("");
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("visibility");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          className='model-visibility-selected pointer-hand'>
          {/* {error != "" && (
            <div className='error-div'>
              <div style={{ position: "relative" }}>
                <div className='pointer-error'></div>
                <div>{error}</div>
              </div>
            </div>
          )} */}
          <div>Private</div>
          <div className={`${openVisibility == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
          {openTooltip == "visibility" &&
            openVisibility == false &&
            proMode == true &&
            (error == "" || error == "not blank") && (
              <InformativeTooltip
                info='This controls the availability of your model on the marketplace.'
                doc=''
                icon={true}
                link='https://skyserve.gitbook.io/skyserve-docs/model-tracker-page#visibility'
              />
            )}
        </div>
        <div
          className={`${
            openVisibility == true
              ? "model-type-lists-open"
              : "model-type-lists-close"
          }`}>
          <div
            className='single-type pointer-hand'
            onClick={() => {
              setSec1Details({ ...sec1Details, visibility: "private" });
              setOpenVisibility(!openVisibility);
            }}>
            Private
          </div>
          <div
            className='single-type disable'
            onClick={() => {
              // setSec1Details({
              //   ...sec1Details,
              //   visibility: "public",
              // });
              setOpenVisibility(!openVisibility);
            }}>
            Public
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModelVisibility;
