import { ADDORDER, ALLORDERS } from "../../Actions/AllOrders/AllOrdersAction"

const init = {
    AllOrders:undefined
}
export const allOrderReducer = (state = init, { type, payload }) => {
    switch (type) {
        case ALLORDERS:
            return { ...state, AllOrders: payload }
        case ADDORDER:
            return {...state,AllOrders:[...state.AllOrders,payload]}
        default:
            return state
    }
}