import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillFolderFill } from "react-icons/bs";
import "./CSS/tracker.css";
import ConfirmationPage from "../Components/ConfirmationPage/ConfirmationPage";
import ChatPage from "../DeveloperPortalComponents/ChatPage/ChatPage";
import ModelLog from "../DeveloperPortalComponents/ModelLog/ModelLog";
import { getResubmitModel } from "../Redux/Actions/ResubmitModel/ResubmitModel";
import { useLocation, useNavigate } from "react-router";
import trackerPageImg from "../Images/tracker.png";
import { getModel } from "../Redux/Actions/Models/ModelAction";
import { getDraftSubmit } from "../Redux/Actions/DraftSubmitModel/DraftSubmit";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getLatestModel } from "../Redux/Actions/LatestSubmission/LatestSubmissionAction";
import { FiExternalLink } from "react-icons/fi";
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineRight } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getopenSessionToken } from "../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
import ProgressMonitor from "../DeveloperPortalComponents/ProgressMonitor/ProgressMonitor";
import ModelDetailsPop from "../DeveloperPortalComponents/ModelDetails/ModelDetailsPop";
import ImageSelection from "../DeveloperPortalComponents/ImageSelectionPage/ImageSelection";
import OutputResult from "../DeveloperPortalComponents/OutputResult/OutputResult";
const Modal = ({
  setOpenModal,
  modelId,
  modelState,
  modelVisibility,
  toBeChanged,
}) => {
  return (
    <div>
      <ConfirmationPage
        setOpenModal={setOpenModal}
        modelId={modelId}
        modelState={modelState}
        modelVisibility={modelVisibility}
        toBeChanged={toBeChanged}
      />
    </div>
  );
};

const Infobox = ({
  children,
  openInfoBox,
  setOpenInfoBox,
  id,
  heading,
  openModelDetails,
  setOpenModelDetails,
}) => {
  return (
    <div>
      <div
        onClick={() => {
          // setOpenContent(true);
          if (openInfoBox.includes(id) == true && !openModelDetails) {
            setOpenInfoBox(openInfoBox.filter((i) => i != id));
          } else if (openInfoBox.includes(id) == false && !openModelDetails) {
            setOpenInfoBox([...openInfoBox, id]);
          } else if (openModelDetails) {
            setOpenModelDetails();
          }
        }}
        className='heading-div-infobox'>
        <div>
          <div className='heading-text-infobox'>{heading}</div>
          <div className='icons-for-infotext'>
            {!openModelDetails && openInfoBox.includes(id) ? (
              <BsChevronUp />
            ) : (
              !openModelDetails &&
              openInfoBox.includes(id) == false && <BsChevronDown />
            )}
            {openModelDetails && (
              <IoCloseOutline
                onClick={() => {
                  setOpenModelDetails();
                }}
              />
            )}
          </div>
        </div>
      </div>
      <di>{openInfoBox.includes(id) && children}</di>
    </div>
  );
};

const DevHomeTracker = ({ setOpenDetailsPage, fcmNotification }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathName = useLocation();
  // const model = useSelector((store) => store.model.model);
  const [model, setModel] = useState();
  const latestModel = useSelector((store) => store.latestModel.latestModel);
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const userMissionAccess = localStorage.getItem("missionAccess");
  const devAccess = useSelector((store) => store.access.access);
  const allModels = useSelector((store) => store.allModels.allModels);
  const modelDetails = useSelector((store) => store.model.model);
  const [openModal, setOpenModal] = useState(false);
  const [openLog, setOpenLog] = useState(false);
  const [modelId, setModelId] = useState();
  const [modelState, setModelState] = useState();
  const [modelVisibility, setModelVisibility] = useState();
  const [toBeChanged, setToBeChanged] = useState("");
  const [openImageSelectionPage, setOpenImageSelectionPage] = useState(false);
  const [outputResult, setOutputResult] = useState();
  const [openOutputResult, setOpenOutputResult] = useState(false);
  const [imgExt, setImgExt] = useState();
  const [openInfoBox, setOpenInfoBox] = useState([0, "0"]);
  const [openModelDetails, setOpenModelDetails] = useState();
  const [startTesting, setStartTesting] = useState(false);
  const [startDummyTesting, setStartDummyTesting] = useState(false);
  const [showHardwareResult, setShowHardwareResult] = useState();
  const [openHardwareResult, setOpenHardwareResult] = useState(false);
  const [testingType, setTestingType] = useState();
  const [openModelExecLog, setOpenModelExecLog] = useState(false);
  const [modelExecLog, setModelExecLog] = useState(false);
  const gotSse = useSelector((store) => store.fcm.notificationPayload);
  useEffect(() => {
    if (modelDetails && pathName.pathname == "/developer/monitor") {
      setModel(modelDetails);
    } else if (!model && pathName.pathname == "/developer/tracker") {
      fetchModel();
    }
  }, [modelDetails, devAccess]);

  useEffect(() => {
    if (latestModel && pathName.pathname == "/developer/tracker") {
      setModel(latestModel);
    }
  }, [latestModel]);

  useEffect(() => {
    if (model && fcmNotification) {
      var myHeaders = new Headers();
      myHeaders.append("Session-Token", Session_Token);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL +
          `/v1/model/getModelDetailsById/${model.model_details.model_id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (model.model_details.model_id == result.model_id) {
            setModel({ model_details: result });
          }
          dispatch(getModel({ model_details: result }));
          setStartTesting(false);
        })
        .catch((error) => {
          dispatch(
            getopenSessionToken({ session: true, message: "Session Expired" })
          );
          console.log("error", error);
        });
    }
  }, [fcmNotification]);

  function fetchModel() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/getLatestModelByUserId",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(getLatestModel({ model_details: result }));
        // console.log(result);
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
  }
  function fetchTestingResult() {
    setOpenOutputResult(true);
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/getModelTestResults/${model.model_details.model_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          setOutputResult(result);
        }
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
  }
  function checkExtension(type) {
    setOpenImageSelectionPage(true);
    setTestingType(type);
  }
  if (allModels == "No data") {
    return navigate(-1);
  }

  function checkHardwareResult() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/getHardwareTestingResults/${model.model_details.model_id}`,
      requestOptions
    )
      .then((response) => {
        // Check the status code
        if (response.status === 200) {
          return response.json();
        } else {
          // Handle non-200 status codes
          // setShowHardwareResult(result);
        }
      })
      .then((result) => {
        setShowHardwareResult(result);
        setStartDummyTesting(false);
        setOpenHardwareResult(true);
        fetchTestingResult();
        localStorage.removeItem("h/w-testing-start");
      })
      .catch((error) => {
        // setStartDummyTesting(false);
        // setOpenHardwareResult(true);
        // fetchTestingResult();
        // localStorage.removeItem("h/w-testing-start");
        toast.error("Analyzing and Preparing Result", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  // Function to fetch Model Execution Logs
  function getModelExecutionLogs(modelId) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);
    console.log("inside get model exec logs " + modelId)
    console.log(Session_Token)
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        `/v1/model/getModelExecutionLogs/` + modelId,
      requestOptions
    )
      .then((response) => {
        // Check the status code
        if (response.status === 200) {
          return response.json()
        } else {
          // Handle non-200 status codes
          // setShowHardwareResult(result);
        }
      })
      .then((result) => {
        console.log(result)
        setModelExecLog(result.model_execution_logs)
      })
      .catch((error) => {
        toast.error("Model Execution Logs is not available", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  console.log(userMissionAccess)
  console.log(model)
  console.log(startDummyTesting);
  console.log(showHardwareResult);
  return (
    <div>
      <ToastContainer />
      {allModels !== "No data" && allModels != null ? (
        <div className='tracker-page'>
          <div style={{ width: "95%", margin: "auto" }}>
            {(openModal || openLog) && <div className='modal-overlay'></div>}
            {openModal && (
              <div className='new-modal'>
                <Modal
                  setOpenModal={setOpenModal}
                  modelId={modelId}
                  modelState={modelState}
                  modelVisibility={modelVisibility}
                  toBeChanged={toBeChanged}
                />
              </div>
            )}
            {openLog && (
              <div className='model-log-page'>
                <ModelLog
                  closelog={setOpenLog}
                  logs={model.model_details.model_verification_logs}
                  model_id={model.model_details.model_id}
                  modelLogTitle={"Model Error Logs"}
                />
              </div>
            )}
            {openModelExecLog && (
              <div className='model-log-page'>
                <ModelLog
                  closelog={setOpenModelExecLog}
                  onClick={getModelExecutionLogs(model.model_details.model_id)}
                  logs={modelExecLog}
                  model_id={model.model_details.model_id}
                  modelLogTitle={"Model Execution Logs"}
                />
              </div>
            )}

            <div style={{ marginTop: "0%" }} className='tracker-model-text'>
              Model Status Tracker
              <div className='submit-model-underline'></div>
            </div>

            {modelDetails && pathName.pathname == "/developer/monitor" && (
              <div className='router-div'>
                <span
                  className='prev-route'
                  onClick={() => {
                    setOpenDetailsPage();
                    dispatch(getModel());
                    sessionStorage.removeItem("opened-model");
                  }}>
                  All Models
                </span>
                <span className='router-arrow'>
                  <AiOutlineRight />
                </span>
                <span className='model-id-route'>
                  {modelDetails.model_details.model_name}
                </span>
                <span className='router-arrow'>
                  <AiOutlineRight />
                </span>
                <span className='model-id-route'>
                  {modelDetails.model_details.model_id}
                </span>
              </div>
            )}
            <div className='first-box-div'>
              <div className='model-name-div'>
                <div>
                  <span
                    style={{
                      color: "rgba(125, 167, 217, 1)",
                      fontSize: "1.2vw",
                    }}>
                    Model name :{" "}
                  </span>
                  <span style={{ color: "rgba(46, 216, 163, 1)" }}>
                    {" "}
                    {model && model.model_details.model_name}
                  </span>
                </div>
                <div className='model-log-btn-div'>
                  <button
                    onClick={() => {
                      if (model) {
                        setOpenLog(true);
                      }
                    }}>
                    Error Logs
                  </button>
                </div>
                <div className='model-log-btn-div'>
                  <button
                    onClick={() => {
                      if (model) {
                        setOpenModelExecLog(true);
                      }
                    }}>
                    Execution Logs
                  </button>
                </div>
                <div className='chat-pop'>
                  {modelDetails != undefined &&
                    pathName.pathname == "/developer/monitor" && (
                      <ChatPage
                        modelId={modelDetails.model_details.model_id}
                        modelStatus={modelDetails.model_details.status}
                        modelQueries={modelDetails.model_details.model_queries}
                      />
                    )}
                </div>
              </div>
              {model && (
                <ProgressMonitor
                  model={model.model_details}
                  startTesting={startTesting}
                  setStartTesting={setStartTesting}
                  startHardwareTesting={startDummyTesting}
                  showHardwareResult={showHardwareResult}
                />
              )}
            </div>
            <div className='latest-model-status'>
              {model &&
              (model.model_details.status == "ValidationSuccessful" ||
                model.model_details.status == "VerifiedModelLocally" ||
                model.model_details.status == "TestingFailed") ? (
                <div>
                  <button
                    className='start-testing-btn'
                    onClick={() => {
                      // navigate("/image-selection");
                      checkExtension("first-testing");
                    }}>
                    Start Testing
                  </button>
                </div>
              ) : model &&
                showHardwareResult == undefined &&
                model.model_details.status == "TestingSuccess" &&
                startDummyTesting == false ? (
                <div>
                  {userMissionAccess=="K2" && (<button
                    className='start-testing-btn'
                    onClick={() => {
                      checkExtension("second-testing");
                      setShowHardwareResult("");
                    }}>
                    Start Hardware Testing
                  </button>)}
                  {model.model_details.status != "TestingFailed" && (
                    <button
                      className='check-testing-btn'
                      onClick={() => fetchTestingResult()}>
                      Check Testing Result
                    </button>
                  )}
                </div>
              ) : (
                model &&
                (model.model_details.status == "HWTestingSuccess" ||
                  model.model_details.status == "HWTestingFailed") && (
                  <div>
                    <button
                      className='start-testing-btn'
                      onClick={() => {
                        // navigate("/image-selection");
                        checkExtension("second-testing");
                        setShowHardwareResult("");
                        // startDummyTestingFn();
                        // localStorage.setItem("h/w-testing-start", "start");
                      }}>
                      Start Hardware Testing
                    </button>
                    {model.model_details.status != "HWTestingFailed" && (
                      <button
                        className='check-testing-btn'
                        onClick={() => {
                          checkHardwareResult();
                          // fetchTestingResult();
                        }}>
                        Check H/W Testing Result
                      </button>
                    )}
                  </div>
                )
              )}
            </div>
            {model ? (
              <div>
                <Infobox
                  id='0'
                  openInfoBox={openInfoBox}
                  heading='Information'
                  setOpenInfoBox={setOpenInfoBox}>
                  <div className='background-infobox'>
                    <div className='information-box-heading'>
                      <div>MODEL STATUS</div>
                      <div>VISIBILITY</div>
                      <div>VERSION</div>
                      <div>ACTIVATION STATUS</div>
                    </div>
                    <div className='information-box-content'>
                      <div
                        className={`${
                          model.model_details.status ==
                            "ValidationSuccessful" ||
                          model.model_details.status == "TestingSuccess" ||
                          model.model_details.status == "HWTestingSuccess"
                            ? "green-text"
                            : model.model_details.status == "TestingSuccess"
                            ? "yellow-text"
                            : model.model_details.status == "HWTestingSuccess"
                            ? "yellow-text"
                            : model.model_details.status ==
                                "ValidationFailed" ||
                              model.model_details.status == "HWTestingFailed" ||
                              model.model_details.status == "TestingFailed"
                            ? "red-text"
                            : ""
                        }`}>
                        {startDummyTesting == true
                          ? "Hardware Testing"
                          : model.model_details.status == "HWTestingSuccess"
                          ? "H/W Testing Successful"
                          : model.model_details.status == "HWTestingFailed"
                          ? "H/W Testing Failed"
                          : model.model_details.status == "ValidationFailed"
                          ? "Validation Failed"
                          : model.model_details.status == "ValidationSuccessful"
                          ? "Validation Successful"
                          : model.model_details.status == "Unsubmitted"
                          ? "Unsubmitted"
                          : model.model_details.status == "UploadModelSuccess"
                          ? "Validating"
                          : model.model_details.status ==
                            "VerificationInProgress"
                          ? "In Progress"
                          : model.model_details.status == "TestingSuccess"
                          ? "Testing Successful"
                          : model.model_details.status == "TestingFailed"
                          ? "Testing Failed"
                          : model.model_details.status == "DownloadRepoSuccess"
                          ? "Validating"
                          : model.model_details.status}
                        {(model.model_details.status == "ValidationFailed" ||
                          model.model_details.status == "VerificationFailed" ||
                          model.model_details.status == "Unsubmitted" ||
                          model.model_details.status == "HWTestingFailed" ||
                          model.model_details.status == "TestingFailed") && (
                          <button
                            onClick={() => {
                              if (
                                model.model_details.status ==
                                  "ValidationFailed" ||
                                model.model_details.status ==
                                  "VerificationFailed"
                              ) {
                                dispatch(getResubmitModel(model.model_details));
                                dispatch(getModel());
                                sessionStorage.removeItem("opened-model");
                              } else {
                                dispatch(getDraftSubmit(model.model_details));
                              }
                              // dispatch(getDevPage("deployment"));
                              navigate("/developer/submit-model");
                            }}
                            className='resubmit-btn'>
                            {model.model_details.status == "ValidationFailed" ||
                            model.model_details.status ==
                              "VerificationFailed" ||
                            model.model_details.status == "TestingFailed" ||
                            model.model_details.status == "HWTestingFailed"
                              ? "Resubmit"
                              : "Submit"}
                          </button>
                        )}
                      </div>
                      <div>
                        {model.model_details.visibility
                          .charAt(0)
                          .toUpperCase() +
                          model.model_details.visibility.substring(
                            1,
                            model.model_details.visibility.length
                          )}
                      </div>
                      <div>{model.model_details.model_version}</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "1vw",
                        }}>
                        <div
                          style={{ textDecoration: "underline" }}
                          className={`${
                            model.model_details.model_state == "Activated"
                              ? "green-text"
                              : model.model_details.model_state == "Draft"
                              ? "sky-text"
                              : "red-text"
                          }`}>
                          {model.model_details.model_state}
                        </div>
                        <div
                          className={`${
                            model.model_details.model_state == "Activated"
                              ? "btn-active"
                              : model.model_details.model_state == "Draft"
                              ? "btn-draft"
                              : "btn-deactivated"
                          }`}>
                          <div
                            className={`${
                              model.model_details.model_state == "Activated"
                                ? "toggle-ball-active"
                                : "toggle-ball-deactive"
                            }`}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Infobox>
                <Infobox
                  id='2'
                  openInfoBox={openInfoBox}
                  heading='Summary'
                  setOpenInfoBox={setOpenInfoBox}>
                  <div className='background-infobox'>
                    <div className='model-url-div'>
                      <div
                        style={{ textDecoration: "none" }}
                        className='model-description-div'>
                        <div
                          style={{
                            width: "16vw",
                          }}
                          className='details-head'>
                          Model Description :
                        </div>
                        <div className='model-des-text'>
                          {model.model_details.model_description}
                        </div>
                      </div>
                      <div>
                        <div style={{ width: "11.5vw" }}>
                          <div>
                            <a
                              style={{
                                display: "flex",
                                gap: "0.4vw",
                                alignItems: "center",
                                textDecoration: "none",
                                color: "white",
                              }}
                              target='_blank'
                              href={
                                model.model_details.model_location
                                  .model_repo_url
                              }>
                              Model Repository URL
                              <div className='link-icon'>
                                <FiExternalLink />
                              </div>
                            </a>
                          </div>
                        </div>
                        {model.model_details.model_type == "dl" && (
                          <div style={{ width: "7.5vw" }}>
                            <div>
                              <a
                                style={{
                                  display: "flex",
                                  gap: "0.4vw",
                                  alignItems: "center",
                                }}
                                target='_blank'
                                href={
                                  model.model_details.model_location.model_path
                                }>
                                Model Path
                                <div
                                  style={{ marginTop: "6%" }}
                                  className='link-icon'>
                                  <BsFillFolderFill />
                                </div>
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='model-details-div'>
                      <div>
                        <div className='details-head'>Model Type : </div>
                        <div className='model-des-text'>
                          {model.model_details.model_type == "dl"
                            ? "Deep Learning"
                            : "Non Deep Learning"}
                        </div>
                      </div>
                      <div>
                        <div className='details-head'>Model Input : </div>
                        <div className='model-des-text'>
                          {model.model_details.input_bands.map((el, id) => (
                            <div>
                              {el[0].toUpperCase() + el.substring(1, el.length)}
                              {id !=
                                model.model_details.input_bands.length - 1 &&
                                ", "}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div>
                        <div className='details-head'>
                          Input Product Type :{" "}
                        </div>
                        <div className='model-des-text'>
                          {model.model_details.level_of_processing == "L1A"
                            ? "Radiometrically corrected Scene"
                            : model.model_details.level_of_processing == "L1B"
                            ? "Haze Compensated Scene"
                            : "Geo-referenced Scene"}
                        </div>
                      </div>
                      <div>
                        <div className='details-head'>Data Mask : </div>
                        <div className='model-des-text'>
                          {model.model_details.cloud_masks.length == 0
                            ? "Null"
                            : model.model_details.cloud_masks.map((el, id) => (
                                <div>
                                  {el == "no_data_mask"
                                    ? "NODATA mask"
                                    : el == "cloud_shadow"
                                    ? "Cloud Shadow"
                                    : "Cloud Free"}
                                  {id !=
                                    model.model_details.cloud_masks.length -
                                      1 && ", "}
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                    {model.model_details.model_config_structure.length > 0 && (
                      <div className='model-configs-details-div'>
                        <div className='config-start-heading'>Configs :</div>
                        <div className='background-infobox'>
                          <div className='information-box-heading'>
                            <div>NAME</div>
                            <div>DATA TYPE</div>
                            <div>MIN</div>
                            <div>MAX</div>
                            <div>DEFAULT</div>
                          </div>
                          {model.model_details.model_config_structure.map(
                            (el) => (
                              <div className='information-box-content'>
                                <div>{el.config_name}</div>
                                <div>
                                  {el.config_data_type == "int"
                                    ? "Integer"
                                    : "Float"}
                                </div>
                                <div>{el.min}</div>
                                <div>{el.max}</div>
                                <div>{el.default}</div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </Infobox>
                <Infobox
                  id='1'
                  openInfoBox={openInfoBox}
                  heading='Output File'
                  openModelDetails={openModelDetails}
                  setOpenModelDetails={setOpenModelDetails}
                  setOpenInfoBox={setOpenInfoBox}>
                  {!openModelDetails ? (
                    <div className='third-box-tracker'>
                      <div className='information-box-heading'>
                        <div>SAMPLE INPUT IMAGE NAME</div>
                        <div>OUTPUT SUFFIX</div>
                        <div>FINAL EXPECTED OUTPUT NAME</div>
                        <div>DETAILS</div>
                      </div>
                      <div className='content-third-div '>
                        <div>{"< Input.tif >"}</div>
                        <div>
                          {model.model_details.output_bands.map((el, id) => (
                            <div>
                              <div>{el.name}</div>
                              <div>
                                Input-{el.name}.
                                {el.output_type.includes("obj")
                                  ? "json"
                                  : "tif"}
                              </div>
                              <div
                                onClick={() => {
                                  setOpenModelDetails(el);
                                }}
                                className='input-details'>
                                ...
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ModelDetailsPop
                      details={openModelDetails}
                      setOpenModelDetails={setOpenModelDetails}
                    />
                  )}
                </Infobox>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <h2>Loading...</h2>
              </div>
            )}
            {(openImageSelectionPage == true) && (
              <div className='image-selection-page-overlay'></div>
            )}
            {openImageSelectionPage == true && (
              <div className='image-selction-page-content'>
                <ImageSelection
                  setOpenImageSelectionPage={setOpenImageSelectionPage}
                  imgExt={imgExt}
                  startTesting={startTesting}
                  setStartTesting={setStartTesting}
                  setStartDummyTesting={setStartDummyTesting}
                  modelId={model && model.model_details.model_id}
                  testingType={testingType}
                  setShowHardwareResult={setShowHardwareResult}
                />
              </div>
            )}
            {openOutputResult && (
              <div className='image-selection-page-overlay'></div>
            )}
            {(openOutputResult ) && (
              <div className='image-selction-page-content'>
                <OutputResult
                  setOpenOutputResult={setOpenOutputResult}
                  outputResult={outputResult}
                  modelId={model.model_details.model_id}
                  showHardwareResult={showHardwareResult}
                  setOpenHardwareResult={setOpenHardwareResult}
                  testingType={testingType}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='tracker-page-without-data'>
          <div className='without-data-img'>
            <img src={trackerPageImg} />
          </div>
          <div className='without-data-text'>
            <div className='without-data-text-head'>
              Add your
              <br /> First Model to <br /> Surge{" "}
            </div>
            <div className='without-data-text-subhead'>
              Lorem ipsum dolor sit amet, consectetur adipis elit. Sit enim nec,
              proin faucibus nibh et sagittis a. Lacinia purus ac amet
              pellentesque aliquam enim.
            </div>
            <div className='without-data-text-button'>
              <div className='button-shadow'></div>
              <div
                className='content-btn'
                onClick={() => {
                  // dispatch(getDevPage("deployment"));
                  navigate("/developer/submit-model");
                }}>
                Add First Model
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DevHomeTracker;
