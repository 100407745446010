import React from "react";
import "./AfterClickVerifyEmailPop.css";
import { useDispatch } from "react-redux";
import { getAlertAfterEmailPop } from "../../Redux/Actions/AlertAfterEmailVerification/AlertAfterEmailVerification";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";

function AfterClickVerifyEmailPop() {
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  // Hashing the email to show as abc***@**xyz.com
  const hashedmail =
    userDetails.email[0] +
    "***" +
    "@**" +
    userDetails.email.substring(
      userDetails.email.length - 4,
      userDetails.email.length
    );
  const dispatch = useDispatch();

  // Call the API to verify the email to send the email again for verification
  function verifyEmail() {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: userDetails.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/auth/verification_initialize",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(spinnerAction(false));
        dispatch(getAlertAfterEmailPop(true));
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)
      });
  }
  return (
    <div className='alert-after-emial-verification'>
      <div className='alert-verify-box'>
        <div className='mail-icon-div'>
          <MdOutlineMarkEmailRead className='mail-icon' />
        </div>
        <div className='email-verify-head'>Verify your email !</div>
        <div className='sent-email-text'>
          We've sent a verification email to {hashedmail}.
          <br />
          You need to verify your email address to log into Surge.
        </div>
        <div className='resent-email-btn-div'>
          <button
            onClick={() => {
              dispatch(getAlertAfterEmailPop(false));
            }}>
            Close
          </button>
          <button onClick={() => verifyEmail()}>Resend email</button>
        </div>
      </div>
    </div>
  );
}

export default AfterClickVerifyEmailPop;
