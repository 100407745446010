import React, { useEffect, useState } from "react";
import "./ModelDescription.css";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
import { useSelector } from "react-redux";
import { BsAsterisk } from "react-icons/bs";
function ModelDescription({ sec1Details, setSec1Details, proMode }) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [error, setError] = useState("");
  const fieldErr = useSelector((store) => store.subError.subError);

  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_description") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);
  return (
    <div className='model-desc'>
      <div className='input-label'>
        Model Description <BsAsterisk color='red' size={"0.6vw"} />
      </div>
      <div
        onMouseEnter={(e) => {
          e.currentTarget.style.position = "relative";
          setOpenTooltip("des");
        }}
        onMouseLeave={(e) => {
          setOpenTooltip("");
        }}
        className='text-area-div'>
        <textarea
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          onChange={(e) => {
            // Setting the value for sec1 Details
            setSec1Details({
              ...sec1Details,
              model_description: e.target.value,
            });
            setError("");
          }}
          onBlur={(e) => {
            if (e.target.value == "") {
              setError("not blank");
            } else if (e.target.value.length < 50) {
              setError("Length must be greater than 50");
            } else if (e.target.value.length > 400) {
              setError("Length must be less than 400");
            }
          }}
          value={sec1Details.model_description}
        />
        <div
          style={{
            position: "absolute",
            top: "100%",
            width: "100%",
            paddingTop: "0%",
          }}>
          {openTooltip == "des" &&
            proMode == true &&
            (error == "" || error == "not blank") && (
              <InformativeTooltip
                info='A detailed description of your model.'
                doc=''
                icon={true}
                link='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-description'
              />
            )}
        </div>
      </div>
      {error != "" && error != "not blank" && (
        <div style={{ top: "100%" }} className='error-div'>
          <div style={{ position: "relative" }}>
            <div className='pointer-error'></div>
            <div>{error}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModelDescription;
