import React from "react";
import "./SuccessFullPop.css";
import right_img from "../../Images/right-img.png";
import { useNavigate } from "react-router";
import { userAction } from "../../Redux/Actions/users/UserAction";
import { useDispatch } from "react-redux";
function SuccessfullPop({ heading, setOpenSuccessPop, msg, fromPage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <div className='success-div'>
        <div className='right-sign-div'>
          <div className='right-sign-img-div'>
            <img src={right_img} alt='img' />
          </div>
        </div>
        <div className='heading-msg'>{heading}</div>
        <div className='success-msg'>{msg}</div>
        <div>
          <button
            onClick={() => {
              setOpenSuccessPop(false);
              if (fromPage == "login") {
                navigate("/developer");
              } else if (fromPage == "register") {
                navigate("/");
              } else if (fromPage == "changePassword") {
                dispatch(userAction());
                navigate("/");
                localStorage.removeItem("session-token-skyserve");
                localStorage.removeItem("user-details");
                sessionStorage.removeItem("accessable");
                window.location.reload();
              }
            }}
            className='register-ok'>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessfullPop;
