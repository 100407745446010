import { EXPIRED_SESSION_TOKEN } from "../../Actions/ExpiredSessionToken/ExpiredSessionToken"

const init = {
    sessionExp: false,
    message:"",
}

export const sessionTokenReducer = (state = init, { type, payload }) => {
    switch (type) {
        case EXPIRED_SESSION_TOKEN:
            return {...state,sessionExp:payload.session,message:payload.message};
        default:
            return state;
    }
}