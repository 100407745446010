import React, { useEffect, useRef, useState } from "react";
import { BsAsterisk, BsCaretDownFill } from "react-icons/bs";
import "./ModelType.css";
import InformativeTooltip from "../../../../Components/InformativeTooltip/InformativeTooltip";
import { useSelector } from "react-redux";
function ModelType({
  sec1Details,
  setSec1Details,
  submitModelDetails,
  proMode,
  resubmitModel,
}) {
  const [openTooltip, setOpenTooltip] = useState("");
  const [openListModelType, setOpenListModelType] = useState(false);
  const [error, setError] = useState("");
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);

  // If there is any error comes from the global state after trying to submit model then it reacts according to that
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_type") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);

  // It is used to close the dropdown when click outside of the drop down
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenListModelType(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div ref={divRef} className='model-type'>
      <div className='input-label'>
        Model Type <BsAsterisk color='red' size={"0.6vw"} />
      </div>
      <div className='model-type-selection-div'>
        <div
          style={{
            border: error == "not blank" ? "2px solid red" : "2px solid white",
            backgroundColor: error == "not blank" ? "#ffe6e6" : "white",
          }}
          onClick={() => {
            if (resubmitModel) {
              setOpenListModelType(false);
            } else {
              setOpenListModelType(!openListModelType);
              setError("");
            }
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("model-type");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          id={`${resubmitModel && "bg-gray"}`}
          className='model-type-selected pointer-hand'>
          <div>
            {sec1Details.model_type == "" && submitModelDetails.model_type == ""
              ? "Select Model Type"
              : sec1Details.model_type != ""
              ? sec1Details.model_type == "dl"
                ? "Deep Learning (ONNX)"
                : "Non Deep Learning"
              : submitModelDetails.model_type != ""
              ? submitModelDetails.model_type == "dl"
                ? "Deep Learning(ONNX)"
                : "Non Deep Learning"
              : ""}
          </div>
          <div className={`${openListModelType == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
          {openTooltip == "model-type" &&
            openListModelType == false &&
            proMode == true &&
            (error == "" || error == "not blank") && (
              <InformativeTooltip
                info='Specify the model type whether deep learning or non-deep learning model.'
                doc=''
                icon={true}
                link=''
              />
            )}
        </div>
        <div
          className={`${
            openListModelType == true
              ? "model-type-lists-open"
              : "model-type-lists-close"
          }`}>
          <div>
            <div
              className='single-type pointer-hand'
              onClick={() => {
                // Setting the value for model type
                setSec1Details({
                  ...sec1Details,
                  model_type: "dl",
                });
                setOpenListModelType(!openListModelType);
              }}>
              Deep Learning{" (ONNX)"}
            </div>
            <div
              className='single-type pointer-hand'
              onClick={() => {
                // Setting the value for model type
                setSec1Details({
                  ...sec1Details,
                  model_type: "non-dl",
                });
                setOpenListModelType(!openListModelType);
              }}>
              Non Deep Learning
            </div>
          </div>
        </div>
      </div>
      {/* {error != "" && (
        <div className='error-div'>
          <div style={{ position: "relative" }}>
            <div className='pointer-error'></div>
            <div>{error}</div>
          </div>
        </div>
      )} */}
    </div>
  );
}

export default ModelType;
