import React, { useState } from "react";
import "./BeforeLoginCheck.css";
import { AiOutlineClose } from "react-icons/ai";

// This page is used before login to the developer portal
// It is not being used in the current version of the project
function BeforeLoginCheck({ setOpenLoginCheck }) {
  const [password, setPassword] = useState();
  const [errorMsg, setErrorMsg] = useState(false);
  function openingDeveloperPortal() {
    if (password == "12345") {
      sessionStorage.setItem("accessable", true);
      setOpenLoginCheck(false);
      setErrorMsg(false);
    } else if (password != "12345") {
      setErrorMsg(true);
    }
  }
  return (
    <div className='login-password-check'>
      <div className='login-password-check-heading'>
        <div>Surge Access</div>
        {/* <div>
          <AiOutlineClose onClick={() => setOpenLoginCheck(false)} />
        </div> */}
      </div>
      <div className='login-password-check-body'>
        <div>Access Key : </div>
        <div>
          <input
            onChange={(e) => setPassword(e.target.value)}
            type='text'
            name=''
            id=''
          />
          <button onClick={() => openingDeveloperPortal()}>Submit</button>
        </div>
        {errorMsg == true && (
          <div className='access-key-error'>Wrong Accesskey !</div>
        )}
      </div>
    </div>
  );
}

export default BeforeLoginCheck;
