import { PRODUCT } from "../Actions/ProductAction";

const init = {
    product: ""
};
export const productDetailsReducer = (state = init, { type, payload }) => {
    switch (type) {
        case PRODUCT:
            return {...state,product:payload};
        default:
            return state
    }
}