import { PRODUCT } from "../../Actions/product/ProductAction"

const init = {
    product:[]
}
export const productReducer = (state = init, { type, payload }) => {
    switch (type) {
        case PRODUCT:
            return { ...state, product:state.product.includes(payload)==true?state.product.filter((el)=>el!=payload):[...state.product,payload] }
        default:
            return state
    }
}