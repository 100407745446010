import { ALLMODELS } from "../../Actions/AllModels/AllModelsAction"

const init = {
    allModels:undefined
}

export const allModelReducer = (state = init, { type, payload }) => {
    switch (type) {
        case ALLMODELS:
            return {...state,allModels:payload}
        default:
            return state
    }
}