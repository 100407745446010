import React, { useEffect } from "react";
import { useState } from "react";
import DevHomeDeployment from "../../DeveloperPortalPages/DevHomeDeployment";
import DevHomeMonitor from "../../DeveloperPortalPages/DevHomeMonitor";
import DevHomeOverview from "../../DeveloperPortalPages/DevHomeOverview";
import DevHomeSidebar from "../DevHomeSidebar/DevHomeSidebar";
import "./DeveloperHome.css";
import DevHomeTracker from "../../DeveloperPortalPages/DevHomeTracker";
import { useDispatch, useSelector } from "react-redux";
import NotificatioPage from "../../DeveloperPortalPages/DevHomeNotification";
import { useLocation, useNavigate } from "react-router";
import PortalNavbarPage from "../PortalNavbarPage/PortalNavbarPage";
import { MdClose } from "react-icons/md";
import tour from "../../Images/tour.png";
import { MdRestartAlt } from "react-icons/md";
import Joyride from "react-joyride";
import { getModel } from "../../Redux/Actions/Models/ModelAction";
import NotFoundPage from "../../Components/404NotFoundPage/404NotFoundPage";
import { checkIfUserAcceptedTerms } from "../../services/checkIfUserAcceptedTerms";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
const DeveloperHome = ({ fcmNotification, WhoamiFn }) => {
  const allModels = useSelector((store) => store.allModels.allModels);
  const location = useLocation();
  const resubmitModel = useSelector(
    (store) => store.resubmitModel.resubmitModel
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const notification = useSelector((store) => store.notification.notification);
  const [proMode, setProMode] = useState(false);
  const [openJoy, setOpenJoy] = useState(false);
  const [state, setState] = useState({
    run: false,
    stepIndex: 0,
  });

  useEffect(() => {
    if (Session_Token) {
      WhoamiFn(Session_Token);
    }
    checkForTermsAndService(userDetails.email, Session_Token);
  }, []);
  async function checkForTermsAndService(email, token) {
    const result = await checkIfUserAcceptedTerms(email, token);
    if (result.message == "User has not accepted terms") {
      dispatch(getopenSessionToken({ session: true, message: result.message }));
    }
  }
  const steps = [
    {
      content: (
        <div>
          <div className='tooltip-head'>WELCOME !</div>
          <div className='tooltip-details'>
            Welcome to the tour, a gateway to a comprehensive overview of our
            offering. Let's begin, explore now!
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex === id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                closingJoy();
              }}>
              Skip Tour
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 1 });
                navigate("/developer/submit-model");
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: false,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".active-portal",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>Model Submission</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            There are two modes available for submitting the model.
            <div style={{ marginTop: "5%" }}>
              <br />1 . Beginner Mode
              <br />2 . Expert Mode
            </div>
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                closingJoy();
              }}>
              Skip Tour
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 2 });
                setProMode(false);
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: false,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".deployment-sidebar",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>Beginner Mode</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            Receive step-by-step guidance in filling out all the fields with
            detailed information. Read more about it in the documentation
            <a
              className='anchor-tag'
              target='_blank'
              href='https://skyserve.gitbook.io/skyserve-docs/model-submission#beginner-mode'>
              Submitting a new model
            </a>
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 1 });
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 3 });
                setProMode(true);
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: false,
      spotlightPadding: 1,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".beginner-mode",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>Expert Mode</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            This mode is suitable for experienced users who are familiar with
            the submission process. Furthermore, when resubmitting a model, the
            expert mode is automatically selected, allowing users to easily
            update or adjust the previous submissions without re-entering
            detailed information. This mode also displays all fields at once on
            a single page for easy access. Read more about it in the
            documentation{" "}
            <a
              className='anchor-tag'
              target='_blank'
              href='https://skyserve.gitbook.io/skyserve-docs/model-submission#expert-mode'>
              Submitting a new model
            </a>
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 2 });
                setProMode(false);
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 4 });
                setProMode(false);
                navigate("/developer/monitor");
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: false,
      spotlightPadding: 1,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".expert-mode",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>View Models</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            Here, explore all the models that have been submitted and saved by
            you. By clicking on ‘View Details’, you can navigate to its
            dedicated tracker for model-specific information.
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 3 });
                navigate("/developer/submit-model");
                setProMode(true);
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 5 });
                dispatch(getModel());
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: false,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: false,
      spotlightPadding: 0,
      spotlightShadow: "0 0 0 rgba(255, 255, 255, 1)",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".monitor-sidebar",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>To View Details</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            By clicking on ‘View Details’, you can navigate to its dedicated
            tracker for model-specific information.
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 4 });
                dispatch(getModel());
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 6 });
                for (let i = 0; i < allModels.length; i++) {
                  let activated = allModels[0].model_version_obj;
                  dispatch(getModel(activated[activated.length - 1]));
                  // setInactiveVersion([...inActiveVersion, inactiveVersion]);
                }
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: false,
      hideFooter: true,
      placement: "right-start",
      spotlightClicks: false,
      spotlightPadding: 0,
      spotlightShadow: "0 0 0 rgba(255, 255, 255, 1)",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".view-button",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>Model Details</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            View comprehensive details and insights about the chosen model,
            enabling you to delve deeper into its status in the pipeline and
            track errors, access validation & test results to understand how it
            is working.
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 5 });
                dispatch(getModel());
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 7 });
                // navigate("/developer/notifications");
                if (allModels && allModels != "No data") {
                  navigate("/developer/tracker");
                }
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: false,
      hideFooter: true,
      placement: "right-start",
      spotlightClicks: false,
      spotlightPadding: 0,
      spotlightShadow: "0 0 0 rgba(255, 255, 255, 1)",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".tracker-model-text",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>Latest Submission</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            Once you submit a model, you can conveniently access the Latest
            submissions on the latest submission page. You can also navigate to
            this page from My Models{">>"}View details
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 6 });
                navigate("/developer/monitor");
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 8 });
                navigate("/developer/notifications");
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: false,
      hideFooter: true,
      placement: "bottom",
      spotlightClicks: false,
      spotlightPadding: 0,
      spotlightShadow: "0 0 0 rgba(255, 255, 255, 1)",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".tracker-sidebar",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>
            <div>Alerts</div>
            <div
              onClick={() => {
                closingJoy();
              }}
              className='closing-icon'>
              <MdClose />
            </div>
          </div>
          <div className='tooltip-details'>
            Stay informed about important updates, message replies, and
            requested changes in the notification section. Additionally, you
            will receive email notifications to ensure you never miss any
            important updates.
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 7 });
              }}>
              Back
            </button>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 9 });
              }}>
              Next
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      hideFooter: true,
      placement: "right-start",
      spotlightClicks: false,
      spotlightPadding: 0,
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".notification-sidebar",
    },
    {
      content: (
        <div>
          <div className='tooltip-head'>End of Tour</div>
          <div className='tooltip-details'>
            To start the tour again at anytime click on restart tour.
            {/* <div className='tooltip-tour-img-div'>
              <img src={tooltip_tour} alt='img' />
              <div className='tooltip-tour-text'>Start Tour</div>
            </div> */}
          </div>
          <div className='progress-points'>
            {Array(10)
              .fill(0)
              .map((el, id) => (
                <div
                  className={`${
                    state && state.stepIndex == id ? "active-dot" : "dots"
                  }`}></div>
              ))}
          </div>
          <div className='tooltip-btns'>
            <button
              onClick={() => {
                setState({ ...state, stepIndex: 0, run: true });
              }}>
              <MdRestartAlt />
              Restart tour
            </button>
            <button
              onClick={() => {
                closingJoy();
                navigate("/developer/overview");
              }}>
              Finish
            </button>
          </div>
        </div>
      ),
      disableBeacon: true,
      disableOverlayClose: true,
      // hideCloseButton: false,
      hideFooter: true,
      placement: "top",
      spotlightClicks: false,
      spotlightPadding: 0,
      spotlightShadow: "0 0 0 rgba(255, 255, 255, 1)",
      styles: {
        options: {
          zIndex: 10000,
        },
      },
      target: ".tour-btn",
    },
  ];
  const closingJoy = () => {
    setState({
      ...state,
      run: false,
      stepIndex: 0,
    });
    setOpenJoy(false);
  };

  useEffect(() => {
    if (resubmitModel) {
      setProMode(true);
    }
  }, [resubmitModel]);
  if (!userDetails || !Session_Token) {
    return <NotFoundPage />;
  }

  const isMobileDeviceOpen =
    "ontouchstart" in window || navigator.maxTouchPoints > 0;
  if (isMobileDeviceOpen) {
    return (
      <div className='mobile-device-error'>
        Sorry, this route is only available on desktop devices.
      </div>
    );
  }
  return (
    <div className='dev-home'>
      {openJoy == true && (
        <Joyride
          // callback={handleJoyrideCallback}
          continuous
          run={state.run}
          scrollToFirstStep={false}
          showProgress
          showSkipButton
          stepIndex={state.stepIndex}
          steps={steps}
          disableOverlayClose={true}
          hideCloseButton={true}
          disableCloseOnEsc={false}
          disableScrolling={false}
          // callback={() => {
          //   setIndex()
          // }}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              beaconSize: 90,
              spotlightShadow: "0 0 0 rgba(255, 255, 255, 1)",
              spotlightPadding: 0,
              width: "22vw",
              zIndex: 100,
              overlayColor: "#000000c7",
            },
            tooltipContainer: {
              textAlign: "left",
            },
            tooltipContent: {
              padding: "5% 0px",
            },
            tooltip: {
              borderRadius: 10,
              boxSizing: "border-box",
              padding: 15,
              position: "relative",
            },
          }}
        />
      )}

      <button
        className='tour-btn'
        onClick={() => {
          setOpenJoy(true);
          setState({ ...state, run: true });
        }}>
        <img src={tour} alt='img' />
        Start Tour
      </button>
      <div className='dev-home-navbar'>
        <PortalNavbarPage portal='developer' />
      </div>
      <div className='dev-home-body'>
        <DevHomeSidebar
          allModels={allModels}
          fcmNotification={fcmNotification}
          numberOfNotification={notification ? notification.length : 0}
        />
        <div className='dev-home-body-contents'>
          {/* {location.pathname == "/developer/submit-model" && !resubmitModel && (
            <button
              // className='mode-selection-btn'
              onClick={() => {
                setProMode(!proMode);
              }}>
              {proMode == true ? "Go Beginner" : "Go Pro"}
            </button>
          )} */}
          <div className='dev-home-body-contents-div'>
            {location.pathname == "/developer/overview" ? (
              <DevHomeOverview token={Session_Token} />
            ) : location.pathname == "/developer/submit-model" ? (
              <DevHomeDeployment
                proMode={proMode}
                setProMode={setProMode}
                token={Session_Token}
              />
            ) : location.pathname == "/developer/monitor" ? (
              <DevHomeMonitor
                // secondStepDone={secondStepDone}
                openJoy={openJoy}
                token={Session_Token}
                state={state}
                fcmNotification={fcmNotification}
              />
            ) : location.pathname == "/developer/tracker" ? (
              <DevHomeTracker fcmNotification={fcmNotification} />
            ) : location.pathname == "/developer/notifications" && (
                <NotificatioPage
                  notification={notification}
                  fcmNotification={fcmNotification}
                />
            )
            }
          </div>
        </div>
      </div>
      {/* <div className='dev-home-body-mob'>
        <div className='menu-developer'>
          <FiMenu
            onClick={() => {
              setOpenDev(!openDev);
            }}
          />{" "}
        </div>
        <div className={`${openDev ? "dev-bar" : "dev-bar-close"}`}>
          <DevHomeSidebar pageShow={pageShow} getPage={getPage} />
        </div>
        {pageShow == "overview" ? (
          <DevHomeOverview getPage={getPage} token={Session_Token} />
        ) : pageShow == "deployment" ? (
          <DevHomeDeployment token={Session_Token} />
        ) : pageShow == "monitor" ? (
          <DevHomeMonitor getPage={getPage} token={Session_Token} />
        ) : pageShow == "tracker" ? (
          <DevTracker />
        ) : (
          pageShow == "notifications" && <NotificatioPage getPage={getPage} />
        )}
      </div> */}
    </div>
  );
};

export default DeveloperHome;
