import { SCROll } from "../Actions/ScrollMainPage";

const init = {
    scroll: ""
};
export const scrollMainPageReducer = (state = init, { type, payload }) => {
    switch (type) {
        case SCROll:
            return {
                ...state,scroll:payload
            };
        default:
            return state;
    }
}