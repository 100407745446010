import React, { useState } from "react";
import "./AlertForCheckingDeveloperPage.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FaLock } from "react-icons/fa";
import BeforeLoginCheck from "../BeforeLoginCheck/BeforeLoginCheck";
import { getAlertAfterEmailPop } from "../../Redux/Actions/AlertAfterEmailVerification/AlertAfterEmailVerification";
import { spinnerAction } from "../../Redux/Actions/Spinner/spinnerAction";
import { getopenSessionToken } from "../../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";

function AlertForCheckingDeveloperPage({ setOpenAlertForDev }) {
  const userDetails = JSON.parse(localStorage.getItem("user-details"));
  const privateAccess = sessionStorage.getItem("accessable");
  const developerAccess = localStorage.getItem("developer-access");
  const emailVerified = useSelector(
    (store) => store.emailVerified.emailVerified
  );
  const dispatch = useDispatch();
  const [openBeforeLoginCheck, setOpenBeforeLoginCheck] = useState(false);
  const navigate = useNavigate();
  function closingPop(data) {
    setOpenAlertForDev(data);
  }
  function verifyEmail() {
    dispatch(spinnerAction(true));
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: userDetails.email,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/auth/verification_initialize",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(spinnerAction(false));
        dispatch(getAlertAfterEmailPop(true));
      })
      .catch((error) => {
        dispatch(getopenSessionToken({session:true,message:"Session Expired"}));
        console.log("error", error)});
  }
  return (
    <div>
      {openBeforeLoginCheck == true ? (
        <div className='register-success-pop'>
          <BeforeLoginCheck
            setOpenLoginCheck={setOpenBeforeLoginCheck}
            setOpenAlertForDev={closingPop}
          />
        </div>
      ) : (
        openBeforeLoginCheck == false && (
          <div className='bounce-in-top'>
            <div className='alert-box-div-dev'>
              <div className='lock-icon-div'>
                <FaLock className='lock-icon' />
              </div>
              <div className='heading-alert-box-dev'>
                You don't have access for Developer portal
              </div>
              <div className='sub-heading-alert-box'>
                {userDetails && privateAccess == undefined
                  ? "Please use the security password to use Dashboard"
                  : userDetails == undefined
                  ? "Please Login to use Dashboard"
                  : userDetails &&
                    emailVerified == false &&
                    "Please verify your email address"}
              </div>
              <div className='alert-box-ok-btn'>
                {userDetails && (
                  <button
                    className='login-cancel'
                    onClick={() => {
                      if (developerAccess == "false") {
                        navigate("/");
                      }
                      setOpenAlertForDev(false);
                    }}>
                    Cancel
                  </button>
                )}
                <button
                  className='to-be-login'
                  onClick={() => {
                    if (userDetails == undefined) {
                      navigate("/");
                      setOpenAlertForDev(false);
                    } else if (privateAccess == undefined) {
                      setOpenBeforeLoginCheck(true);
                    } else if (emailVerified == false) {
                      setOpenAlertForDev(false);
                      verifyEmail();
                    } else if (developerAccess == "false") {
                      // navigate(-1);
                      navigate("/");
                      setOpenAlertForDev(false);
                    }
                  }}>
                  {userDetails && privateAccess == undefined
                    ? "Proceed"
                    : userDetails == undefined
                    ? "Login"
                    : userDetails && emailVerified == false
                    ? "Verify Email"
                    : "Ok"}
                </button>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default AlertForCheckingDeveloperPage;
