import { EMAIL_VERIFICATION } from "../../Actions/EmailVerification/EmailVerification"

const init = {
    emailVerified:true
}

export const emailVerificationReducer = (state = init, { type, payload }) => {
    switch (type) {
        case EMAIL_VERIFICATION:
            return {...state,emailVerified:payload};
        default:
            return state
    }
}