import { PRICE } from "../../Actions/product/PriceEstimation"

const init = {
    priceDetails:""
}
export const priceReducer = (state = init, { type, payload }) => {
    switch (type) {
        case PRICE: {
            return {...state,priceDetails:payload}
        }
        default: 
            return state
        
    }
}