import { DEVELOPER_ACCESS } from "../../Actions/DeveloperAccess/DeveloperAccess"

const init = {
    access:null
}

export const developerAccessReducer = (state = init, { type, payload }) => {
    switch (type) {
        case DEVELOPER_ACCESS:
            return {...state,access: payload};
        default:
            return state
    }
}