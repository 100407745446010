import { applyMiddleware, combineReducers, legacy_createStore as createStore } from "redux";
import thunk from "redux-thunk"
import { AddEmailReducer } from "../Reducers/AddEmailReducer";
import { allModelReducer } from "../Reducers/AllModels/AllModelsReducer";
import { allOrderReducer } from "../Reducers/AllOrders/AllOrderReducer";
import { coordinatesReducer } from "../Reducers/coordinates/CoordinatesReducer";
import { developerPortalPageReducer } from "../Reducers/DeveloperPortalPage/DeveloperPortalPageReducer";
import { darftSubmitReducer } from "../Reducers/DraftSubmitModel/DraftSubmitReducer";
import { inactiveReducer } from "../Reducers/InactiveVersion/InactiveVersionReducer";
import { loggedinUserReducer } from "../Reducers/LoginReducer";
import { modelReducer } from "../Reducers/Models/ModelsReducer";
import { notificationReducer } from "../Reducers/Notifications/NotificationsReducer";
import { openChatFromNotificationReducer } from "../Reducers/OpenChatFromNotification/OpenChatFromNotificationReducer";
import { openPopReducer } from "../Reducers/OpenPopReducer";
import { orderReviewReducer } from "../Reducers/orderReview/OrderReducer";
import { priceReducer } from "../Reducers/product/PriceEstimation";
import { productReducer } from "../Reducers/product/ProductReducer";
import { sectionReducer } from "../Reducers/ProductActionReducer";
import { productDetailsReducer } from "../Reducers/ProductReducer";
import { resubmitModelReducer } from "../Reducers/ResubmitModel/ResubmitModelReducer";
import { savedInputsReducer } from "../Reducers/SavedInputs/SavedInputReducer";
import { scrollMainPageReducer } from "../Reducers/ScrollMainPageReducer";
import { spinnerReducer } from "../Reducers/Spinner/SpinnerReducer";
import { userReducer } from "../Reducers/users/UserReducer";
import { developerAccessReducer } from "../Reducers/DeveloperAccess/DeveloperAccessReducer";
import { earlyAccessReducer } from "../Reducers/EarlyAccessPop/EarlyAccessPopReducer";
import { emailVerificationReducer } from "../Reducers/EmailVerification/EmailVerificationReducer";
import { alertAfterEmailVerificationReducer } from "../Reducers/AlertAfterEmailVerification/AlertAfterEmailVerification";
import { sseMsgReducer } from "../Reducers/SSEmsgReducer/SSEmsgReducer";
import { sessionTokenReducer } from "../Reducers/ExpiredSessionToken/ExpiredSessionTokenReducer";
import { submissionErrorReducer } from "../Reducers/SubmissionError/SubmissionErrorReducer";
import { latestModelReducer } from "../Reducers/LatestSubmission/LatestSubmissionReducer";
import { cookiePolicyReducer } from "../Reducers/CookiePolicy/CookiePolicyReducer";
import { fcmReducer } from "../Reducers/FCM/fcmReducer";
const rootReducer = combineReducers({
    loggedinUser: loggedinUserReducer,
    productDetails: productDetailsReducer,
    scrollPage: scrollMainPageReducer,
    openPop: openPopReducer,
    addEmail: AddEmailReducer,
    section: sectionReducer,
    user: userReducer,
    product: productReducer,
    price: priceReducer,
    orderReview: orderReviewReducer,
    coordinates: coordinatesReducer,
    savedInputs: savedInputsReducer,
    allOrders: allOrderReducer,
    model: modelReducer,
    allModels: allModelReducer,
    spinner: spinnerReducer,
    resubmitModel: resubmitModelReducer,
    draftSubmit:darftSubmitReducer,
    openChat: openChatFromNotificationReducer,
    notification: notificationReducer,
    page: developerPortalPageReducer,
    inactive: inactiveReducer,
    access: developerAccessReducer,
    early: earlyAccessReducer,
    emailVerified: emailVerificationReducer,
    alertOpen: alertAfterEmailVerificationReducer,
    sseMsg: sseMsgReducer,
    sessionExp: sessionTokenReducer,
    subError: submissionErrorReducer,
    latestModel: latestModelReducer,
    cookieStored: cookiePolicyReducer,
    fcm: fcmReducer
});
export const store=createStore(rootReducer,applyMiddleware(thunk))