import React, { useEffect, useRef, useState } from "react";
import { BsAsterisk, BsCaretDownFill } from "react-icons/bs";
import "./Sensor.css";
import { useSelector } from "react-redux";
import InformativeTooltip from "../../../Components/InformativeTooltip/InformativeTooltip";
function Sensor({ proMode, resubmitModel }) {
  const [selectedSensor, setSelectedSensor] = useState("");
  const [openTooltip, setOpenTooltip] = useState("");
  const [openListModelType, setOpenListModelType] = useState(false);
  const [error, setError] = useState("");
  const divRef = useRef(null);
  const fieldErr = useSelector((store) => store.subError.subError);
  const userMissionAccess = localStorage.getItem("missionAccess");
  useEffect(() => {
    if (fieldErr && fieldErr.Field == "model_type") {
      setError(fieldErr.Message);
    } else {
      setError("");
    }
  }, [fieldErr]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpenListModelType(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div ref={divRef} className='model-type'>
      <div className='input-label'>
        Sensor
        <BsAsterisk
          style={{ marginLeft: "0.2vw" }}
          color='red'
          size={"0.6vw"}
        />
      </div>
      <div className='model-type-selection-div'>
        <div
          onClick={() => {
            if (resubmitModel) {
              setOpenListModelType(false);
            } else {
              setOpenListModelType(!openListModelType);
              setError("");
            }
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.position = "relative";
            setOpenTooltip("sensor");
          }}
          onMouseLeave={(e) => {
            // e.currentTarget.style.position = "static";
            setOpenTooltip("");
          }}
          id={`${resubmitModel && "bg-gray"}`}
          className='model-type-selected pointer-hand'>
          <div>
            {/* {sec1Details.model_type == "" && submitModelDetails.model_type == ""
              ? "Select Model Type"
              : sec1Details.model_type != ""
              ? sec1Details.model_type == "dl"
                ? "Deep Learning (ONNX)"
                : "Non Deep Learning"
              : submitModelDetails.model_type != ""
              ? submitModelDetails.model_type == "dl"
                ? "Deep Learning(ONNX)"
                : "Non Deep Learning"
              : ""} */}
            {selectedSensor}
          </div>
          <div className={`${openListModelType == true ? "goUp" : "goDown"}`}>
            <BsCaretDownFill />
          </div>
          {openTooltip == "model-type" &&
            openListModelType == false &&
            proMode == true &&
            error == "" && (
              <InformativeTooltip
                info='Specify the model type whether deep learning or non-deep learning model.'
                doc=''
                icon={true}
                link=''
              />
            )}
        </div>
        <div
          className={`${
            openListModelType == true
              ? "model-type-lists-open"
              : "model-type-lists-close"
          }`}>
          <div>
            {userMissionAccess == "K2" && (
              <div
                className='single-type pointer-hand'
                // K2 mission
                onClick={() => {
                  // setSec1Details({
                  //   ...sec1Details,
                  //   model_type: "dl",
                  // });
                  setSelectedSensor(`RGB-N @ 5.75m`);
                  setOpenListModelType(!openListModelType);
                }}>
                RGB-N @ 5.75m | 14-days revisit
              </div>
            )}
            {userMissionAccess == "Denali" && (
              <div
                className='single-type pointer-hand'
                // Denali mission
                onClick={() => {
                  // setSec1Details({
                  //   ...sec1Details,
                  //   model_type: "non-dl",
                  // });
                  setSelectedSensor(`RGB @ 3.25m`);
                  setOpenListModelType(!openListModelType);
                }}>
                RGB @ 3.25m | 14-days revisit
              </div>
            )}
            {userMissionAccess == "Denali" && (
              <div
                className='single-type pointer-hand'
                // Denali mission
                onClick={() => {
                  // setSec1Details({
                  //   ...sec1Details,
                  //   model_type: "non-dl",
                  // });
                  setSelectedSensor(`Hyperspecral Vis-NIR`);
                  setOpenListModelType(!openListModelType);
                }}>
                Hyperspecral Vis-NIR | 14-days revisit
              </div>
            )}

            {userMissionAccess == "Matterhorn" && (
              <div
                className='single-type pointer-hand'
                // Matterhorn mission
                onClick={() => {
                  // setSec1Details({
                  //   ...sec1Details,
                  //   model_type: "non-dl",
                  // });
                  setSelectedSensor(`RGB @ 160m`);
                  setOpenListModelType(!openListModelType);
                }}>
                RGB @ 160m | 2-days revisit
              </div>
            )}
          </div>
        </div>
      </div>
      {error != "" && (
        <div className='error-div'>
          <div style={{ position: "relative" }}>
            <div className='pointer-error'></div>
            <div>{error}</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sensor;
