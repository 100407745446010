import React from "react";
import "./FailurePop.css";
import { useNavigate } from "react-router";
import right_img from "../../Images/right-img.png";
import { ImCross } from "react-icons/im";
function FailurePop({ heading, setOpenFailurePop, msg }) {
  const navigate = useNavigate();
  return (
    <div>
      <div className='failure-div'>
        <div className='wrong-sign-div'>
          <div className='wrong-sign-img-div'>
            <ImCross />
          </div>
        </div>
        <div className='heading-msg'>{heading}</div>
        <div className='failure-msg'>{msg}</div>
        <div>
          <button
            onClick={() => {
              setOpenFailurePop(false);
            }}
            className='register-ok-failure'>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
}

export default FailurePop;
