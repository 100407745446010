import React, { useEffect, useState } from "react";
import OutputBands from "./OutputBands/OutputBands";
import "./Section4.css";
import { BsPlusLg } from "react-icons/bs";
function Section4({
  sec4Response,
  giveInput,
  submitModelDetails,
  setNextBtnDisabled,
  proMode,
  setSubmitModelDetails,
  resubmitModel,
  draftModel,
}) {
  const [openInfo, setOpenInfo] = useState();
  const [additionOpenInfo, setAdditionalOpenInfo] = useState(1.1);
  const [sec4Details, setSec4Details] = useState({
    output_bands: [],
  });
  if (giveInput && proMode == false) {
    sec4Response(sec4Details);
  }
  // if (giveInputAtOnce && proMode == true) {
  //   getAllDetailsAtOnce(sec4Details);
  // }
  useEffect(() => {
    if (sec4Details.output_bands.length > 0 && proMode == false) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }
    if (proMode == true) {
      if (sec4Details.output_bands.length > 0) {
        setSubmitModelDetails({
          ...submitModelDetails,
          output_bands: sec4Details.output_bands,
        });
      }
    }
  }, [sec4Details]);
  // It is used to store the values coming from every field even after section changes to show that value in the input fields
  useEffect(() => {
    setSec4Details({
      output_bands: submitModelDetails.output_bands,
    });
  }, []);
  // If there is resubmit flow then just store all the values in the section state to show them in the respective field
  useEffect(() => {
    if (resubmitModel) {
      setSec4Details({
        output_bands: resubmitModel.output_bands,
      });
    } else if (draftModel) {
      setSec4Details({
        output_bands: draftModel.output_bands,
      });
    }
  }, [resubmitModel, draftModel]);
  return (
    <div className='sec4-div'>
      <div
        onClick={() => {
          setOpenInfo(1);
        }}
        className='sec4-div-left'>
        <div
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box"
          }`}>
          <OutputBands
            setSec4Details={setSec4Details}
            sec4Details={sec4Details}
            proMode={proMode}
            setAdditionalOpenInfo={setAdditionalOpenInfo}
          />
        </div>
      </div>
      {proMode == false && (
        <div className='sec4-div-right'>
          <div className='sec-info sec1-1'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Output ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 1) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(1);
                    }
                  }}
                  className={`${
                    openInfo == 1 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 1 ? "sec-info-desc extra" : "sec-info-desc-close"
                }`}>
                {" "}
                Choose the appropriate model output type based on the model’s
                inference from the following categories.
                <a
                  style={{ color: "gold", textDecoration: "underline" }}
                  target='_blank'
                  href='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-output'>
                  {" "}
                  Link
                </a>
                <div className='additional-data'>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>Image type</div>
                      <div
                        onClick={() => {
                          if (additionOpenInfo == 1.1 && openInfo == 1) {
                            setAdditionalOpenInfo();
                          } else {
                            setAdditionalOpenInfo(1.1);
                          }
                        }}
                        className={`${
                          additionOpenInfo == 1.1 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        additionOpenInfo == 1.1 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      Select the image type as Integer or Float and define the
                      values accordingly.
                      <br />
                      <span style={{ fontWeight: "bold" }}>Values :</span>{" "}
                      Specify the range of pixel values in the image output (Min
                      and Max).
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Output size optimization parameter :{" "}
                      </span>
                      Consider any parameter that optimizes the size of the
                      output.
                      <br />{" "}
                      <span style={{ fontWeight: "bold" }}>
                        Threshold range :{" "}
                      </span>
                      Define the range for a threshold parameter, including the
                      minimum value, maximum value, and precision (default
                      precision is set to medium).
                    </div>
                  </div>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>Segmentation</div>
                      <div
                        onClick={() => {
                          if (additionOpenInfo == 1.2 && openInfo == 1) {
                            setAdditionalOpenInfo();
                          } else {
                            setAdditionalOpenInfo(1.2);
                          }
                        }}
                        className={`${
                          additionOpenInfo == 1.2 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        additionOpenInfo == 1.2 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      Specify the type of segmentation output. <br />
                      <span style={{ fontWeight: "bold" }}>Binary Mask : </span>
                      Specify if the segmentation output is binary (0 or 1){" "}
                      <br />
                      <span style={{ fontWeight: "bold" }}>Multi-class : </span>
                      Choose this option if the segmentation output involves
                      multiple classes. In the multi-class segmentation, please
                      specify the number of classes present in the segmentation
                      output, Additionally, provide the corresponding pixel
                      values and class name for each class in order to
                      accurately interpret the segmentation results.
                    </div>
                  </div>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>Object Classification</div>
                      <div
                        onClick={() => {
                          if (additionOpenInfo == 1.3 && openInfo == 1) {
                            setAdditionalOpenInfo();
                          } else {
                            setAdditionalOpenInfo(1.3);
                          }
                        }}
                        className={`${
                          additionOpenInfo == 1.3 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        additionOpenInfo == 1.3 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      Specify the total number of classes in the output. Include
                      the class ID and corresponding class label for each to
                      provide a comprehensive understanding of the
                      classification results.
                    </div>
                  </div>
                  <div className='sec-info'>
                    <div className='sec-info-head'>
                      <div>Object Detection</div>
                      <div
                        onClick={() => {
                          if (additionOpenInfo == 1.4 && openInfo == 1) {
                            setAdditionalOpenInfo();
                          } else {
                            setAdditionalOpenInfo(1.4);
                          }
                        }}
                        className={`${
                          additionOpenInfo == 1.4 && openInfo == 1
                            ? "cross pointer-hand"
                            : "plus pointer-hand"
                        }`}>
                        <BsPlusLg />
                      </div>
                    </div>
                    <div
                      className={`${
                        additionOpenInfo == 1.4 && openInfo == 1
                          ? "sec-info-desc"
                          : "sec-info-desc-close"
                      }`}>
                      {" "}
                      Follow the GeoJSON syntax for specifying the object
                      detection output format.
                      <br />{" "}
                      <span style={{ fontWeight: "bold" }}>
                        Point and Bounding Box :{" "}
                      </span>
                      Determine whether the output includes point coordinates
                      and bounding box information.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section4;
