import React, { useEffect, useState } from "react";
import { AiFillCloseCircle, AiOutlineClose } from "react-icons/ai";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getDevPage } from "../Redux/Actions/DeveloperPortalPage/DeveloperPortalPage";
import { getModel } from "../Redux/Actions/Models/ModelAction";
import { getNotification } from "../Redux/Actions/Notifications/NotificationsAction";
import { openingChat } from "../Redux/Actions/OpenChatFromNotification/OpenChatFromNotification";
import "./CSS/notification.css";
import { useNavigate } from "react-router";
import { spinnerAction } from "../Redux/Actions/Spinner/spinnerAction";
import { getopenSessionToken } from "../Redux/Actions/ExpiredSessionToken/ExpiredSessionToken";
function DevHomeNotification({ fcmNotification }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Session_Token = localStorage.getItem("session-token-skyserve");
  // const [allNotifications, setAllNotifications] = useState();
  const allModels = useSelector((store) => store.allModels.allModels);
  const allNotifications = useSelector(
    (store) => store.notification.notification
  );
  // const notification = useSelector((store) => store.notification.notification);
  useEffect(() => {
    fetchAllNotification();
  }, []);
  function fetchAllNotification() {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/getAllNotificationsForUser",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result == null) {
          // setAllNotifications();
          dispatch(getNotification());
        }
        if (result.Status == 401) {
          // setAllNotifications();
          dispatch(getNotification());
        } else {
          // setAllNotifications(result);
          dispatch(getNotification(result));
        }
        dispatch(spinnerAction(false));
      })
      .catch((error) => {
        dispatch(getNotification());
        dispatch(spinnerAction(false));
        console.log("error", error);
      });
  }
  function fetchModelById(model) {
    for (let i = 0; i < allModels.length; i++) {
      for (let j = 0; j < allModels[i].model_version_obj.length; j++) {
        if (
          allModels[i].model_version_obj[j].model_details.model_id ==
            model.model_id &&
          model.alert_type == "chat"
        ) {
          dispatch(getModel(allModels[i].model_version_obj[j]));
          dispatch(openingChat(true));
          // dispatch(getDevPage("tracker"));
          navigate("/developer/monitor");
        } else if (
          allModels[i].model_version_obj[j].model_details.model_id ==
            model.model_id &&
          model.alert_type == "notification"
        ) {
          readNotification(model, "readOnly");
          dispatch(getModel(allModels[i].model_version_obj[j]));
          navigate("/developer/monitor");
        }
      }
    }
  }
  function fetchModelToClear(model) {
    dispatch(spinnerAction(true));
    readAllMsgs(model);
  }

  function readAllMsgs(model) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model_id: model.model_id,
      model_status: model.status,
      read_all_msg: true,
    });
    // console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      mode: "cors",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/submitUserQuery",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        readNotification(model, "clear");
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log("error", error);
      });
  }
  function readNotification(model, status) {
    var myHeaders = new Headers();
    myHeaders.append("Session-Token", Session_Token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      model_id: model.model_id,
      alert_id: model.alert_id,
      notification_for: model.notification_for,
      read: 1,
      clear: status == "readOnly" ? 0 : status == "clear" && 1,
    });

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "/v1/model/updateNotificationStatus",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        fetchAllNotification();
      })
      .catch((error) => {
        dispatch(
          getopenSessionToken({ session: true, message: "Session Expired" })
        );
        console.log(error);
      });
  }
  return (
    <div className='notification'>
      <div className='notify-heading'>
        <div>Notifications</div>
        {/* <div className='notication-page-close'>
            <AiFillCloseCircle />
          </div> */}
        <div className='underline'></div>
      </div>
      <div className='notification-content'>
        <div>
          {allNotifications == null && (
            <div className='all-notifications'>
              <div className='no-notify-head'>
                <div className='warn-icon'>
                  <AiOutlineExclamationCircle />
                </div>
                <div className='notify-head'>No new notifications</div>
              </div>
              <div className='no-notify-sub-head'>
                No new notifications to display at this time.
              </div>
            </div>
          )}
        </div>
        {allNotifications &&
          allNotifications.map((el) => {
            return (
              <div
                // className={`${
                //   el.type == "success"
                //     ? "successfull msg"
                //     : el.type == "error"
                //     ? "error msg"
                //     : "warning msg"
                // }`}
                className={
                  el.read == 1 && el.clear == 0
                    ? "grayedOut-notification"
                    : el.msg.includes("Failed")
                    ? "msg-red"
                    : "msg"
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}>
                <div
                  className='msg-content'
                  onClick={() => {
                    fetchModelById(el);
                  }}>
                  <div>
                    <span>{el.msg}</span>
                    <span> : </span>
                    <span style={{ color: "black" }}>
                      {" < " + el.model_name + " > "}
                    </span>
                  </div>
                  <div className='timestamp-size'>
                    {new Date(el.timestamp * 1000).toISOString()}
                  </div>
                </div>
                <div className='remove-icons'>
                  <AiOutlineClose
                    className='pointer-hand'
                    onClick={() => {
                      fetchModelToClear(el);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default DevHomeNotification;
