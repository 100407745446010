import { OPENCHAT } from "../../Actions/OpenChatFromNotification/OpenChatFromNotification"

const init = {
    openChat:false
}

export const openChatFromNotificationReducer = (state = init, { type, payload }) => {
    switch (type) {
        case OPENCHAT:
            return {...state,openChat: payload};
        default:
            return state
    }
}