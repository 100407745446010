import React, { useEffect, useState } from "react";
import AuthKey from "./AuthKey/AuthKey";
import ModelPath from "./ModelPath/ModelPath";
import ModelUrl from "./ModelUrl/ModelUrl";
import "./Section2.css";
import { BsPlusLg } from "react-icons/bs";
import Sensor from "./Sensor";
function Section2({
  sec2Response,
  giveInput,
  submitModelDetails,
  setNextBtnDisabled,
  proMode,
  setSubmitModelDetails,
  resubmitModel,
  draftModel,
}) {
  const [openInfo, setOpenInfo] = useState();
  const [sec2Details, setSec2Details] = useState({
    model_location: {
      model_repo_url: "",
      model_repo_auth_key: "",
      model_path: "",
      model_test_path: "",
    },
  });

  //------------------------It is optimized below----------------------------//
  if (giveInput && proMode == false) {
    sec2Response(sec2Details);
  }
  useEffect(() => {
    if (
      sec2Details.model_location.model_repo_url != "" &&
      sec2Details.model_location.model_repo_auth_key != "" &&
      (sec2Details.model_location.model_path != "" ||
        (sec2Details.model_location.model_path == "" &&
          submitModelDetails.model_type == "non-dl")) &&
      proMode == false
    ) {
      setNextBtnDisabled(false);
    } else {
      setNextBtnDisabled(true);
    }
  }, [sec2Details.model_location]);
  // console.log(sec2Details);

  useEffect(() => {
    if (resubmitModel) {
      setSec2Details({
        model_location: resubmitModel.model_location,
      });
    } else if (draftModel) {
      setSec2Details({
        model_location: draftModel.model_location,
      });
    }
    // else if (!resubmitModel || !draftModel) {
    //   setSec2Details({
    //     model_location: {
    //       model_repo_url: "",
    //       model_repo_auth_key: "",
    //       model_path: "",
    //       model_test_path: "",
    //     },
    //   });
    // }
  }, [resubmitModel, draftModel]);
  useEffect(() => {
    if (sec2Details.model_location.model_path != "" && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_location: {
          ...submitModelDetails.model_location,
          model_path: sec2Details.model_location.model_path,
        },
      });
    }
  }, [sec2Details.model_location.model_path]);
  useEffect(() => {
    if (
      sec2Details.model_location.model_repo_auth_key != "" &&
      proMode == true
    ) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_location: {
          ...submitModelDetails.model_location,
          model_repo_auth_key: sec2Details.model_location.model_repo_auth_key,
        },
      });
    }
  }, [sec2Details.model_location.model_repo_auth_key]);
  useEffect(() => {
    if (sec2Details.model_location.model_repo_url != "" && proMode == true) {
      setSubmitModelDetails({
        ...submitModelDetails,
        model_location: {
          ...submitModelDetails.model_location,
          model_repo_url: sec2Details.model_location.model_repo_url,
        },
      });
    }
  }, [sec2Details.model_location.model_repo_url]);
  // ---------------------------------------------------------------------------------------------//

  // It is used to store the values coming from every field even after section changes to show that value in the input fields
  useEffect(() => {
    setSec2Details({
      model_location: submitModelDetails.model_location,
    });
  }, []);

  //---------------------------------------------- It needs to be replaced with top code---------------------------------------//
  // useEffect(() => {
  //   // when giveInput is true (which is set by its parent) then send the sec1Details to the parent
  //   if (giveInput && !proMode) {
  //     sec2Response(sec2Details);
  //   }

  //   // If it is beginner mode then check if all the fields are filled or not
  //   if (
  //     sec2Details.model_location.model_repo_url !== "" &&
  //     sec2Details.model_location.model_repo_auth_key !== "" &&
  //     (sec2Details.model_location.model_path !== "" ||
  //       (sec2Details.model_location.model_path === "" &&
  //         submitModelDetails.model_type === "non-dl")) &&
  //     !proMode
  //   ) {
  //     setNextBtnDisabled(false);
  //   } else {
  //     setNextBtnDisabled(true);
  //   }

  //   // If it is pro mode then just set then set the values to the submitModelDetails state
  //   // Which is in future go to the whole form of model submission
  //   if (proMode) {
  //     let newModelDetails = { ...submitModelDetails };

  //     if (sec2Details.model_location.model_path !== "") {
  //       newModelDetails.model_location.model_path =
  //         sec2Details.model_location.model_path;
  //     }

  //     if (sec2Details.model_location.model_repo_auth_key !== "") {
  //       newModelDetails.model_location.model_repo_auth_key =
  //         sec2Details.model_location.model_repo_auth_key;
  //     }

  //     if (sec2Details.model_location.model_repo_url !== "") {
  //       newModelDetails.model_location.model_repo_url =
  //         sec2Details.model_location.model_repo_url;
  //     }

  //     setSubmitModelDetails(newModelDetails);
  //   }
  // }, [sec2Details, proMode, giveInput]);
  //--------------------------------------------------------------------------------------------------------------------//

  // If there is resubmit flow then just store all the values in the section state to show them in the respective field
  useEffect(() => {
    if (resubmitModel || draftModel) {
      const model = resubmitModel || draftModel;
      setSec2Details({
        model_location: model.model_location,
      });
    } else {
      setSec2Details({
        model_location: submitModelDetails.model_location,
      });
    }
  }, [resubmitModel, draftModel]);
  return (
    <div className='sec2-div'>
      <div className='sec2-div-left'>
        <div
          onClick={() => {
            setOpenInfo(3);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <ModelUrl
            setSec2Details={setSec2Details}
            sec2Details={sec2Details}
            proMode={proMode}
            resubmitModel={resubmitModel}
          />
        </div>

        <div
          onClick={() => {
            setOpenInfo(2);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <ModelPath
            setSec2Details={setSec2Details}
            sec2Details={sec2Details}
            proMode={proMode}
            resubmitModel={resubmitModel}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(1);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <AuthKey
            setSec2Details={setSec2Details}
            sec2Details={sec2Details}
            proMode={proMode}
            resubmitModel={resubmitModel}
          />
        </div>
        <div
          onClick={() => {
            setOpenInfo(4);
          }}
          className={`${
            proMode == false
              ? "section-single-box-not-pro pointer-hand"
              : "section-single-box pointer-hand"
          }`}>
          <Sensor
            setSec2Details={setSec2Details}
            sec2Details={sec2Details}
            proMode={proMode}
            resubmitModel={resubmitModel}
          />
        </div>
      </div>
      {proMode == false && (
        <div className='sec2-div-right'>
          <div className='sec-info sec2-1'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model URL ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 3) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(3);
                    }
                  }}
                  className={`${
                    openInfo == 3 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 3 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                The GitHub/Bitbucket URL of the repository containing the model
                as specified in the guideline. Provide the repository URL where
                the model and its associated files are stored.{" "}
                <a
                  style={{ color: "gold", textDecoration: "underline" }}
                  href='https://skyserve.gitbook.io/skyserve-docs/model-submission#model-repository-url'
                  target='_blank'>
                  {" "}
                  Link
                </a>
              </div>
            </div>
          </div>

          <div className='sec-info sec2-3'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Model Path ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 2) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(2);
                    }
                  }}
                  className={`${
                    openInfo == 2 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 2 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                {" "}
                For DL models, please specify the exact location within the
                model repository that points to the trained model (.onnx file).
                This field is not required for non-DL models.
                <a
                  style={{ color: "gold", textDecoration: "underline" }}
                  href='https://skyserve.gitbook.io/skyserve-docs/model-submission#auth-key'
                  target='_blank'>
                  {" "}
                  Link
                </a>
              </div>
            </div>
          </div>
          <div className='sec-info sec2-2'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Auth Key ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 1) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(1);
                    }
                  }}
                  className={`${
                    openInfo == 1 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 1 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                An authentication key (Personal Access Token), is a unique
                alphanumeric code or token that serves as a secure credential
                for SkyServe to access the repository.To generate Auth Key for
                GitHub Repo please have a look at{" "}
                <a
                  style={{ color: "gold", textDecoration: "underline" }}
                  href='https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token'
                  target='_blank'>
                  Link
                </a>{" "}
                .
              </div>
            </div>
          </div>
          <div className='sec-info sec2-2'>
            <div className='sec-info-relative'>
              <div className='sec-info-head'>
                <div>What is Sensor ?</div>
                <div
                  onClick={() => {
                    if (openInfo == 4) {
                      setOpenInfo();
                    } else {
                      setOpenInfo(4);
                    }
                  }}
                  className={`${
                    openInfo == 4 ? "cross pointer-hand" : "plus pointer-hand"
                  }`}>
                  <BsPlusLg />
                </div>
              </div>
              <div
                className={`${
                  openInfo == 4 ? "sec-info-desc" : "sec-info-desc-close"
                }`}>
                Sensors detect and measure Earth's surface or atmospheric
                properties using various spectral bands, resolutions, and
                revisit times, facilitating detailed observations tailored to
                specific objectives and geographic regions{" "}
                <a
                  style={{ color: "gold", textDecoration: "underline" }}
                  href=''
                  target='_blank'>
                  Link
                </a>{" "}
                .
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Section2;
